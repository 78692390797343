import React from 'react';
import { Link } from '@reach/router';
import { nanoid } from 'nanoid';

interface ICompanyProps {
    projects: any,
}

const ProjectList: React.FC<ICompanyProps> = (props) => {
    return <table className="table table-cart bg-white shadow-2">
        <tbody>
            {props.projects.map((
                project: {
                    id: string;
                    name: string;
                    year: number;
                    start: Date;
                    end: Date;
                    completed: boolean;
                    is_delayed: boolean;

                    type: {
                        id: number;
                        name: string;
                    }

                    client: {
                        id: number;
                        name: string;

                        company_type: {
                            id: number;
                            name: string;
                        }
                    }

                    milestone: {
                        id: number;
                        name: string;
                        step: number;
                    }
                }
            ) => (
                <tr key={nanoid()}>
                    <td>
                        <h5>
                            <Link to={`/projects/${project.id}`}>{project.name}</Link>
                        </h5>
                        {/* project.type.name */}
                        <p>Project Type TBD</p>
                    </td>

                    <td>
                        <h5>{project.client.name || 'Unknown Company'}</h5>
                        <p>{project.client.company_type.name || <span>&nbsp;</span>}</p>
                    </td>

                    <td>
                        <h5>Start: {project.start || <span>Unknown</span>}</h5>
                        <p>End: {project.end || <span>Unknown</span>}</p>
                    </td>

                    <td>
                        {project.milestone
                            ? <span className={`badge badge-${project.is_delayed ? 'warning' : 'primary'}`}>M{project.milestone.step}: {project.milestone.name}</span>
                            : <>&nbsp;</>
                        }

                        {project.completed && <span className="badge badge-success">Completed</span>}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>;
};

export default ProjectList;
