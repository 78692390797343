import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IClientContactReportPageProps extends RouteComponentProps { }

interface IState { }

class ClientContactReportPage extends React.Component<IClientContactReportPageProps, IState> {
    // Define the constructor for the ad audit page
    constructor (props: IClientContactReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the ad audit container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Client Contact Report</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Client Contact Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Client Contact report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col lh-5">
                                                    <div className="row">
                                                        <div className="col-5 lh-5"><strong>Client</strong></div>
                                                        <div className="col-4 text-left"><strong>Weekly Meeting</strong></div>
                                                        <div className="col-3 text-left"><strong>2nd Contact</strong></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Cocoa Beach</div>
                                                <div className="col-4 text-left">Fridays @ 11 AM</div>
                                                <div className="col-3 text-left">Tuesday</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Rockville</div>
                                                <div className="col-4 text-left">Fridays @ 2:30 PM</div>
                                                <div className="col-3 text-left">Monday</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Lake Wales</div>
                                                <div className="col-4 text-left">Thursdays @ 10 AM</div>
                                                <div className="col-3 text-left">Tuesday</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Millington</div>
                                                <div className="col-4 text-left">Thursdays @ 11:30 AM</div>
                                                <div className="col-3 text-left">Tuesday</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Unalaska</div>
                                                <div className="col-4 text-left">Thursdays @ 2:30 PM</div>
                                                <div className="col-3 text-left">Tuesday</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Sullivan</div>
                                                <div className="col-4 text-left">Thursdays @ 3 PM</div>
                                                <div className="col-3 text-left">Tuesday</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Indian River</div>
                                                <div className="col-4 text-left">Mondays @ 1:30 PM</div>
                                                <div className="col-3 text-left">Wednesday's @ 10 AM</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default ClientContactReportPage;
