import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface ITimelineReportPageProps extends RouteComponentProps { }

interface IState { }

class TimelineReportPage extends React.Component<ITimelineReportPageProps, IState> {
    // Define the constructor for the timeline report page
    constructor (props: ITimelineReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the timeline report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Timeline Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem', paddingBottom: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Timeline report is not yet functional</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Project Start</div>
                                        <div className="col-auto text-center">01/01/20</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Project End</div>
                                        <div className="col-auto text-center">08/13/20</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Revised End</div>
                                        <div className="col-auto text-center">08/15/20</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Editorial Received</div>
                                        <div className="col-auto text-center">04/24/20</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Images Received</div>
                                        <div className="col-auto text-center">04/26/20</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Listings Received</div>
                                        <div className="col-auto text-center">05/12/20</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Meetings Missed</div>
                                        <div className="col-auto text-center">3</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Times Timeline Modified</div>
                                        <div className="col-auto text-center">5</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Days Modified</div>
                                        <div className="col-auto text-center">18</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Soft Proof Sent</div>
                                        <div className="col-auto text-center">01/01/20</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Proof Returned</div>
                                        <div className="col-auto text-center">08/13/20</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Hard Proof Sent</div>
                                        <div className="col-auto text-center">04/24/20</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Proof Returned</div>
                                        <div className="col-auto text-center">04/26/20</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Print Date</div>
                                        <div className="col-auto text-center">09/01/20</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Modified Date</div>
                                        <div className="col-auto text-center">09/03/20</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Delivery Date</div>
                                        <div className="col-auto text-center">09/03/20</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Modified Date</div>
                                        <div className="col-auto text-center">09/25/20</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Editorial Audit</div>
                                        <div className="col-auto text-center">01/01/20</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Image Audit</div>
                                        <div className="col-auto text-center">04/24/20</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Ad Sales Audit</div>
                                        <div className="col-auto text-center">09/01/20</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Listing Audit</div>
                                        <div className="col-auto text-center">09/03/20</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            Action Items
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                            project start           Editorial Received      Meetings Missed
                            project end             Images Received         Times Modified
                            revised project end     Listings Received       Days Modified

                            Soft Proof Sent             Hard Proof Sent             Printing Date           Delivery Date
                            Soft Proof Received Back    Hard Proof Received Back    Printing Date Revised   Delivery Date Revised

                            Editorial Audit Date    Image Audit Completion Date     Ad Sales Audit Completion Date      Listing Audit Completion Date

                                            --------- action items -----------
                        */}

                        {/*
                            [-] Initial Project Start Date
                            [-] Initial Project Completion Date
                            [-] Revised Project Completion Date

                            [-] Date Editorial Was Received
                            [-] Date Images Were Received
                            [-] Date Directory / Listings Were Received

                            [-] Number of Meetings Missed
                            [-] Number of Time Line Modifications
                            [-] Number of Days’ Time Line has been adjusted
                            [-] Reasons why the time line was adjusted          -> detailed in the action item modification reason

                            [-] Action Items Initial Start Dates
                            [-] Action Item Initial Completion Dates
                            [-] Action Item Revised Completion Dates
                            [-] LC Reason For TimeLine Adjustments

                            [-] Soft Proofing Dates Sent and Received back Dates
                            [-] Hard Copy Proofing Sent and Received Back Dates

                            [-] Printing Dates Initial and Revised
                            [-] Delivery Dates Initial and Revised

                            [-] Editorial Audit Completion Date
                            [-] Image Audit Completion Date

                            [-] Advertising Sales Audit Completion Date
                            [-] Directory and Listing Audit Completion Date
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default TimelineReportPage;
