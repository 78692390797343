import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface ISalesCommissionReportPageProps extends RouteComponentProps { }

interface IState { }

class SalesCommissionReportPage extends React.Component<ISalesCommissionReportPageProps, IState> {
    // Define the constructor for the sales commission report page
    constructor (props: ISalesCommissionReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the sales commission report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Commission Report for Employee Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">
                                            <span className="badge badge-secondary mr-1">Day</span>
                                            <span className="badge badge-primary mr-1">Week</span>
                                            <span className="badge badge-secondary mr-1">Month</span>
                                            <span className="badge badge-secondary mr-1">Quarter</span>
                                            <span className="badge badge-secondary mr-1">Year</span>
                                            <span className="badge badge-secondary mr-1">All Time</span>
                                        </span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Sales Commission report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Project Name
                                                </small>
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Total agreements, total agreement dollars, total paid, total unpaid */}

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Advertiser</strong></div>
                                                <div className="col-1 text-left"><strong>Type</strong></div>
                                                <div className="col-2 text-left"><strong>Size</strong></div>
                                                <div className="col-1 text-left"><strong>Agreement</strong></div>
                                                <div className="col-2 text-right"><strong>Commission</strong></div>
                                                <div className="col-1 text-left"><strong>&nbsp;</strong></div>
                                                <div className="col-2 text-left"><strong>&nbsp;</strong></div>
                                                {/* <div className="col-1 text-left"><strong>Amount</strong></div> */}
                                                {/* <div className="col-1 text-left"><strong>Art Type</strong></div> */}
                                                {/* <div className="col-1 text-left"><strong>Status</strong></div> */}

                                                {/* <div className="col-3 text-left"><strong>Agreement Received</strong></div>
                                                <div className="col-3 text-left"><strong>Artwork Received</strong></div>
                                                <div className="col-3 text-left"><strong>Artwork Approved</strong></div>
                                                <div className="col-3 text-left"><strong>Release Form Approved</strong></div> */}
                                            </div>
                                        </div>
                                    </div>
                            {/*
                            [x] Advertiser names and demographics
                            [x] Advertising Size
                            [x] Advertising Amount
                            [x] Renewal / Prospect
                            [-] Art Work Type       -> Will Send Art, Designing New, etc. No longer relevant
                            [x] Advertising Status
                            [x] Contract Amount
                            [x] Commission Amount / and Paid 1st Half
                            [x] Commission Amount / and Paid 2nd Half
                            [-] Total Commission Paid with dates paid
                            [x] Agreement / contract received date
                            [x] Art Work received date
                            [x] Art Work approved date
                            [x] Release form approved date
                            */}

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5">Albella Family Restaurant</div>
                                                <div className="col-1 text-left">Prospect</div>
                                                <div className="col-2 text-left">1/4 Page</div>
                                                <div className="col-1 text-left">$665</div>
                                                <div className="col-2 text-right">$53.20<br /><span className="text-muted"><small><i>(Half: $26.60)</i></small></span></div>
                                                <div className="col-1 text-left">
                                                    <span className="">
                                                        <small><span className="badge badge-secondary">Unpaid</span></small>
                                                        <br />
                                                        <small><span className="badge badge-success">Paid 08/17/20</span></small>
                                                    </span>
                                                </div>
                                                <div className="col-2 text-right">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        {/* Status */}
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14 3V3.28988C16.8915 4.15043 19 6.82898 19 10V17H20V19H4V17H5V10C5 6.82898 7.10851 4.15043 10 3.28988V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17ZM14 21V20H10V21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M15 6H3V8H15V6Z" fill="currentColor" />
                                                            <path d="M15 10H3V12H15V10Z" fill="currentColor" />
                                                            <path d="M3 14H11V16H3V14Z" fill="currentColor" />
                                                            <path
                                                                d="M11.9905 15.025L13.4049 13.6106L15.526 15.7321L19.7687 11.4895L21.1829 12.9037L15.526 18.5606L11.9905 15.025Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Beer World</div>
                                                <div className="col-1 text-left">Prospect</div>
                                                <div className="col-2 text-left">1/4 Page</div>
                                                <div className="col-1 text-left">$800</div>
                                                <div className="col-2 text-right">$48<br /><span className="text-muted"><small><i>(Half: $24.60)</i></small></span></div>
                                                <div className="col-1 text-left">
                                                    <span className="">
                                                        <small><span className="badge badge-secondary">Unpaid</span></small>
                                                        <br />
                                                        <small><span className="badge badge-success">Paid 08/17/20</span></small>
                                                    </span>
                                                </div>
                                                <div className="col-2 text-right">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        {/* Status */}
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14 3V3.28988C16.8915 4.15043 19 6.82898 19 10V17H20V19H4V17H5V10C5 6.82898 7.10851 4.15043 10 3.28988V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17ZM14 21V20H10V21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M15 6H3V8H15V6Z" fill="currentColor" />
                                                            <path d="M15 10H3V12H15V10Z" fill="currentColor" />
                                                            <path d="M3 14H11V16H3V14Z" fill="currentColor" />
                                                            <path
                                                                d="M11.9905 15.025L13.4049 13.6106L15.526 15.7321L19.7687 11.4895L21.1829 12.9037L15.526 18.5606L11.9905 15.025Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Bethel Business Association</div>
                                                <div className="col-1 text-left">Prospect</div>
                                                <div className="col-2 text-left">1/2 Page</div>
                                                <div className="col-1 text-left">$395</div>
                                                <div className="col-2 text-right">$31.60<br /><span className="text-muted"><small><i>(Half: $15.80)</i></small></span></div>
                                                <div className="col-1 text-left">
                                                    <span className="">
                                                        <small><span className="badge badge-secondary">Unpaid</span></small>
                                                        <br />
                                                        <small><span className="badge badge-success">Paid 08/17/20</span></small>
                                                    </span>
                                                </div>
                                                <div className="col-2 text-right">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        {/* Status */}
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14 3V3.28988C16.8915 4.15043 19 6.82898 19 10V17H20V19H4V17H5V10C5 6.82898 7.10851 4.15043 10 3.28988V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17ZM14 21V20H10V21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M15 6H3V8H15V6Z" fill="currentColor" />
                                                            <path d="M15 10H3V12H15V10Z" fill="currentColor" />
                                                            <path d="M3 14H11V16H3V14Z" fill="currentColor" />
                                                            <path
                                                                d="M11.9905 15.025L13.4049 13.6106L15.526 15.7321L19.7687 11.4895L21.1829 12.9037L15.526 18.5606L11.9905 15.025Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Building Traditions</div>
                                                <div className="col-1 text-left">Renewal</div>
                                                <div className="col-2 text-left">Full Page</div>
                                                <div className="col-1 text-left">$1,000</div>
                                                <div className="col-2 text-right">$30<br /><span className="text-muted"><small><i>(Half: $15.00)</i></small></span></div>
                                                <div className="col-1 text-left">
                                                    <span className="">
                                                        <small><span className="badge badge-secondary">Unpaid</span></small>
                                                        <br />
                                                        <small><span className="badge badge-success">Paid 08/17/20</span></small>
                                                    </span>
                                                </div>
                                                <div className="col-2 text-right">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        {/* Status */}
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14 3V3.28988C16.8915 4.15043 19 6.82898 19 10V17H20V19H4V17H5V10C5 6.82898 7.10851 4.15043 10 3.28988V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17ZM14 21V20H10V21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M15 6H3V8H15V6Z" fill="currentColor" />
                                                            <path d="M15 10H3V12H15V10Z" fill="currentColor" />
                                                            <path d="M3 14H11V16H3V14Z" fill="currentColor" />
                                                            <path
                                                                d="M11.9905 15.025L13.4049 13.6106L15.526 15.7321L19.7687 11.4895L21.1829 12.9037L15.526 18.5606L11.9905 15.025Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Downtown Barn</div>
                                                <div className="col-1 text-left">Renewal</div>
                                                <div className="col-2 text-left">1/8 Page</div>
                                                <div className="col-1 text-left">$625</div>
                                                <div className="col-2 text-right">$18.75<br /><span className="text-muted"><small><i>(Half: $9.38)</i></small></span></div>
                                                <div className="col-1 text-left">
                                                    <span className="">
                                                        <small><span className="badge badge-secondary">Unpaid</span></small>
                                                        <br />
                                                        <small><span className="badge badge-success">Paid 08/17/20</span></small>
                                                    </span>
                                                </div>
                                                <div className="col-2 text-right">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        {/* Status */}
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14 3V3.28988C16.8915 4.15043 19 6.82898 19 10V17H20V19H4V17H5V10C5 6.82898 7.10851 4.15043 10 3.28988V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17ZM14 21V20H10V21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M15 6H3V8H15V6Z" fill="currentColor" />
                                                            <path d="M15 10H3V12H15V10Z" fill="currentColor" />
                                                            <path d="M3 14H11V16H3V14Z" fill="currentColor" />
                                                            <path
                                                                d="M11.9905 15.025L13.4049 13.6106L15.526 15.7321L19.7687 11.4895L21.1829 12.9037L15.526 18.5606L11.9905 15.025Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form Approved */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="col-md-4 mx-auto">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Project Name
                                                </small>
                                            </div>
                                        </div>

                                        <div className="col-auto text-right">
                                            <div>
                                                <strong>TBD</strong><br />
                                                <small>TBD</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>

                        {/*
                            [x] Pay Period Dates
                            [x] Ad Sales Rep Name
                            [x] Project Name
                            [x] Advertiser names and demographics
                            [x] Advertising Size
                            [x] Advertising Amount
                            [-] Renewal / Prospect
                            [-] Art Work Type
                            [-] Advertising Status
                            [-] Contract Amount
                            [-] Commission Amount / and Paid 1st Half
                            [-] Commission Amount / and Paid 2nd Half
                            [-] Total Commission Paid with dates paid
                            [-] Agreement / contract received date
                            [-] Art Work received date
                            [-] Art Work approved date
                            [-] Release form approved date
                            [x] Report needs to break out the projects and summarize by total
                            [x] Need to allow the rep to query reports daily, weekly, monthly, quarterly and yearly
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default SalesCommissionReportPage;
