// import React from 'react';
import gql from 'graphql-tag';

export const AccountQuery = gql`query userAccount (
    $employeeId: String!,
    $dateStart: timestamptz!,
    $dateEnd: timestamptz!
) {
    users (where: {id: {_eq: $employeeId }}) {
        id
        name
        email
        phone
        ext
        line1
        line2
        city
        hired
        anniversary
        birthday
        terminated

        state {
            id
            abbr
        }

        zip
        url

        type: employee_type {
            id
            name
        }

        absences {
            id
            day
            full_day_absence
            time_start
            time_end
        }

        benefits {
            id
            days_sick
            days_vacation
            days_personal
        }

        documents {
            id
            name
            url
            date
        }

        ice {
            id
            name
            relationship
            email
            phone
        }

        salaries {
            id
            salary
            comments
        }

        requests (order_by: {start: desc}, where: {start: {_gte: $dateStart, _lte: $dateEnd}}) {
            id
            start
            end
            deny_reason
            reviewed
            approved

            type {
                id
                name
            }
        }
    }

    user_request_types (order_by: {name: asc}) {
        id
        name
    }

    user_document_types (order_by: {name: asc}) {
        id
        name
    }

    request_years: user_requests_aggregate (where: {user_id: {_eq: $employeeId}}) {
        aggregate {
            max {
                start
            }
            min {
                start
            }
        }
    }
}`;
