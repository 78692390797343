import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import EditContact from '../../components/company/EditContact';
import gql from 'graphql-tag';
import { useQuery } from 'urql';

const q = gql`
    query ($contactId: String!) {
        contacts (where: {id: {_eq: $contactId}}) {
            id
            name
            title
            email
            phone
            mobile
        }
    }
`;

// TODO: Import components

interface MatchParams { id: string; }
interface MatchProps extends RouteComponentProps<MatchParams> { }

// class EditContactPage extends React.Component<IContactPageProps, IState> {
    // Define the constructor for the edit contact page
    /*
    constructor (props: IContactPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }
    */

const EditContactPage: React.FC<MatchProps> = (match: MatchProps) => {
    const contactId = match.id;

    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: { contactId }
    });

    const { data, fetching, error } = result;

    // Get the company data out of the response
    const contact = data ? data.contacts[0] : {};

    console.log('contact info', ' => ', contact);

    // Render the TSX for the contact container
    return (
        <FullPage>
            <section className="section" style={{ paddingTop: '1rem' }}>
                <div className="container">
                    <section className="section tab-pane show active" id="info" style={{ paddingTop: '2rem' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 mx-auto pl-0">
                                    <EditContact contact={contact} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </FullPage>
    );
}

export default EditContactPage;
