import React from 'react';
import { useQuery } from 'urql';
import gql from 'graphql-tag';
import AlertMessage from '../../components/alerts/AlertMessage';

const q = gql`
    {
        concept_sizes(order_by: {name: asc}) {
            name
            id
        }
        concept_types(order_by: {name: asc}) {
            id
            name
        }
    }
`;

const ConceptFilter = () => {
    const [result, reexecuteQuery] = useQuery({
        query: q,
    });

    const { data, fetching, error } = result;
    if (fetching) return <p>Loading...</p>;
    if (error) return <AlertMessage type="danger" text={error.message} />;

    return <div className="border rounded bg-white p-2 mb-4">
        <div className="row align-items-center">
            <div className="col lh-5 col-1">
                <a className="text-default fw-400" href="#">Type</a>
            </div>

            <div className="col-auto">
                <span className="topbar-divider"></span>

                <a
                    href=""
                    className="badge badge-secondary mr-1"
                    onClick={e => { e.preventDefault() }}
                >
                    All
                </a>

                {data.concept_types.map((type: { name: string; }) => (
                    <a
                        href=""
                        className="badge badge-secondary mr-1"
                        onClick={e => { e.preventDefault() }}
                    >
                        {type.name}
                    </a>
                ))}
            </div>
        </div>

        <div className="row align-items-center">
            <div className="col lh-5 col-1">
                <a className="text-default fw-400" href="#">Size</a>
            </div>

            <div className="col-auto">
                <span className="topbar-divider"></span>

                <a
                    href=""
                    className="badge badge-secondary mr-1"
                    onClick={e => { e.preventDefault() }}
                >
                    All
                </a>

                {data.concept_sizes.map((size: { name: string; }) => (
                    <a
                        href=""
                        className="badge badge-secondary mr-1"
                        onClick={e => { e.preventDefault() }}
                    >
                        {size.name}
                    </a>
                ))}
            </div>
        </div>
    </div>;
};

export default ConceptFilter;
