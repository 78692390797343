import React, { Component } from 'react';

interface IProps {
    title?: string,
    text: string,
    type: string
}

export class AlertMessage extends Component<IProps> {
    render () {
        return <div className={`alert alert-${this.props.type}`} role="alert">
            {this.props.title && <h4 className="alert-heading">{this.props.title}</h4>}
            <p>{this.props.text}</p>
        </div>
    }
}

export default AlertMessage;
