import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IProps extends RouteComponentProps<{}> { }

interface IState { }

class DesignerProductionReportPage extends React.Component<IProps, IState> {
    // Define the constructor for the designer production report page
    constructor (props: IProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the designer production report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Designer Production Report for Employee Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">
                                            <span className="badge badge-secondary mr-1">Day</span>
                                            <span className="badge badge-primary mr-1">Week</span>
                                            <span className="badge badge-secondary mr-1">Month</span>
                                            <span className="badge badge-secondary mr-1">Quarter</span>
                                            <span className="badge badge-secondary mr-1">Year</span>
                                            <span className="badge badge-secondary mr-1">All Time</span>
                                        </span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 pl-0 pr-0">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Designer Production report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center text-center">
                                        <div className="col-1 lh-5">&nbsp;</div>
                                        <div className="col-2 lh-5">
                                            <strong>Agreements</strong><br />
                                            67
                                            {/* Broken down by renewals and prospects */}
                                        </div>

                                        <div className="col-2 lh-5">
                                            <strong>Rejected</strong><br />
                                            4
                                        </div>

                                        <div className="col-2 lh-5">
                                            <strong>LC Designed</strong><br />
                                            21
                                        </div>

                                        <div className="col-2 lh-5">
                                            <strong>Camera Ready</strong><br />
                                            18
                                        </div>

                                        <div className="col-2 lh-5">
                                            <strong>Templated</strong><br />
                                            3
                                        </div>
                                        {/* TODO: Add Approved */}
                                        <div className="col-1 lh-5">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                            TODO: Add "timing" - 15, 30, 45 days from agreement entered, per advertiser
                        */}

                        <div className="row">
                            <div className="col-md-8 pl-0">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Project Name
                                                </small>
                                                {/*</Link>*/}
                                            </div>
                                        </div>

                                        <div className="col-auto text-right">
                                            <div>
                                                <strong>TBD</strong><br />
                                                <small>TBD</small> {/*moment(agreement.createdAt).format('L')*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col lh-5">
                                                    <div className="row">
                                                        <div className="col-5 lh-5"><strong>Advertiser</strong></div>
                                                        <div className="col-2 text-left"><strong>Designed</strong></div>
                                                        <div className="col-2 text-left"><strong>Modified</strong></div>
                                                        <div className="col-3 text-left"><strong>&nbsp;</strong></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Rock Hill Trading Post, 1/4 Panel</div>
                                                <div className="col-2 text-left">08/11/20</div> {/* Date Created */}
                                                <div className="col-2 text-left">08/17/20</div> {/* Finalized */}

                                                {/*
                                                    - Ad Details:
                                                        - Date sold, etc
                                                    - Approved Status
                                                    - Materials Received
                                                    - Sales Rep
                                                */}

                                                <div className="col-3 text-center">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn btn-xs btn-square rounded btn-secondary"
                                                        style={{ padding: 0 }}
                                                    >
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div> {/* Agreement, Artwork, ARF, Payment Received */}
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">ADM Interiors</div>
                                                <div className="col-2 text-left">08/12/20</div>
                                                <div className="col-2 text-left">N/A</div>

                                                <div className="col-3 text-center">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Quality Pet Care</div>
                                                <div className="col-2 text-left">08/13/20</div>
                                                <div className="col-2 text-left">N/A</div>

                                                <div className="col-3 text-center">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 pr-0">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Agreements</div> {/* */}
                                        <div className="col-auto text-center">3</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Rejected</div> {/* */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Camera Ready</div>
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">LC Designing</div> {/* */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Templated</div> {/* */}
                                        <div className="col-auto text-center">0</div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Back Cover
                                            </div> {/* Broken down by renewals and prospects */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Inside Front Cover</div> {/* */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Inside Facing Front (Page 3)</div> {/* */}
                                        <div className="col-auto text-center">0</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Inside Back Cover</div> {/* */}
                                        <div className="col-auto text-center">0</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Inside Facing Back</div> {/* */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Full Page</div> {/* */}
                                        <div className="col-auto text-center">3</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">1/2 Page</div> {/* */}
                                        <div className="col-auto text-center">8</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">1/4 Page</div> {/* */}
                                        <div className="col-auto text-center">16</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 pl-0">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Project Name
                                                </small>
                                                {/*</Link>*/}
                                            </div>
                                        </div>

                                        <div className="col-auto text-right">
                                            <div>
                                                <strong>TBD</strong><br />
                                                <small>TBD</small> {/*moment(agreement.createdAt).format('L')*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col lh-5">
                                                    <div className="row">
                                                        <div className="col-5 lh-5"><strong>Advertiser</strong></div>
                                                        <div className="col-2 text-left"><strong>Designed</strong></div>
                                                        <div className="col-2 text-left"><strong>Modified</strong></div>
                                                        <div className="col-3 text-left"><strong>&nbsp;</strong></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Rock Hill Trading Post, 1/4 Panel</div>
                                                <div className="col-2 text-left">08/11/20</div> {/* Date Created */}
                                                <div className="col-2 text-left">08/17/20</div> {/* Finalized */}

                                                {/*
                                                    - Ad Details:
                                                        - Date sold, etc
                                                    - Approved Status
                                                    - Materials Received
                                                    - Sales Rep
                                                */}

                                                <div className="col-3 text-center">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn btn-xs btn-square rounded btn-secondary"
                                                        style={{ padding: 0 }}
                                                    >
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div> {/* Agreement, Artwork, ARF, Payment Received */}
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">ADM Interiors</div>
                                                <div className="col-2 text-left">08/12/20</div>
                                                <div className="col-2 text-left">N/A</div>

                                                <div className="col-3 text-center">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 lh-5">Quality Pet Care</div>
                                                <div className="col-2 text-left">08/13/20</div>
                                                <div className="col-2 text-left">N/A</div>

                                                <div className="col-3 text-center">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 pr-0">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Agreements</div> {/* */}
                                        <div className="col-auto text-center">3</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Rejected</div> {/* */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Camera Ready</div>
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">LC Designing</div> {/* */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Templated</div> {/* */}
                                        <div className="col-auto text-center">0</div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Back Cover
                                            </div> {/* Broken down by renewals and prospects */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Inside Front Cover</div> {/* */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Inside Facing Front (Page 3)</div> {/* */}
                                        <div className="col-auto text-center">0</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Inside Back Cover</div> {/* */}
                                        <div className="col-auto text-center">0</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Inside Facing Back</div> {/* */}
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Full Page</div> {/* */}
                                        <div className="col-auto text-center">3</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">1/2 Page</div> {/* */}
                                        <div className="col-auto text-center">8</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">1/4 Page</div> {/* */}
                                        <div className="col-auto text-center">16</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                            [-] Report to be produced by project and by total summary
                            [-] Art Work Need By Project and By Summary of all Projects
                            [-] Art Work Completed By Project and By Summary of all Projects
                            [-] Sizes of Ads by Project and By Summary of All Projects
                            [-] Name of Business and Contact by Project and By Summary of all projects
                            [-] Sales rep who sold the ad by project and by summary of all projects
                            [-] Date ad was sold by project and by summary of all projects
                            [-] Date Materials were received by project
                            [-] Date the ad was designed by project
                            [-] Date the ad was modified by project
                            [-] Date the ad was approved by project, by whom?
                            [-] Release forms needed by project and by summary for all projects
                            [-] Release forms completed by project and by summary for all projects
                            [-] Art Work Errors – list out by project and by total, reason for errors
                            [-] Art Work Rejected – list out by project and by total, reason for rejection
                            [-] Number of camera-ready artwork by project and summarized by total
                            [-] Number of LC designing by project and summarized by total
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default DesignerProductionReportPage;
