import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { useMutation } from 'urql';
import gql from 'graphql-tag';
import moment from 'moment-timezone';

interface IProps {
    employeeId: string;
    types: [{
        id: number;
        name: string;
    }]
};

const AddRequestForm: React.FC<IProps> = ({ employeeId, types }) => {
    const [typeId, setTypeId] = useState<number>(0);
    const [start, setStart] = useState<string>('');
    const [end, setEnd] = useState<string>('');
    const [halfDay, setHalfDay] = useState<boolean>(false);

    const [addRequestResult, executeRequestMutation] = useMutation(gql`mutation addRequest ($user_id: String!, $type_id: Int!, $start: timestamptz!, $end: timestamptz!, $half_day: Boolean) {
        insert_user_requests (objects: { user_id: $user_id, type_id: $type_id, start: $start, end: $end, half_day: $half_day }) {
            returning {
                id
                type_id
                start
                end
                half_day
            }
        }
    }`);

    const addRequest = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            executeRequestMutation({
                user_id: employeeId,
                type_id: typeId,
                start: moment(start).tz("America/New_York").format(),
                end: moment(end).tz("America/New_York").format(),
                half_day: halfDay
            });

            // Set the fields to empty
            setTypeId(0);
            setStart('');
            setEnd('');
            setHalfDay(false);
        } catch (err) {
            console.warn(err);
        }
    };

    return <form>
        <div className="border rounded shadow-2 p-4 mb-4 bg-white">
            <div className="row pb-4">
                <div className="col lh-5">
                    <select
                        className="form-control"
                        value={typeId || 0}
                        onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                        ): void => {
                            setTypeId(parseInt(e.target.value, 10))}
                        }
                    >
                        <option value="">-- Request Type --</option>
                        {types.map((type: { id: number; name: string; }) => (
                            <option key={nanoid()} value={type.id}>{type.name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Start (MM/DD/YYYY)"
                        value={start}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setStart(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="End (MM/DD/YYYY)"
                        value={end}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setEnd(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="form-group input-group" style={{ border: 'none' }}>
                <div className="input-group-prepend mr-4">
                    <button
                        className="btn btn-primary"
                        onClick={addRequest}
                        disabled={(!start || !end || !typeId)}
                    >
                        Add Request
                    </button>
                </div>

                <div className="custom-control custom-checkbox mt-1">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={halfDay}
                        onChange={() => setHalfDay(!halfDay)}
                    />

                    <label
                        className="custom-control-label"
                        onClick={() => setHalfDay(!halfDay)}
                    >
                        Half Day
                    </label>
                </div>
            </div>
        </div>
    </form>;
};

export default AddRequestForm;
