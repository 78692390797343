import React from 'react';
import { nanoid } from 'nanoid';
import moment from 'moment';

interface ICompanyProps {
    employee: any,
}

const InfoTab: React.FC<ICompanyProps> = ({ employee }) => {
    return <div className="row">
        <div className="col-md-4 pl-0">
            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                <div className="row">
                    <div className="col lh-5">Name</div>
                    <div className="col-auto text-center">{employee.name || 'Not Provided'}</div>
                </div>

                <div className="row">
                    <div className="col lh-5">Email</div>
                    <div className="col-auto text-center">{employee.email || 'Not Provided'}</div>
                </div>

                <div className="row">
                    <div className="col lh-5">Mobile</div>
                    <div className="col-auto text-center">{employee.phone || 'Not Provided'}</div>
                </div>

                <div className="row">
                    <div className="col lh-5">Address</div>
                    <div className="col-auto text-center">
                        {employee.line1}<br />
                        {`${employee.city}, ${employee.state ? employee.state.abbr : 'N/A'} ${employee.zip}`}
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-4 pl-0 pr-0">
            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                <div className="row">
                    <div className="col lh-5">Hire Date</div>
                    <div className="col-auto text-center">{moment(employee.hired).format('L') || 'Not Provided'}</div>
                </div>

                <div className="row">
                    <div className="col lh-5">Anniversary Date</div>
                    <div className="col-auto text-center">{moment(employee.anniversary).format('L') || 'Not Provided'}</div>
                </div>

                <div className="row">
                    <div className="col lh-5">Birthday</div>
                    <div className="col-auto text-center">{moment(employee.birthday).format('L') || 'Not Provided'}</div>
                </div>

                {employee.terminated &&
                    <div className="row">
                        <div className="col lh-5">Termination Date</div>
                        <div className="col-auto text-center">{moment(employee.terminated).format('L') || ''}</div>
                    </div>
                }
            </div>
        </div>

        <div className="col-md-4 pr-0">
            <table className="table table-cart bg-white shadow-2">
                <tbody>
                    {(employee.salaries.length === 0) && 
                        <tr key={nanoid()}>
                            <td colSpan={11}>
                                <h5 className="mx-auto">User does not have any salaries</h5>
                            </td>
                            <td colSpan={1}>
                                <h5 className="mx-auto">&nbsp;</h5>
                            </td>
                        </tr>
                    }

                    {employee.salaries.map((
                        salary: {
                            id: string;
                            salary: number;
                            comments: string;
                            date: string;
                        }) => (
                        <tr key={nanoid()}>
                            <td colSpan={6}>
                                <h5>${salary.salary}</h5>
                                <p>{salary.comments ? salary.comments : 'No comments'}</p>
                            </td>

                            <td colSpan={6}>
                                <h5>{salary.date ? moment(salary.date).format('L') : 'No Date'}</h5>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>;
}

export default InfoTab;
