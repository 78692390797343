import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';

// TODO: Import components

interface IContactPageProps extends RouteComponentProps<{}> { }

interface IState { }

class ContactPage extends React.Component<IContactPageProps, IState> {
    // Define the constructor for the contact page
    constructor (props: IContactPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the contact container
    render () {
        return (
            <FullPage>
                Contact page
            </FullPage>
        );
    }
}

export default ContactPage;
