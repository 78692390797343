import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IDesignerVarianceSummaryReportPageProps extends RouteComponentProps { }

interface IState { }

class DesignerVarianceSummaryReportPage extends React.Component<IDesignerVarianceSummaryReportPageProps, IState> {
    // Define the constructor for the ad audit page
    constructor (props: IDesignerVarianceSummaryReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    something = () => {

    };

    // Render the TSX for the ad audit container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Designer Variance Report</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">
                                            <span className="badge badge-secondary mr-1">Day</span>
                                            <span className="badge badge-primary mr-1">Week</span>
                                            <span className="badge badge-secondary mr-1">Month</span>
                                            <span className="badge badge-secondary mr-1">Quarter</span>
                                            <span className="badge badge-secondary mr-1">Year</span>
                                            <span className="badge badge-secondary mr-1">All Time</span>
                                        </span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Designer Variance Summary report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col lh-5">
                                                    <div className="row">
                                                        <div className="col-6 lh-5"><strong>Client</strong></div>
                                                        <div className="col-2 text-left"><strong>Est Hours</strong></div>
                                                        <div className="col-2 text-left"><strong>Hours</strong></div>
                                                        <div className="col-2 text-left"><strong>Diff Hours</strong></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 lh-5">Auburn-Opelika Tourism Bureau</div>
                                                <div className="col-2 text-left">137.00</div>
                                                <div className="col-2 text-left">81.75</div>
                                                <div className="col-2 text-left">55.25</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 lh-5">Boulder City</div>
                                                <div className="col-2 text-left">111.50</div>
                                                <div className="col-2 text-left">30.12</div>
                                                <div className="col-2 text-left">81.38</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 lh-5">Citrus County</div>
                                                <div className="col-2 text-left">146.50</div>
                                                <div className="col-2 text-left">136.37</div>
                                                <div className="col-2 text-left">10.13</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 lh-5">Cocoa Beach</div>
                                                <div className="col-2 text-left">115.50</div>
                                                <div className="col-2 text-left">64.49</div>
                                                <div className="col-2 text-left">51.01</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 lh-5">North Tampa Bay</div>
                                                <div className="col-2 text-left">127.50</div>
                                                <div className="col-2 text-left">12.40</div>
                                                <div className="col-2 text-left">115.10</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 lh-5">&nbsp;</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 lh-5"><strong>Total</strong></div>
                                                <div className="col-2 text-left"><strong>7,430.00</strong></div>
                                                <div className="col-2 text-left"><strong>2,415.55</strong></div>
                                                <div className="col-2 text-left"><strong>5,014.45</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default DesignerVarianceSummaryReportPage;
