import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';

// TODO: Import components

interface ICreateEmployeePageProps extends RouteComponentProps { }

interface IState { }

/*
    TODO:
        - Create a firebase user (password doesn't matter since it's google oauth)
        - Receive the ID created
        - Insert a user into the user table with the appropriate ID, name, email, type, and subtype
*/

class CreateEmployeePage extends React.Component<ICreateEmployeePageProps, IState> {
    // Define the constructor for the create employee page
    constructor (props: ICreateEmployeePageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the create employee container
    render () {
        return (
            <FullPage>
                Create Employee page
            </FullPage>
        );
    }
}

export default CreateEmployeePage;
