import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import moment from 'moment';
import { useQuery } from 'urql';
import FullPage from '../../layouts/FullPage';
import ls from 'local-storage';

import InfoTab from '../../components/account/InfoTab';
import RequestTab from '../../components/account/RequestTab';

import { AccountQuery } from '../../queries/AccountQuery';

interface IAccountPageProps extends RouteComponentProps {}

const AccountPage: React.FC<IAccountPageProps> = () => {
    const [reqYearFilter, setReqYearFilter] = useState<number>(moment().year());

    const [accData, reexecuteQuery] = useQuery({
        query: AccountQuery,
        variables: {
            employeeId: ls.get('uid'),
            dateStart: `${reqYearFilter}-01-01`,
            dateEnd: `${reqYearFilter}-12-31`
        }
    });

    const refresh = () => reexecuteQuery({ requestPolicy: 'cache-and-network' });

    const updateYearFilter = (year: number) => {
        setReqYearFilter(year);
        // refresh();
    };

    if (!accData.data) {
        return <FullPage>
            <div className="row gap-y">
                <div className="col-12 col-lg-2">
                    Loading...
                </div>
            </div>
        </FullPage>;
    }

    return <FullPage>
        <section className="section" style={{ paddingTop: '1rem' }}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                    <div className="container d-stick-none">
                        <div className="row">
                            <div className="col-8">
                                <div className="nav nav-navbar">
                                    <span className="nav-link active">{accData.data.users[0].name}</span>
                                </div>
                            </div>

                            <div className="col-4 ml-auto">
                                <nav className="nav nav-navbar justify-content-end">
                                    <span className="nav-link">{accData.data.users[0].type.name}</span>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <hr className="my-0 w-100" />

                    <div className="container">
                        <div className="navbar-left">
                            <button className="navbar-toggler" type="button">
                                <span className="navbar-toggler-icon" />
                            </button>
                        </div>

                        <section className="navbar-mobile">
                            <div className="nav nav-navbar mr-auto">
                                <a href="#info" className="nav-link active" data-toggle="tab">Info</a>
                                <a href="#requests" className="nav-link" data-toggle="tab">Requests</a>
                            </div>

                            <nav className="nav nav-navbar ml-lg-5">
                                <li className="nav-item">
                                    &nbsp;
                                    {/* <a className="nav-link" href="#">&nbsp;</a> */}
                                </li>
                            </nav>
                        </section>
                    </div>
                </nav>
            </div>
        </section>

        <div className="tab-content p-4">
            <section className="section tab-pane show active" id="info" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <InfoTab employee={accData.data.users[0]} />
                </div>
            </section>

            <section className="section tab-pane" id="requests" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <RequestTab
                        employee={accData.data.users[0]}
                        reqs={accData.data.users[0].requests}
                        reqTypes={accData.data.user_request_types}
                        currYear={reqYearFilter}
                        reqYears={accData.data.request_years.aggregate}
                        updateYearFilter={updateYearFilter}
                        refresh={refresh}
                    />
                </div>
            </section>
        </div>
    </FullPage>;
};

export default AccountPage;
