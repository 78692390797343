import React, { useState } from 'react';
import { useMutation } from 'urql';
import gql from 'graphql-tag';

interface IProps {
    employeeId: string;
};

const AddDocumentForm: React.FC<IProps> = ({ employeeId }) => {
    const [name, setName] = useState<string>('');
    const [url, setUrl] = useState<string>('');

    const [addDocumentResult, executeDocumentMutation] = useMutation(gql`mutation addIce ($user_id: String!, $name: String!, $relationship: String!, $email: String!, $phone: String!) {
        insert_user_ice (objects: { user_id: $user_id, name: $name, relationship: $relationship, email: $email, phone: $phone }) {
            returning {
                id
                name
            }
        }
    }`);

    const addDocument = async (e: React.FormEvent) => {
        e.preventDefault();

        /*
        try {
            executeIceMutation({
                user_id: employeeId,
                name,
                relationship,
                phone,
                email
            });

            // Set the fields to empty
            setName('');
            setRelationship('');
            setPhone('');
            setEmail('');
            // TODO: insert ice into array
        } catch (err) {
            console.warn(err);
        }
        */
    };

    return <form>
        <div className="border rounded shadow-2 p-4 mb-4 bg-white">
            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setName(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Url"
                        value={url}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setUrl(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row">
                <div className="col lh-5 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={addDocument}
                    >
                        Add Document
                    </button>
                </div>
            </div>
        </div>
    </form>;
};

export default AddDocumentForm;
