import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IProject {
    name: string;
    hours: number;
}

interface ISalesRepHoursReportPageProps extends RouteComponentProps { }

interface IState {
    projects: IProject[]
}

class SalesRepHoursReportPage extends React.Component<ISalesRepHoursReportPageProps, IState> {
    // Define the constructor for the sales rep hours report page
    constructor (props: ISalesRepHoursReportPageProps) {
        // Pull in parent props
        super(props);

        this.state = {
            projects: [{
                name: 'Fenton',
                hours: 3.5
            }, {
                name: 'Cocoa Beach',
                hours: 5
            }, {
                name: 'Sullivan',
                hours: 9.5
            }]
        }
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the sales rep hours report container
    render () {
        const { projects } = this.state;

        // Sum by reduction the hours
        const total_hours = projects.reduce((l, r) => l + r.hours, 0);

        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Sales Rep Hours Report for Employee Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">
                                            <span className="badge badge-secondary mr-1">Day</span>
                                            <span className="badge badge-primary mr-1">Week</span>
                                            <span className="badge badge-secondary mr-1">Month</span>
                                            <span className="badge badge-secondary mr-1">Quarter</span>
                                            <span className="badge badge-secondary mr-1">Year</span>
                                            <span className="badge badge-secondary mr-1">All Time</span>
                                        </span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                {/*
                    [-] Agent Name
                    [-] All Projects by Name
                    [-] Hours summarized by total broken out by: day / week / month / year
                */}
                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Sales Rep Hours report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col lh-5">
                                                    <div className="row">
                                                        <div className="col-8 lh-5"><strong>Project</strong></div>
                                                        <div className="col-4 text-left"><strong>Hours</strong></div>
                                                    </div>
                                                </div>
                                            </div>

                                            {projects.map(project => (
                                                <div className="row">
                                                    <div className="col-8 lh-5">{project.name}</div>
                                                    <div className="col-4 text-left">{project.hours}</div>
                                                </div>
                                            ))}

                                            <div className="row">
                                                <div className="col-12 lh-5">&nbsp;</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-8 lh-5"><strong>TOTAL</strong></div>
                                                <div className="col-4 text-left"><strong>{total_hours}</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default SalesRepHoursReportPage;
