import React from 'react';
import { nanoid } from 'nanoid';
import moment from 'moment';

import AddUserRequestForm from './AddUserRequestForm';

interface ICompanyProps {
    employee: any;
    reqs: any;
    reqTypes: [{
        id: number;
        name: string;
    }];
    currYear: number;
    reqYears: any;
    updateYearFilter: (year: number) => void;
    refresh: () => void;
};

const RequestTab: React.FC<ICompanyProps> = ({ employee, reqs, reqTypes, currYear, reqYears, updateYearFilter, refresh }) => {
    // Define the variables to hold the total days
    let vacationDaysUsed = 0;
    let sickDaysUsed = 0;
    let personalDaysUsed = 0;
    let loaDaysUsed = 0;

    // If there are any requests
    if (reqs && reqs.length > 0) {
        // Iterate over the request and total the days for each type
        reqs.map((req: any) => {
            // Only add the days to the total if the request was approved
            if (req.reviewed && req.approved) {
                if (req.type.id === 1) { // Vacation request
                    // Sum the days for the request and add it to the vacation total
                    vacationDaysUsed += Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1;
                } else if (req.type.id === 2) { // Sick request
                    // Sum the days for the request and add it to the sick total
                    sickDaysUsed += Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1;
                } else if (req.type.id === 3) { // Personal day
                    // Sum the days for the request and add it to the personal total
                    personalDaysUsed += Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1;
                } else { // Leave of absence (type.id 4)
                    // Sum the days for the request and add it to the leave of absence total
                    loaDaysUsed += Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1;
                }
            }
        });
    }

    // Extract the year from the min and max. They start off as timestamps (0000-00-00T00:00:00+00:00)
    const minYear = (reqYears && reqYears.min && reqYears.min.start) ? reqYears.min.start.substring(0, 4) : moment().year();
    const maxYear = (reqYears && reqYears.max && reqYears.max.start) ? reqYears.max.start.substring(0, 4) : moment().year();

    // Create an array for the year range so that we can iterate over it for our filter
    const years = Array(maxYear - (minYear - 1)).fill('').map((v, idx) => maxYear- idx) as Array<number>;

    // Return the dom for the request tab
    return <div className="row">
        <div className="col-md-4 mx-auto pl-0">
            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                <div className="row">
                    <div className="col lh-5">Vacation Days Used</div>
                    <div className="col-auto text-center">{vacationDaysUsed} / {employee.benefits.days_vacation || '0'}</div>
                </div>

                <div className="row">
                    <div className="col lh-5">Sick Days Used</div>
                    <div className="col-auto text-center">{sickDaysUsed} / {employee.benefits.days_sick || '0'}</div>
                </div>

                <div className="row">
                    <div className="col lh-5">Personal Days Used</div>
                    <div className="col-auto text-center">{personalDaysUsed} / {employee.benefits.days_personal || '0'}</div>
                </div>

                <div className="row">
                    <div className="col lh-5">Leave of Absence Days Used</div>
                    <div className="col-auto text-center">{loaDaysUsed}</div>
                </div>
            </div>

            <AddUserRequestForm
                employeeId={employee.id}
                types={reqTypes}
                refresh={refresh}
            />
        </div>

        <div className="col-md-8 mx-auto pl-0 pr-0">
            <div className="border bg-white shadow-2 p-2 mb-4">
                <div className="row align-items-center">
                    <div className="col lh-5">
                        <a className="text-default ml-2 fw-400" href="#">Filter By Year</a>
                        <span className="topbar-divider"></span>
                        {years.map(year =>
                            <span
                                key={nanoid()}
                                className={`badge badge-${currYear === year ? 'primary' : 'secondary'} mr-1`}
                                onClick={() => updateYearFilter(year)}
                                style={{ cursor: 'pointer' }}
                            >
                                {year}
                            </span>
                        )}
                    </div>

                    <div className="col-auto">
                        <a className="text-default mr-2 fw-400" href="#">{reqs.length || 0} Requests</a>
                    </div>
                </div>
            </div>

            <table className="table table-cart bg-white shadow-2">
                <tbody>
                    {(reqs.length === 0) && 
                        <tr key={nanoid()}>
                            <td colSpan={11}>
                                <h5 className="mx-auto">You do not have any requests</h5>
                            </td>
                            <td colSpan={1}>
                                <h5 className="mx-auto">&nbsp;</h5>
                            </td>
                        </tr>
                    }

                    {reqs.map((
                        req: {
                            id: string;
                            reviewed: boolean;
                            approved: boolean;
                            start: string;
                            end: string;
                            deny_reason: string;

                            type: {
                                id: number;
                                name: string;
                            }
                        }) => (
                        <tr key={nanoid()} style={{
                            borderLeft: `${
                                req.reviewed
                                ? (req.approved ? '3px solid #3cd458' : '3px solid #ff4954')
                                : '3px solid #74b4ff'
                            }`
                        }}>
                            <td colSpan={4}>
                                <h5>{req.type.name}</h5>
                                <p>{req.reviewed ? (req.approved ? 'Approved' : 'Denied') : 'Status Pending'}</p>
                            </td>

                            <td colSpan={4}>
                                <h5><span className="badge badge-pale badge-secondary fw-600">{Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1} days</span></h5>
                            </td>

                            <td colSpan={4}>
                                <h5>{moment(req.start).format('L')}</h5>
                                <p>{moment(req.end).format('L')}</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>;
}

export default RequestTab;
