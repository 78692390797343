import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface ISalesAuditReportPageProps extends RouteComponentProps { }

interface IState { }

class SalesAuditReportPage extends React.Component<ISalesAuditReportPageProps, IState> {
    // Define the constructor for the sales audit report page
    constructor (props: ISalesAuditReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the sales audit report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Sales Audit Report</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">
                                            <span className="badge badge-secondary mr-1">Day</span>
                                            <span className="badge badge-primary mr-1">Week</span>
                                            <span className="badge badge-secondary mr-1">Month</span>
                                            <span className="badge badge-secondary mr-1">Quarter</span>
                                            <span className="badge badge-secondary mr-1">Year</span>
                                            <span className="badge badge-secondary mr-1">All Time</span>
                                        </span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Sales Audit report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Project Name
                                                </small>
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Total agreements, total agreement dollars, total paid, total unpaid */}

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Advertiser</strong></div>
                                                <div className="col-1 text-left"><strong>Date</strong></div>
                                                <div className="col-2 text-left"><strong>Size</strong></div>
                                                <div className="col-2 text-left"><strong>Amount</strong></div>
                                                <div className="col-2 text-left"><strong>Rep</strong></div>
                                                <div className="col-2 text-left"><strong>&nbsp;</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5">Albella Family Restaurant</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/4 Page</div>
                                                <div className="col-2 text-left">$665</div>
                                                <div className="col-2 text-left">Abigail Riley</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary bg-success text-white mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded bg-warning text-white btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Beer World</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/4 Page</div>
                                                <div className="col-2 text-left">$800</div>
                                                <div className="col-2 text-left">Marsha McKinsey</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Bethel Business Association</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/2 Page</div>
                                                <div className="col-2 text-left">$395</div>
                                                <div className="col-2 text-left">Diana Riley</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Building Traditions</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">Full Page</div>
                                                <div className="col-2 text-left">$1,000</div>
                                                <div className="col-2 text-left">Christian Hinton</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Downtown Barn</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/8 Page</div>
                                                <div className="col-2 text-left">$625</div>
                                                <div className="col-2 text-left">Christian Hinton</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Project Name
                                                </small>
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Total agreements, total agreement dollars, total paid, total unpaid */}

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Advertiser</strong></div>
                                                <div className="col-1 text-left"><strong>Date</strong></div>
                                                <div className="col-2 text-left"><strong>Size</strong></div>
                                                <div className="col-2 text-left"><strong>Amount</strong></div>
                                                <div className="col-2 text-left"><strong>Rep</strong></div>
                                                <div className="col-2 text-left"><strong>&nbsp;</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5">Albella Family Restaurant</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/4 Page</div>
                                                <div className="col-2 text-left">$665</div>
                                                <div className="col-2 text-left">Abigail Riley</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Beer World</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/4 Page</div>
                                                <div className="col-2 text-left">$800</div>
                                                <div className="col-2 text-left">Marsha McKinsey</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Bethel Business Association</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/2 Page</div>
                                                <div className="col-2 text-left">$395</div>
                                                <div className="col-2 text-left">Diana Riley</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Building Traditions</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">Full Page</div>
                                                <div className="col-2 text-left">$1,000</div>
                                                <div className="col-2 text-left">Christian Hinton</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Downtown Barn</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/8 Page</div>
                                                <div className="col-2 text-left">$625</div>
                                                <div className="col-2 text-left">Christian Hinton</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                            [-] Project Name
                            [-] Date of Report
                            [-] Name of Advertiser
                            [-] Advertising Sales Rep
                            [-] Date of advertising sale Completed?
                            [-] Size of Advertisement
                            [-] Amount of Sale
                            [-] Payment Status (Paid / Unpaid)
                            [-] Art Work Status (Collected / Not Collected)
                            [-] Release Form Status (Collected / Not Collected)
                            [-] Payment received Date
                            [-] Art Work Received Date
                            [-] Release Form Received Date
                            [-] Notes
                            */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default SalesAuditReportPage;
