import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IAdAuditPageProps extends RouteComponentProps<{}> { }

interface IState { }

class AdAuditPage extends React.Component<IAdAuditPageProps, IState> {
    // Define the constructor for the ad audit page
    constructor (props: IAdAuditPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the ad audit container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Ad Audit</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Ad Audit report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    {/* <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col lh-5">
                                            {/* <h6 className="divider">Completed Agreements</h6> */}

                                            <div className="row">
                                                <div className="col lh-5">
                                                    <div className="row">
                                                        <div className="col-4 lh-5"><strong>Advertiser</strong></div>
                                                        <div className="col-3 text-left"><strong>Ad Size</strong></div>
                                                        <div className="col-2 text-left"><strong>Docs</strong></div>
                                                        <div className="col-3 text-left"><strong>Notes</strong></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-4 lh-5">Rock Hill Trading Post</div>
                                                <div className="col-3 text-left">1/4 Panel</div> {/* Date Created */}

                                                <div className="col-2 text-left">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary bg-success text-white mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-4 lh-5">Wayward Social</div>
                                                <div className="col-3 text-left">1/2 Panel</div> {/* Date Created */}

                                                <div className="col-2 text-left">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary bg-success text-white mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary bg-success text-white" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-4 lh-5">Holiday Inn &amp; Suites</div>
                                                <div className="col-3 text-left">Inside Front Cover</div> {/* Date Created */}

                                                <div className="col-2 text-left">
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44771 7 6 6.55228 6 6Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 10C6 9.44771 6.44772 9 7 9H17C17.5523 9 18 9.44771 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44771 15 7 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H7Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M6 18C6 17.4477 6.44772 17 7 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H7C6.44772 19 6 18.5523 6 18Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2 4C2 2.34315 3.34315 1 5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4ZM5 3H19C19.5523 3 20 3.44771 20 4V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4C4 3.44772 4.44771 3 5 3Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary bg-success text-white" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                                            <path d="M13 11H7V9H13V11Z" fill="currentColor" />
                                                            <path d="M7 15H13V13H7V15Z" fill="currentColor" />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default AdAuditPage;
