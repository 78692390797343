import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { auth, provider } from '../../firebase/firebase.utils';
import ls from 'local-storage';
// import moment from 'moment-timezone';
import moment from 'moment';

interface ILoginPageProps extends RouteComponentProps {}

// TODO: Check if the user is logged in or not. Redirect to the dashboard if they are

// Render the login page
const LoginPage: React.FC<ILoginPageProps> = () => {
    // Handle logging the user in
    const login = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // Attempt to sign in with the Google Auth Provider
        auth.signInWithPopup(provider)
            .then(res => {
                // Capture the data returned from a successful login
                const user = res.user;

                // Set the user ID and other info that comes packaged with auth()
                ls.set('uid', user!.uid);
                ls.set('refreshToken', user!.refreshToken);
                ls.set('email', user!.email);
                // ls.set('loggedInAt', moment().tz('America/New_York').format());
                // ls.set('loggedInAt', Date.now());
                ls.set('loggedInAt', moment());
            })
            .then(() => navigate('/dashboard'))
            .catch(err => {
                // TODO: Show the error to the user on the front end and not just the console
                console.warn('err authentication', ' => ', err);
            });
    };

    return <div className="layout-centered bg-img" style={{ backgroundImage: "url(https://lunarcow.nyc3.digitaloceanspaces.com/img/bg/12.jpg)", height: '100vh' }}>
        <main className="main-content" style={{ height: '100vh' }}>
            <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                <h5 className="mb-7">Employee Portal Login</h5>

                <form>
                    <div className="form-group">
                        <button
                            className="btn btn-block btn-google"
                            onClick={login}>
                            <i className="fa fa-google" /> Sign In With Google
                        </button>
                    </div>

                    <div className="form-group flexbox py-3">
                        <p>If you forgot your password, you'll have to reset it on your <a href="https://accounts.google.com" target="_blank">Google account</a>. Contact the IT department at extension 118 if you're still having difficulty.</p>
                    </div>
                </form>
            </div>
        </main>
    </div>;
};

export default LoginPage;
