import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IClientSpecsReportPageProps extends RouteComponentProps { }

interface IState { }

class ClientSpecsReportPage extends React.Component<IClientSpecsReportPageProps, IState> {
    // Define the constructor for the client specs report page
    constructor (props: IClientSpecsReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the client specs report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Client Specs Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        <div className="row">
                            <div className="col-6 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Client Specs report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-7 lh-5"><strong>Spec</strong></div>
                                                <div className="col-5 text-right"><strong>Selection</strong></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Project Type</div>
                                                <div className="col-5 text-right">Visitors Guide</div> {/* (Visitors Guide / Business Directory / Map) */}
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Contact Name</div>
                                                <div className="col-5 text-right">John Glenn</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Printer</div>
                                                <div className="col-5 text-right">PRINTER NAME</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Print Costs</div>
                                                <div className="col-5 text-right">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Start Date</div>
                                                <div className="col-5 text-right">03/08/20</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Est Completion Date</div>
                                                <div className="col-5 text-right">11/24/20</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Publication Size</div>
                                                <div className="col-5 text-right">8 3/8 X 10 7/8</div> {/* (8 3/8 X 10 7/8, 5 3/8 X 8 3/8, 4 X 9, OR Map Flat Size and Finished Size */}
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Units To Be Produced</div>
                                                <div className="col-5 text-right">15,000</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Total Pages</div>
                                                <div className="col-5 text-right">64</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Number of Cover Pages</div>
                                                <div className="col-5 text-right">4</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Number of Body Pages</div>
                                                <div className="col-5 text-right">60</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Binding</div>
                                                <div className="col-5 text-right">Saddle Stitched</div> {/* (Saddle Stitched / Perfect Binding) */}
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Special Coating</div>
                                                <div className="col-5 text-right">Soft Touch</div> {/* (Soft Touch, UV, Aqueous) */}
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Delivery</div>
                                                <div className="col-5 text-right">Inside</div> {/* (Inside / No Inside / Pallet Jack / Lift Gate) */}
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Editorial</div>
                                                <div className="col-5 text-right">Need to Provide</div> {/* (Provided / Need to Provide) */}
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Images</div>
                                                <div className="col-5 text-right">Provided</div> {/* (Provided / Need to Provide) */}
                                            </div>
                                            <div className="row">
                                                <div className="col-7 lh-5">Map Files</div>
                                                <div className="col-5 text-right">Have</div> {/* (Have Them / Need Them) */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                            [-] Project Name
                            [-] Client Name / Contact Name
                            [-] Project Start Date
                            [-] Project Estimated Completion Date
                            [-] Type of Project
                            [-] Publications Size:
                            [-] Number of Units To Be Produced
                            [-] Total Pages of Publication
                            [-] Number of Cover Pages
                            [-] Number of Body Pages
                            [-] Binding
                            [-] Special Coating
                            [-] Delivery
                            [-] Editorial
                            [-] Images (Provided / Need to Provide)
                            [-] Map Files

                            [-] Number of Timeline Modifications
                            [-] Why Timeline Was Modified
                            [-] Number of Meetings Missed
                            [-] Reason Why Meetings Were Missed
                            [-] Revised Estimated Completion Date
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default ClientSpecsReportPage;
