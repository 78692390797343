import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import { nanoid } from 'nanoid';
import moment from 'moment-timezone';

import { useQuery, useMutation } from 'urql';
import gql from 'graphql-tag';

// import AddMarketingListEvent from '../../components/projects/AddMarketingListEvent';
// import AddMarketingListCommunication from '../../components/projects/AddMarketingListCommunication';

import ProjectMarketingListItem from '../../components/projects/ProjectMarketingListItem';

// TODO: Import components

// interface IProjectMarketingListPageProps extends RouteComponentProps { }
// interface IState { }

interface MatchParams { id: string; }
interface MatchProps extends RouteComponentProps<MatchParams> { }

const q = gql`
    query GetProjectMarketingList ($projectId: String!) {
        projects (where: {id: {_eq: $projectId}}) {
            id
            name
            client_id
        }

        users (where: {type_id: {_eq: 3}, project_id: {_eq: $projectId}}) {
            id
            name
            title
            email
            url
            phone
            line1
            line2
            city
            zip
            comments
            contact_name

            state {
                id
                name
                abbr
            }

            type {
                id
                name
            }

            communications {
                id
                comment

                type {
                    id
                    name
                }

                disposition {
                    id
                    name
                }
            }

            events {
                id
                desc
                time

                type {
                    id
                    name
                }
            }
        }

        communication_dispositions(order_by: {name: asc}) {
            id
            name
        }

        communication_types(order_by: {name: asc}) {
            id
            name
        }

        event_types(order_by: {name: asc}) {
            id
            name
        }
    }
`;

// class ProjectMarketingListPage extends React.Component<IProjectMarketingListPageProps, IState> {
const ProjectMarketingListPage : React.FC<MatchProps> = (match: MatchProps) => {
    const projectId = match.id;

    const [size, setSize] = useState<number>(0);

    // const [eventTypeId, setEventTypeId] = useState<string>('');
    // const [eventTime, setEventTime] = useState<string>('');
    // const [eventDesc, setEventDesc] = useState<string>('');
    // const [eventAdded, setEventAdded] = useState<boolean>(false);

    // const [communicationTypeId, setCommunicationTypeId] = useState<number>(0);
    // const [communicationComment, setCommunicationComment] = useState<string>('');
    // const [communicationDispositionId, setCommunicationDispositionId] = useState<number>(0);
    // const [communicationAdded, setCommunicationAdded] = useState<boolean>(false);

    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: { projectId }
    });

    /*
    const [addDispositionResult, executeDispositionMutation] = useMutation(gql`mutation AddCommunication ($id: String!, $user_id: String!, $type_id: Int!, $start: timestamptz!, $end: timestamptz!, $comment: String!, $disposition_id: Int!) {
        insert_ml_communications (objects: { id: $id, user_id: $user_id, type_id: $type_id, start: $start, end: $end, comment: $comment, disposition_id: $disposition_id }) {
            returning {
                id
                user_id
                type_id
                start
                end
                comment
                disposition_id
            }
        }
    }`);
    */

    /*
    const [addEventResult, executeEventMutation] = useMutation(gql`mutation AddEvent ($id: String!, $type_id: Int!, $user_id: String!, $time: String!, $desc: String!) {
        insert_ml_events (objects: { id: $id, type_id: $type_id, user_id: $user_id, time: $time, desc: $desc }) {
            returning {
                id
                type_id
                user_id
                time
                desc
            }
        }
    }`);
    */

    const { data, fetching, error } = result;

    if (fetching) {
        return <FullPage>
            <div className="row gap-y">
                <div className="col-12 col-lg-2">
                    Loading...
                </div>
            </div>
        </FullPage>;
    }

    /*
    if (!data) {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '2rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto p-4">
                                No Data
                            </div>
                        </div>
                    </div>
                </section>
            </FullPage>
        );
    }
    */

    console.log('data', ' => ', data);

    const project = data.projects[0];
    const marketingList = data.users;

    const removeMarketingListItem = (e: React.FormEvent, id: number) => {};
    const notInterested = (e: React.FormEvent, id: number) => {};
    const addAgreement = (e: React.FormEvent, id: number) => {};

    /*
    const addCommunication = (e: React.FormEvent, contactId: string) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            const now = moment();

            executeDispositionMutation({
                id: nanoid(),
                user_id: contactId,
                // rep_id: ls.get('uid'),
                type_id: communicationTypeId,
                start: moment(now).tz("America/New_York").format(),
                end: moment(now).tz("America/New_York").format(),
                comment: communicationComment,
                disposition_id: communicationDispositionId,
                // disposition_desc: communicationComment
            });

            // Clear the form inputs
            setCommunicationTypeId(0);
            setCommunicationComment('');
            setCommunicationDispositionId(0);

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setCommunicationAdded(true);
        } catch (err) {
            console.warn(err);
        }
    };
    */

    /*
    const addEvent = (e: React.FormEvent, contactId: string,) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            executeEventMutation({
                type_id: eventTypeId,
                // employee_id: ls.get('uid'),
                user_id: contactId,
                // client_id: company.id,
                time: eventTime,
                desc: eventDesc
            });

            // NOTE: Format the time as such, if possible: YYYY-MM-DD HH:MM:SS

            // Clear the form inputs
            setEventTypeId('');
            setEventTime('');
            setEventDesc('');

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setEventAdded(true);
        } catch (err) {
            console.warn(err);
        }
    };
    */

    // Define the constructor for the project marketing list page
    /*
    constructor (props: IProjectMarketingListPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }
    */

    // Render the TSX for the project marketing list container
    return (
        <FullPage>
            <section className="section" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto p-4">
                            <h6 className="divider">Call List for {project.name}</h6>

                            {data.users.length > 0 &&
                                <div className="accordion accordion-arrow-right" id="ml-accordion">
                                    {data.users.map((prospect: any, key: any) => (
                                        <ProjectMarketingListItem
                                            prospect={prospect}
                                            communicationTypes={data.communication_types}
                                            communicationDispositions={data.communication_dispositions}
                                            eventTypes={data.event_types}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </FullPage>
    );
}
// }

export default ProjectMarketingListPage;
