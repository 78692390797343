import React, { useState } from 'react';
import { nanoid } from 'nanoid';

interface IProps {
    appointments: any,
}

const SetAppointmentList: React.FC<IProps> = (props) => {
    const [amount, setAmount] = useState<string>("0");

    if (props.appointments.length < 1) {
        return <div className="alert alert-info" role="alert">User does not have any set appointments</div>;
    }

    return (
        <React.Fragment>
            {props.appointments.map((appointment: any) => (
                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                    <div className="row align-items-center">
                        <div className="col lh-5">
                            <div>
                                <strong>{appointment.contact.name}</strong><br />
                                <small className="text-muted">
                                    {appointment.contact.email}
                                </small>
                            </div>
                        </div>

                        <div className="col-auto text-right">
                            <div>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Amount</span>
                                    </div>
                                    <input
                                        className="form-control"
                                        placeholder=""
                                        value={amount || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setAmount(e.target.value)} 
                                    />
                                </div>
                                <button className="btn btn-xs btn-outline-success">Approve</button> <button className="btn btn-xs btn-outline-danger">Reject</button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
};

export default SetAppointmentList;
