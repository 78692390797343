import React, { useEffect, useState } from 'react';
import { Link, navigate, RouteComponentProps } from '@reach/router'; // match
import { useQuery, useMutation } from 'urql';
import FullPage from '../../layouts/FullPage';
import gql from 'graphql-tag';

interface MatchParams { id: string; }
interface MatchProps extends RouteComponentProps<MatchParams> { }

const q = gql`
    query($userId: String!) {
        users(where: {id: {_eq: $userId}}) {
            city
            do_not_call
            email
            ext
            fax
            id
            is_client
            lead_type
            line1
            line2
            name
            phone
            state_str
            state_id
            toll_free
            url
            zip

            members

            subtype_id
            status_id
        }

        states (order_by: {name:asc}) {
            id
            name
        }

        statuses: company_statuses (order_by: {name:asc}) {
            id
            name
        }

        types: company_types (order_by: {name:asc}) {
            id
            name
        }
    }
`;

const CompanyEditPage: React.FC<MatchProps> = (match: MatchProps) => {
    const userId = match.id;

    const [editState, setEditState] = useState({
        name: '',
        type: 0,
        status: 0,

        url: '',
        email: '',
        phone: '',
        tollFree: '',
        fax: '',

        line1: '',
        line2: '',
        city: '',
        state: 0,
        zip: '',

        members: 0
    });

    const [editCompanyResult, executeEditCompanyMutation] = useMutation(gql`mutation EditCompany (
        $id: String!,
        $name: String!,
        $type: Int!,
        $status: Int!,
        $url: String!,
        $email: String!,
        $phone: String!,
        $tollFree: String!,
        $fax: String!,
        $line1: String!,
        $line2: String!,
        $city: String!,
        $state: Int!,
        $zip: String!,
        $members: Int!
    ) {
        update_users_by_pk(
            pk_columns: {id: $id},
            _set: {
                name: $name,
                subtype_id: $type,
                status_id: $status,
                
                url: $url,
                email: $email,
                phone: $phone,
                toll_free: $tollFree,
                fax: $fax,
                
                line1: $line1,
                line2: $line2,
                city: $city,
                state_id: $state,
                zip: $zip,

                members: $members
            }
        ) {
            id
        }
    }`);

    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: { userId }
    });

    const { data, fetching, error } = result;

    // Get the company data out of the response
    const company = data ? data.users[0] : {};

    useEffect(() => {
        if (company && 'name' in company) {
            setData(company);
        }
    }, [company]);

    const setData = (company: any) => {
        setEditState({
            name: company.name,
            type: company.subtype_id,
            status: company.status_id,
            
            url: company.url,
            email: company.email,
            phone: company.phone,
            tollFree: company.toll_free,
            fax: company.fax,
            
            line1: company.line1,
            line2: company.line2,
            city: company.city,
            state: company.state_id,
            zip: company.zip,

            members: company.members
        });
    };

    if (fetching) {
        return <FullPage>
            <div className="row gap-y">
                <div className="col-12 col-lg-2">
                    Loading...
                </div>
            </div>
        </FullPage>;
    }

    // Handle saving the company data
    const saveCompany = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        console.log(`id: ${userId}`);
        console.log(`editstate: `, editState);

        try {
            executeEditCompanyMutation({
                id: userId,
                name: editState.name ? editState.name : 'Unknown Name',
                type: editState.type,
                status: editState.status,
                url: editState.url ? editState.url : '',
                email: editState.email ? editState.email : '',
                phone: editState.phone ? editState.phone : '',
                tollFree: editState.tollFree ? editState.tollFree : '',
                fax: editState.fax ? editState.fax : '',
                line1: editState.line1 ? editState.line1 : '',
                line2: editState.line2 ? editState.line2 : '',
                city: editState.city ? editState.city : '',
                state: editState.state,
                zip: editState.zip,
                members: editState.members
            });
        } catch (err) {
            console.warn('error saving!', err);
    }

        // Redirect back to the company page
        navigate(`/companies/${userId}`);
    };

    return <FullPage>
        <section className="section" style={{ paddingTop: '1rem' }}>
            <div className="container">
                <section className="section tab-pane show active" id="info" style={{ paddingTop: '2rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 pl-0">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Name</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.name || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, name: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Type</span>
                                                </div>
                                                <select
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.type || 0}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLSelectElement>
                                                    ): void => {
                                                        setEditState({ ...editState, type: parseInt(e.target.value, 10) })
                                                    }}
                                                >
                                                    {data.types.map((type: { id: number; name: string; }) => <option key={`type-${type.id}`} value={type.id}>{type.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Status</span>
                                                </div>
                                                <select
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.status || 0}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLSelectElement>
                                                    ): void => {
                                                        setEditState({ ...editState, status: parseInt(e.target.value, 10) })
                                                    }}
                                                >
                                                    {data.statuses.map((status: { id: number; name: string; }) => <option key={`status-${status.id}`} value={status.id}>{status.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Members</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.members || 0}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, members: parseInt(e.target.value, 10) })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-success btn-block" onClick={saveCompany}>Save Changes</button>
                                <button className="btn btn-secondary btn-block" onClick={() => navigate(`/companies/${userId}`)}>Cancel</button>
                            </div>
                            <div className="col-md-4 pl-0 pr-0">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Website</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.url || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, url: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Email</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.email || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, email: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Phone</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.phone || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, phone: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Toll Free</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.tollFree || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, tollFree: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Fax</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.fax || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, fax: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 pr-0">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Line 1</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.line1 || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, line1: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Line 2</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.line2 || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, line2: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">City</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.city || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, city: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">State</span>
                                                </div>
                                                <select
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.state || 0}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLSelectElement>
                                                    ): void => {
                                                        setEditState({ ...editState, state: parseInt(e.target.value, 10) })
                                                    }}
                                                >
                                                    {data.states.map((state: { id: number; name: string; abbr: string; }) => <option key={`state-${state.id}`} value={state.id}>{state.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Zip</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    value={editState.zip || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEditState({ ...editState, zip: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    </FullPage>;
};

export default CompanyEditPage;
