import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';

// TODO: Import components

interface ISalesProposalPageProps extends RouteComponentProps<{}> { }

interface IState { }

class SalesProposalPage extends React.Component<ISalesProposalPageProps, IState> {
    // Define the constructor for the sales proposal page
    constructor (props: ISalesProposalPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the sales proposal container
    render () {
        return (
            <FullPage>
                Sales Proposal page
            </FullPage>
        );
    }
}

export default SalesProposalPage;
