import React from 'react';
import { render } from 'react-dom';
import AppRouter from './routers/AppRouter';

import {
    Provider,
    Client,
    dedupExchange,
    fetchExchange,
    // subscriptionExchange
} from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';

// import { SubscriptionClient } from 'subscriptions-transport-ws';
// import { WebSocketLink } from 'apollo-link-ws';
// import { subscriptionExchange } from 'urql';

// Import queries
// import { AccountQuery } from './queries/AccountQuery';

// Import mutations

// Set our graphql endpoint
const url = 'graph.lunarcow.com/v1/graphql';

// Define our cache exchange
const cache = cacheExchange({
    keys: {
        users_aggregate: () => null,
        user_requests_aggregate: () => null,
        projects_aggregate: () => null,
        sales_proposals_aggregate: () => null
    },
    /*
    updates: {
        Mutation: {
            addRequest: (result: any, args: any, cache: any, info: any) => {
                console.log('addRequest called');
                console.log('result', ' => ', result);
                console.log('args', ' => ', args);
                console.log('cache', ' => ', cache);
                console.log('info', ' => ', info);

                cache.updateQuery({ query: AccountQuery }, (data: any) => {
                    console.log('data before', ' => ', data);

                    data.users[0].requests.push(result.addRequest);

                    console.log('data after', ' => ', data);

                    return data;
                })
            }
        }
    }
    */
});

// Create the subscription listener
// const subscriptionClient = new SubscriptionClient(`wss://${url}`, { reconnect: true });

/*
const wsLink = new WebSocketLink({
    uri: `ws://${url}`,
    options: {
        reconnect: true
    }
});
*/

// Create the client
const client = new Client({
    url: `https://${url}`,
    // exchanges: [dedupExchange, cache, fetchExchange, subscriptionExchange],
    exchanges: [
        dedupExchange,
        cache,
        fetchExchange,
        /*
        subscriptionExchange({
            forwardSubscription (op: any) {
                return subscriptionClient.request(op);
            }
            // }: (op: any) => subscriptionClient.request(op)
        })
        */
        /*
        subscriptionExchange({
            forwardSubscription: (op: any) => subscriptionClient.request(op)
        })
        */
    ],
});

// Load the router, which loads the proper page(s)
render(
    <Provider value={client}>
        <AppRouter />
    </Provider>,
    document.getElementById('app')
);
