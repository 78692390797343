import React, { useState } from 'react';
import { useQuery } from 'urql';
import { Link } from '@reach/router';
import gql from 'graphql-tag';
import AlertMessage from '../../components/alerts/AlertMessage';
import { nanoid } from 'nanoid';
import ls from 'local-storage';

const q = gql`
    {
        users(order_by: {name: asc}, where: {type_id: {_eq: 1}, active: {_eq: true}}) {
            id
            name
            subtype_id
            ext
            img
        }
    }
`;

const employeeTypeAdmin = 1;
const employeeTypeDeveloper = 2;
// const employeeTypeProjectManager = 3;
// const employeeTypeSalesManager = 4;
// const employeeTypeDesigner = 5;
// const employeeTypeLeadGenerator = 6;
// const employeeTypeAdSales = 7;

const EmployeeContactList = () => {
    const [accSubType, setAccSubType] = useState(ls.get('accSubType') || 0);

    const [result, reexecuteQuery] = useQuery({
        query: q,
    });

    const { data, fetching, error } = result;
    if (fetching) return <p>Loading...</p>;
    if (error) return <AlertMessage type="danger" text={error.message} />;

    return <>
        <h6 className="divider">Farmhands</h6>

        {data.users.map((
            employee: {
                id: string;
                ext: number;
                img: string;
            }
        ) => (
            <React.Fragment key={nanoid()}>
                {(
                    accSubType === employeeTypeAdmin ||
                    accSubType === employeeTypeDeveloper
                )
                    ? (
                        <Link to={`/employees/${employee.id}`}>
                            <img
                                className="avatar avatar-xl mr-1 mb-1"
                                src={
                                    employee.img
                                        ? employee.img
                                        :
                                        'https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/default-usr.png'
                                }
                            />
                        </Link>
                    )
                    : (
                        <img
                            className="avatar avatar-xl mr-1 mb-1"
                            src={
                                employee.img
                                    ? employee.img
                                    :
                                    'https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/default-usr.png'
                            }
                        />
                    )
                }

                <span className="text-center badge badge-secondary">{employee.ext || 'x000'}</span>
            </React.Fragment>
        ))}

        {(
            accSubType === employeeTypeAdmin ||
            accSubType === employeeTypeDeveloper
        ) &&
            <Link to="/employees/create">
                <span
                    className="iconbox"
                    style={{
                        border: '2px #ddd dashed',
                        width: '64px',
                        height: '64px'
                    }}
                >
                    <i className="fa fa-plus" />
                </span>
            </Link>
        }
    </>;
};

export default EmployeeContactList;
