import React from 'react';
import { RouteComponentProps } from '@reach/router';
import ls from 'local-storage';
import FullPage from '../../layouts/FullPage';

import AdminDashboard from '../../components/dashboards/AdminDashboard';
import AdSalesDashboard from '../../components/dashboards/AdSalesDashboard';
import DesignerDashboard from '../../components/dashboards/DesignerDashboard';
import DeveloperDashboard from '../../components/dashboards/DeveloperDashboard';
import LeadGenDashboard from '../../components/dashboards/LeadGenDashboard';
import ProjectManagerDashboard from '../../components/dashboards/ProjectManagerDashboard';
import SalesManagerDashboard from '../../components/dashboards/SalesManagerDashboard';

import { useQuery } from 'urql';
import gql from 'graphql-tag';

interface IDashboardPageProps extends RouteComponentProps { }

const employeeTypeAdmin = 1;
const employeeTypeDeveloper = 2;
const employeeTypeProjectManager = 3;
const employeeTypeSalesManager = 4;
const employeeTypeDesigner = 5;
const employeeTypeLeadGenerator = 6;
const employeeTypeAdSales = 7;

const DashboardPage: React.FC<IDashboardPageProps> = () => {
    // Execute the graphql query for the user data we need
    const [result, reexecuteQuery] = useQuery({
        query: gql`
            query ($userId: String!) {
                users (where: {type_id: {_eq: 1}, id: {_eq: $userId }}) {
                    id
                    subtype_id
                }
            }
        `,
        variables: { userId: ls.get('uid') }
    });

    // Get the data, loading status, and error status from the query
    const { data, fetching, error } = result;

    // Check if the query has finished loading
    if (fetching) {
        // Return a loading page
        return <FullPage>
            <div className="row gap-y">
                <div className="col-12 col-lg-2">
                    loading
                </div>
            </div>
        </FullPage>;
    }

    // TODO: Handle displaying a loading error

    // Extract the user from the returned array
    const userData = data.users[0];

    // Check if the subtype is set in local storage
    if (!ls.get('accSubType')) {
        // Set the subtype in local storage
        ls.set('accSubType', userData.subtype_id);
    }

    // Display the appropriate dashboard based on the user level
    return <FullPage>
        <section className="section" style={{ paddingTop: '1rem' }}>
            <div className="container">
                {userData.subtype_id === employeeTypeAdmin && <AdminDashboard />}
                {userData.subtype_id === employeeTypeAdSales && <AdSalesDashboard />}
                {userData.subtype_id === employeeTypeDesigner && <DesignerDashboard />}
                {userData.subtype_id === employeeTypeDeveloper && <DeveloperDashboard />}
                {userData.subtype_id === employeeTypeLeadGenerator && <LeadGenDashboard />}
                {userData.subtype_id === employeeTypeProjectManager && <ProjectManagerDashboard />}
                {userData.subtype_id === employeeTypeSalesManager && <SalesManagerDashboard />}
            </div>
        </section>
    </FullPage>;
};

export default DashboardPage;
