import React from 'react';
import { nanoid } from 'nanoid';
import axios from 'axios';

interface ICompanyProps {
    proposals: any,
    refresh: () => void
}

const SalesProposalList: React.FC<ICompanyProps> = (props) => {
    const proposalCost = (proposal: {
        editorial_price: string;
        photography_price: string;
        imap_price: string;
        goguide_price: string;
        kiosk_price: string;
        ad_sales_price: string;
        inside_delivery_price: string;
        design_price: string;
        printing_price: string;
    }) => {
        // Store the cost of the proposal
        var cost = 0;

        cost += parseInt(proposal.editorial_price || "0", 10); // editorial
        cost += parseInt(proposal.photography_price || "0", 10); // photography
        cost += parseInt(proposal.imap_price || "0", 10); // imap
        cost += parseInt(proposal.goguide_price || "0", 10); // goguide
        cost += parseInt(proposal.kiosk_price || "0", 10); // kiosk
        cost += parseInt(proposal.ad_sales_price || "0", 10); // ad_sales
        cost += parseInt(proposal.inside_delivery_price || "0", 10); // inside_delivery
        cost += parseInt(proposal.design_price || "0", 10); // design
        cost += parseInt(proposal.printing_price || "0", 10); // printing

        // Return the cost, adding commas where necessary
        return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const viewProposal = (e: React.FormEvent, id: number) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // Get the URL of the PDF from the database and open it in a new tab
        window.open(`https://api.lunarcow.com/download/sales-proposal/${id}`, '_blank');
    };

    const removeProposal = (e: React.FormEvent, id: number) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            // Make the API delete request
            axios
                .delete('https://api.lunarcow.com/sales-proposals/' + id, { headers: { 'x-auth-token': '_test_token_' } })
                .then(() => {
                    // TODO: Remove the proposal from the list
                    props.refresh();

                    // Update the state of the sales proposals
                    /*
                    this.setState(prevState => ({
                        // Set the sales proposals to the previous state of the proposal list, adding
                        // back only the sales proposals that don't have an ID of the one we removed
                        proposals: prevState.proposals.filter(proposal => proposal.id !== id)
                    }));
                    */
                });

            /*
            // Delete the document by ID
            this.proposalRef
                .doc(id)
                .delete()
                .then(() => {
                    // Update the state of the sales proposals
                    this.setState(prevState => ({
                        // Set the sales proposals to the previous state of the proposal list, adding
                        // back only the sales proposals that don't have an ID of the one we removed
                        proposals: prevState.proposals.filter(proposal => proposal.id !== id)
                    }));
                })
                .catch(error => {
                });
                */
        } catch (error) {
            console.log('Error while deleting a sales proposal', ' => ', error);
        }
    };

    return <table className="table table-cart bg-white shadow-2">
        <tbody>
            {props.proposals.map((
                proposal: {
                    id: number;
                    business_name: string;
                    line1: string;
                    line2: string;
                    city: string;
                    state: string;
                    zip: string;
                    contact_name: string;
                    contact_title: string;
                    date: string;
                    filename: string;

                    editorial_price: string;
                    photography_price: string;
                    imap_price: string;
                    goguide_price: string;
                    kiosk_price: string;
                    ad_sales_price: string;
                    inside_delivery_price: string;
                    design_price: string;
                    printing_price: string;
                    /*
                    is_client: boolean;
                    lead_type: string;
                    name: string;
                    line1: string;
                    city: string;
                    state_str: string;
                    zip: string;
                    do_not_call: boolean;
                    status: {
                        name: string;
                    };
                    company_type: {
                        name: string;
                    }
                    */
                }) => (
                    <tr key={nanoid()}>
                        <td>
                            <h5><a href="" onClick={e => viewProposal(e, proposal.id)}>{proposal.business_name}</a></h5>
                            <p>&nbsp;</p>
                        </td>

                        <td>
                            <h5>{proposal.line1 || 'No Address'}</h5>
                            <p>{proposal.city || 'Unknown City'}, {proposal.state || 'N/A'} {proposal.zip}</p>
                        </td>

                        <td>
                            <h5>{proposal.contact_name || 'No Contact'}</h5>
                            <p>{proposal.contact_title || 'No Title'}</p>
                        </td>

                        <td>
                            <h5>${proposalCost({
                                    editorial_price: proposal.editorial_price,
                                    photography_price: proposal.photography_price,
                                    imap_price: proposal.imap_price,
                                    goguide_price: proposal.goguide_price,
                                    kiosk_price: proposal.kiosk_price,
                                    ad_sales_price: proposal.ad_sales_price,
                                    inside_delivery_price: proposal.inside_delivery_price,
                                    design_price: proposal.design_price,
                                    printing_price: proposal.printing_price
                                })}</h5>
                            <p>{/*moment(new Date(proposal.date)).format('L')*/}{proposal.date}</p>
                        </td>

                        <td>
                            <div className="text-center">
                                {proposal.filename &&
                                    <span
                                        className="iconbox iconbox-sq iconbox-sm text-info mr-1"
                                        onClick={e => viewProposal(e, proposal.id)}
                                        style={{ cursor: 'pointer' }}>
                                        <i className="fa fa-file-pdf-o" />
                                    </span>
                                }

                                {/* TODO: Add edit functionality */}
                                {/*
                                <Link
                                    to={`/sales-proposals/${proposal.id}/edit`}
                                    className="iconbox iconbox-sq iconbox-sm text-warning mr-1"
                                    style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-pencil" />
                                </Link>
                                */}

                                <span
                                    className="iconbox iconbox-sq iconbox-sm text-danger"
                                    onClick={e => removeProposal(e, proposal.id)}
                                    style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-trash-o" />
                                </span>
                            </div>
                        </td>
                    </tr>
            ))}
        </tbody>
    </table>;
};
/*
<tr key={nanoid()} style={{ borderLeft: `${company.is_client ? '3px solid #74b4ff' : ''}` }}>
    <td colSpan={6}>
        <h5>
            <Link to={`/sales-proposals/${company.id}`}>
                {company.name}
                </Link>
                </h5>
                <p>{company.company_type.name || 'Unknown Type'}</p>
            </td>

            <td colSpan={5}>
                <h5>{company.line1 || 'No Address'}</h5>
                <p>{company.city && company.state_str ? `${company.city}, ${company.state_str} ${company.zip}` : 'No Location Info'}</p>
            </td>

            <td colSpan={3}>
                <h5>{company.status ? company.status.name : 'Unknown Status'}</h5>
                <p>{company.do_not_call ? <strong className="text-danger">DO NOT CALL</strong> : 'No Disposition'}</p>
            </td>
        </tr>
        // TODO: Add last contact - result, date
    */

export default SalesProposalList;
