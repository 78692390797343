import React, { useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import { nanoid } from 'nanoid';
import AlertMessage from '../../components/alerts/AlertMessage';
import SalesProposalList from '../../components/tools/sales-proposals/SalesProposalList';

import { useQuery } from 'urql';
import gql from 'graphql-tag';

interface ISalesProposalListPageProps extends RouteComponentProps {}

const SalesProposalListPage: React.FC<ISalesProposalListPageProps> = () => {
    const [nameFilter, setNameFilter] = useState<string>('');
    const [yearFilter, setYearFilter] = useState<number>(0);

    const limit = 30;
    const [offset, setOffset] = useState<number>(0);

    const query = gql`
        query (
            $limit: Int!,
            $offset: Int!,
            ${nameFilter !== '' ? '$name: String!,': ''}
            ${yearFilter > 0 ? '$year: String!,' : ''}
        ) {
            sales_proposals (
                order_by: {
                    business_name: asc
                },
                where: {
                    ${nameFilter !== '' ? 'business_name: {_ilike: $name},' : ''}
                    ${yearFilter > 0 ? 'pub_year: {_eq: $year},' : ''}
                },
                limit: $limit,
                offset: $offset
            ) {
                id
                business_name
                line1
                line2
                city
                state
                zip
                contact_name
                contact_title
                date
                filename
                editorial_price
                photography_price
                imap_price
                goguide_price
                kiosk_price
                ad_sales_price
                inside_delivery_price
                design_price
                printing_price
            }

            sales_proposals_aggregate (
                where: {
                    ${nameFilter !== '' ? 'business_name: {_ilike: $name},' : ''}
                    ${yearFilter > 0 ? 'pub_year: {_eq: $year},' : ''}
                }
            ) {
                aggregate {
                    count
                }
            }
            

            years: sales_proposals (distinct_on: pub_year) {
                pub_year
            }
        }
    `;

    // GraphQL sales proposals and proposal-data query
    const [result, reexecuteQuery] = useQuery({
        query,
        variables: {
            name: `%${nameFilter}%`,
            year: yearFilter.toString(),
            offset,
            limit
        }
    });

    // Refetch the query and skip the cache
    const refresh = () => reexecuteQuery({ requestPolicy: 'network-only' });

    // Result of the query
    let { data, fetching, error } = result;

    // Clear the filters for the sales proposal list
    const clearFilters = (e: React.FormEvent) => { };



    /*
    if (fetching) {
        return <FullPage>
            <div className="row gap-y">
                <div className="col-12 col-lg-2">
                    &nbsp;
                </div>
            </div>
        </FullPage>;
    }
    */

    // if (error) return <AlertMessage type="danger" text={error.message} />;

    return <FullPage>
            <section className="section" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mx-auto pl-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <select
                                            className="form-control"
                                            value={yearFilter || 0}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLSelectElement>
                                            ): void => {
                                                setYearFilter(parseInt(e.target.value, 10));
                                                refresh();
                                            }
                                        }>
                                            <option value="0">Filter By Year</option>
                                            {(!fetching && data) && data.years.map((year: { pub_year: number; }) => (
                                                <option key={nanoid()} value={year.pub_year}>{year.pub_year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 mx-auto pl-0 pr-0">
                            {error && <AlertMessage type="danger" text={error.message} />}

                            <section className="section" style={{
                                paddingTop: '0rem',
                                paddingBottom: '5rem'
                            }}>
                                <div className="container">
                                    <nav
                                        className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white"
                                        style={{ top: 0 }}
                                    >
                                        <div className="container d-stick-none pl-0 pr-0">
                                            <div className="navbar-left" style={{ width: '60%' }}>
                                                <input
                                                    className="form-control"
                                                    placeholder="Search By Name"
                                                    value={nameFilter || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setNameFilter(e.target.value);
                                                        refresh();
                                                    }
                                                }/>

                                                <Link to="/sales-proposals/create" className="btn btn-primary ml-2">
                                                    Create
                                                </Link>
                                            </div>

                                            <section className="navbar-mobile">
                                                <nav className="nav nav-navbar ml-auto">
                                                    <span className="nav-link pr-0">
                                                        {(!fetching && data)
                                                            ? data.sales_proposals_aggregate.aggregate.count
                                                            : 0
                                                        } Sales Proposals
                                                    </span>
                                                </nav>
                                            </section>
                                        </div>
                                    </nav>
                                </div>
                            </section>

                            {(fetching && !data) ? <p>Loading...</p> : <SalesProposalList proposals={data.sales_proposals} refresh={refresh}/>}

                            {(!fetching && data && data.sales_proposals_aggregate.aggregate.count > 30) &&
                                <nav className="flexbox mb-2">
                                    <a
                                        className={`btn btn-primary ${(offset - limit)  + 1 <= 0 ? 'disabled' : ''}`}
                                        href="#"onClick={() : void => {
                                            setOffset(offset - limit);
                                            refresh();
                                        }}
                                    >
                                        <i className="fa fa-angle-left mr-1"></i> Previous
                                    </a>

                                    <a
                                        className={`btn btn-primary ${(offset + limit) >= data.sales_proposals_aggregate.aggregate.count ? 'disabled' : ''}`}
                                        href="#"onClick={() : void => {
                                            setOffset(offset + limit);
                                            refresh();
                                        }}
                                    >
                                        Next <i className="fa fa-angle-right ml-1"></i>
                                    </a>
                                </nav>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </FullPage>;
};

export default SalesProposalListPage;
