import React from 'react';
import { RouteComponentProps } from '@reach/router';
import gql from 'graphql-tag';
import { useQuery } from 'urql';

import FullPage from '../../layouts/FullPage';
import EmployeeRequestEditForm from '../../components/employees/EmployeeRequestEditForm';

const q = gql`
    query GetUserRequest ($requestId: Int!) {
        user_requests (where: {id: {_eq: $requestId}}) {
            id
            type_id
            start
            end
            half_day
        }

        user_request_types (order_by: {name: asc}) {
            id
            name
        }
    }
`;

// TODO: Import components

interface MatchParams { id: string; reqid: number; }
interface MatchProps extends RouteComponentProps<MatchParams> { }

const EmployeeRequestEditPage: React.FC<MatchProps> = (match: MatchProps) => {
    // console.log(`match id type: ${typeof match.id}`);
    // console.log(`match id: ${match.id}`);

    const requestId = match.reqid;

    // console.log(`req type: ${typeof requestId}`);
    // console.log(`req id: ${requestId}`);

    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: { requestId }
    });

    const { data, fetching, error } = result;

    /*
    if (fetching) {
        return (
            <FullPage>&nbsp;</FullPage>
        );
    }
    */

    // console.log('data', ' => ', data);

    // Get the company data out of the response
    const employeeRequest = data ? data.user_requests[0] : {};
    const types = data ? data.user_request_types : [];

    // console.log('result', ' => ', result);
    // console.log('employeeRequest', ' => ', employeeRequest);
    // console.log('types', ' => ', types);

    return (
        <FullPage>
            
            <section className="section" style={{ paddingTop: '1rem' }}>
                <div className="container">
                    <section className="section tab-pane show active" id="info" style={{ paddingTop: '2rem' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 mx-auto pl-0">
                                    <EmployeeRequestEditForm employeeRequest={employeeRequest} types={types} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </FullPage>
    );
}


export default EmployeeRequestEditPage;
