import React from 'react';
import { useQuery } from 'urql';
import gql from 'graphql-tag';
import AlertMessage from '../../components/alerts/AlertMessage';

const q = gql`
    {
        concepts(order_by: {created_at: desc}) {
            id
            img
            public
            thumb
            concept_size {
                name
                id
            }
            concept_type {
                name
                parent_id
                id
            }
        }
    }
`;

const ConceptList = () => {
    const [result, reexecuteQuery] = useQuery({
        query: q,
    });

    const { data, fetching, error } = result;
    if (fetching) return <p>Loading...</p>;
    if (error) return <AlertMessage type="danger" text={error.message} />;

    return <div className="row gap-y">
        <div className="col-12 col-lg-2">
            {data.concepts.map((
                concept: {
                    id: number;
                    thumb: string;
                    public: boolean;
                    project: {
                        name: string;
                    }
                    concept_size: {
                        name: string;
                    }
                    concept_type: {
                        name: string;
                    }
                }
            ) => (
                <div className="card d-block">
                    {/*
                    <div className="card-img-top">
                        <img src={concept.thumb} alt="..." />
                        <div className="badges">
                            <a className="badge badge-warning" href="#">{concept.concept_size.name}</a>
                            <a className="badge badge-info" href="#">{concept.concept_type.name}</a>
                        </div>
                    </div>
                    */}

                    <img className="card-img-top" src={concept.thumb} alt="..." />
                    <div className="text-center" style={{ backgroundColor: 'none' }}>
                        {/* <h5 className="card-title">{concept.project}</h5> */}
                        <p>
                            <a className="badge badge-secondary" href="#">{concept.concept_type.name}</a><br />
                            <a className="badge badge-secondary" href="#">{concept.concept_size.name}</a>
                        </p>
                        {/* <a className="fs-12 fw-600" href="#">more <i className="fa fa-angle-right pl-1"></i></a> */}
                    </div>
                </div>
            ))}
        </div>
    </div>;
};

export default ConceptList;
