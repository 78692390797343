import React, { Suspense, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import EmployeeContactList from '../common/EmployeeContactList';

interface ISalesManagerDashboardProps extends RouteComponentProps {}

const SalesManagerDashboard: React.FC<ISalesManagerDashboardProps> = () => {
    const [salesIncentive, setSalesIncentive] = useState('');
    const [currentSalesIncentive, setCurrentSalesIncentive] = useState('');

    /*
    const setSalesIncentive = () => {
        // TODO: Set the sales incentive banner
        console.warn('Not yet implemented');
//
        // const { salesIncentive } = this.state;
//
        // firestore
            // .collection('globals')
            // .doc('salesincentive')
            // .update({ desc: salesIncentive, seenBy: [] })
            // .then(() => {
                // this.setState({ currentSalesIncentive: salesIncentive });
            // });
    };
    */

    const clearSalesIncentive = () => {
        // TODO: Clear the sales incentive banner
        console.warn('Not yet implemented');

        // firestore
            // .collection('globals')
            // .doc('salesincentive')
            // .update({ desc: '', seenBy: [] })
            // .then(() => {
                // this.setState({
                    // salesIncentive: '',
                    // currentSalesIncentive: ''
                // });
            // });
    };

    return <div className="row">
        <div className="col-md-8 mx-auto">
            <h6 className="divider">TBD</h6>
            Sales Manager Dashboard
        </div>

        <div className="col-md-4 mx-auto">
            <h6 className="divider">Sales Rep Incentive</h6>

            <textarea
                className="form-control"
                onChange={e => setSalesIncentive(e.target.value)}
                value={salesIncentive}
            />

            <button
                className="btn btn-secondary"
                onClick={clearSalesIncentive}
            >
                Clear Incentive
            </button>

            <button
                className="btn btn-primary"
                onClick={e => e.preventDefault()}
                disabled={currentSalesIncentive == salesIncentive}
            >
                Set Incentive
            </button>

            <Suspense fallback={<div>Loading...</div>}>
                <EmployeeContactList />
            </Suspense>
        </div>
    </div>;
}

export default SalesManagerDashboard;
