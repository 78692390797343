import React, { useState} from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import ProjectList from '../../components/projects/ProjectList';

import { useQuery } from 'urql';
import gql from 'graphql-tag';
import AlertMessage from '../../components/alerts/AlertMessage';
import { nanoid } from 'nanoid';

interface IProjectListPageProps extends RouteComponentProps {}

// Clear the filters for the project list
const clearFilters = (e: React.FormEvent) => { };

const ProjectListPage: React.FC<IProjectListPageProps> = () => {
    const [nameFilter, setNameFilter] = useState<string>('');
    const [typeFilter, setTypeFilter] = useState<number>(0);
    const [yearFilter, setYearFilter] = useState<number>(0);

    const limit = 30;
    const [offset, setOffset] = useState<number>(0);

    const query = gql`
        query (
            $limit: Int!,
            $offset: Int!,
            ${nameFilter !== '' ? '$name: String!,': ''}
            ${typeFilter > 0 ? '$type_id: Int!,' : ''}
            ${yearFilter > 0 ? '$year: Int!,' : ''}
        ) {
            projects (
                order_by: {
                    name: asc
                },
                where: {
                    ${nameFilter !== '' ? 'name: {_ilike: $name},' : ''}
                    ${typeFilter > 0 ? 'type_id: {_eq: $type_id},' : ''}
                    ${yearFilter > 0 ? 'year: {_eq: $year},' : ''}
                },
                limit: $limit,
                offset: $offset
            ) {
                id
                name
                year
                start
                end
                completed
                is_delayed

                type {
                    id
                    name
                }

                client {
                    id
                    name

                    company_type {
                        id
                        name
                    }
                }

                milestone {
                    id
                    name
                    step
                }
            }

            projects_aggregate (
                where: {
                    ${nameFilter !== '' ? 'name: {_ilike: $name},' : ''}
                    ${typeFilter > 0 ? 'type_id: {_eq: $type_id},' : ''}
                    ${yearFilter > 0 ? 'year: {_eq: $year},' : ''}
                }
            ) {
                aggregate {
                    count
                }
            }

            types: project_types (order_by: {name: asc}) {
                id
                name
            }

            years: projects(distinct_on: year) {
                year
            }
        }
    `;

    // GraphQL project and project-data query
    const [result, reexecuteQuery] = useQuery({
        query,
        variables: {
            name: `%${nameFilter}%`,
            type_id: typeFilter,
            year: yearFilter,
            offset,
            limit
        }
    });

    // Refetch the query and skip the cache
    const refresh = () => reexecuteQuery({ requestPolicy: 'network-only' });

    // Result of the query
    let { data, fetching, error } = result;

    /*
    if (fetching) {
        return <FullPage>
        <div className="row gap-y">
            <div className="col-12 col-lg-2">
                &nbsp;
            </div>
        </div>
    </FullPage>;
    }
    */

    // if (error) return <AlertMessage type="danger" text={error.message} />;

    return <FullPage>
            <section className="section" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mx-auto pl-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <select
                                            className="form-control"
                                            value={typeFilter || 0}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLSelectElement>
                                            ): void => {
                                                setTypeFilter(parseInt(e.target.value, 10));
                                                refresh();
                                            }
                                        }>
                                            <option value="0">Filter By Type</option>
                                            {(!fetching && data) && data.types.map((type: { id: number; name: string; }) => (
                                                <option key={nanoid()} value={type.id}>{type.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <select
                                            className="form-control"
                                            value={yearFilter || 0}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLSelectElement>
                                            ): void => {
                                                setYearFilter(parseInt(e.target.value, 10));
                                                refresh();
                                            }
                                        }>
                                            <option value="0">Filter By Year</option>
                                            {(!fetching && data) && data.years.map((year: { year: number; }) => (
                                                <option key={nanoid()} value={year.year}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 mx-auto pl-0 pr-0">
                            {error && <AlertMessage type="danger" text={error.message} />}

                            <section className="section" style={{
                                paddingTop: '0rem',
                                paddingBottom: '5rem'
                            }}>
                                <div className="container">
                                    <nav
                                        className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white"
                                        style={{ top: 0 }}
                                    >
                                        <div className="container d-stick-none pl-0 pr-0">
                                            <div className="navbar-left" style={{ width: '60%' }}>
                                                <input
                                                    className="form-control"
                                                    placeholder="Search By Name"
                                                    value={nameFilter || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setNameFilter(e.target.value);
                                                        refresh();
                                                    }
                                                }/>
                                            </div>

                                            <section className="navbar-mobile">
                                                <nav className="nav nav-navbar ml-auto">
                                                    <span className="nav-link pr-0">
                                                        {(!fetching && data)
                                                            ? data.projects_aggregate.aggregate.count
                                                            : 0
                                                        } Projects
                                                    </span>
                                                </nav>
                                            </section>
                                        </div>
                                    </nav>
                                </div>
                            </section>

                            {(fetching && !data) ? <p>Loading...</p> : <ProjectList projects={data.projects} />}

                            {(!fetching && data && data.projects_aggregate.aggregate.count > 30) &&
                                <nav className="flexbox mb-2">
                                    <a
                                        className={`btn btn-primary ${(offset - limit)  + 1 <= 0 ? 'disabled' : ''}`}
                                        href="#"onClick={() : void => {
                                            setOffset(offset - limit);
                                            refresh();
                                        }}
                                    >
                                        <i className="fa fa-angle-left mr-1"></i> Previous
                                    </a>

                                    <a
                                        className={`btn btn-primary ${(offset + limit) >= data.projects_aggregate.aggregate.count ? 'disabled' : ''}`}
                                        href="#"onClick={() : void => {
                                            setOffset(offset + limit);
                                            refresh();
                                        }}
                                    >
                                        Next <i className="fa fa-angle-right ml-1"></i>
                                    </a>
                                </nav>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </FullPage>;
};

export default ProjectListPage;
