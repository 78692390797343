import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import ls from 'local-storage';
import FullPage from '../../layouts/FullPage';
import CompanyList from '../../components/company/CompanyList';
import AddCompanyForm from '../../components/company/AddCompanyForm';

import { useQuery } from 'urql';
import gql from 'graphql-tag';
import AlertMessage from '../../components/alerts/AlertMessage';
import { nanoid } from 'nanoid';

interface ICompanyListPageProps extends RouteComponentProps {}

// Clear the filters for the company list
const clearFilters = (e: React.FormEvent) => { };

const CompanyListPage: React.FC<ICompanyListPageProps> = () => {
    // Filter states
    const [typeFilter, setTypeFilter] = useState<number>(ls.get('companyTypeFilter') || 0);
    const [statusFilter, setStatusFilter] = useState<number>(ls.get('companyStatusFilter') || 0);
    const [dispositionFilter, setDispositionFilter] = useState<number>(0);
    const [stateFilter, setStateFilter] = useState<string>(ls.get('companyStateFilter') || ''); // TODO: Update to number
    const [timezoneFilter, setTimezoneFilter] = useState<number>(0);
    const [repFilter, setRepFilter] = useState<string>('');
    const [nameFilter, setNameFilter] = useState<string>(ls.get('companyNameFilter') || '');

    const limit = 30;
    const [offset, setOffset] = useState<number>(ls.get('companyListOffset') || 0);

    // Pagination variables
    // const [] = useState<number>(0);

    const query = gql`
        query (
            $limit: Int!,
            $offset: Int!,
            ${nameFilter !== '' ? '$name: String!,': ''}
            ${typeFilter > 0 ? '$type_id: Int!,' : ''}
            ${statusFilter > 0 ? '$status_id: Int!,' : ''}
            ${stateFilter !== '' ? '$state_str: String!,': ''}
            ${dispositionFilter > 0 ? '$do_not_call: Int!,' : ''}
        ) {
            users(
                order_by: {
                    name: asc
                },
                where: {
                    type_id: {_eq: 2},
                    ${nameFilter !== '' ? 'name: {_ilike: $name},' : ''}
                    ${typeFilter > 0 ? 'subtype_id: {_eq: $type_id},' : ''}
                    ${statusFilter > 0 ? 'status_id: {_eq: $status_id},' : ''}
                    ${stateFilter !== '' ? 'state_str: {_eq: $state_str},': ''}
                    ${dispositionFilter > 0 ? 'do_not_call: {_eq: $do_not_call},' : ''}
                },
                limit: $limit,
                offset: $offset
            ) {
                id
                name
                do_not_call
                is_client
                lead_type
                line1
                line2
                city
                state_str
                zip
                members

                company_type {
                    id
                    name
                }

                status {
                    id
                    name
                }
            }

            company_types(order_by: {name: asc}) {
                id
                name
            }

            company_statuses(order_by: {name: asc}) {
                id
                name
            }

            states(order_by: {name: asc}) {
                id
                name
                abbr
            }

            users_aggregate(where: {
                type_id: {_eq: 2},
                ${nameFilter !== '' ? 'name: {_ilike: $name},' : ''}
                ${typeFilter > 0 ? 'subtype_id: {_eq: $type_id},' : ''}
                ${statusFilter > 0 ? 'status_id: {_eq: $status_id},' : ''}
                ${stateFilter !== '' ? 'state_str: {_eq: $state_str},': ''}
                ${dispositionFilter > 0 ? 'do_not_call: {_eq: $do_not_call},' : ''}
            }) {
                aggregate {
                    count
                }
            }
        }
    `;

    // GraphQL company and company-data query
    const [result, reexecuteQuery] = useQuery({
        query,
        variables: {
            name: `%${nameFilter}%`,
            type_id: typeFilter,
            status_id: statusFilter,
            state_str: stateFilter,
            do_not_call: dispositionFilter ? true : false,
            offset,
            limit
        }
    });

    useEffect(() => { ls.set('companyNameFilter', nameFilter)}, [nameFilter]);
    useEffect(() => { ls.set('companyTypeFilter', typeFilter)}, [typeFilter]);
    useEffect(() => { ls.set('companyStatusFilter', statusFilter)}, [statusFilter]);
    useEffect(() => { ls.set('companyStateFilter', stateFilter)}, [stateFilter]);
    useEffect(() => { ls.set('companyListOffset', offset)}, [offset]);

    // Refetch the query and skip the cache
    const refresh = () => reexecuteQuery({ requestPolicy: 'cache-and-network' });

    // Result of the query
    let { data, fetching, error } = result;

    const currentPage = Math.ceil((offset - 1) / limit) + 1;

    return <FullPage>
            <section className="section" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mx-auto pl-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <select
                                            className="form-control"
                                            value={typeFilter || 0}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLSelectElement>
                                            ): void => {
                                                setTypeFilter(parseInt(e.target.value, 10));
                                                setOffset(0);
                                                refresh();
                                            }
                                        }>
                                            <option value="0">Filter By Type</option>
                                            {(!fetching && data) && data.company_types.map((type: { id: number; name: string; }) => (
                                                <option key={nanoid()} value={type.id}>{type.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <select
                                            className="form-control"
                                            value={statusFilter || 0}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLSelectElement>
                                            ): void => {
                                                setStatusFilter(parseInt(e.target.value, 10));
                                                setOffset(0);
                                                refresh();
                                            }
                                        }>
                                            <option value="0">Filter By Status</option>
                                            {(!fetching && data) && data.company_statuses.map((status: { id: number; name: string; }) => (
                                                <option key={nanoid()} value={status.id}>{status.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <select
                                            className="form-control"
                                            value={stateFilter || 0}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLSelectElement>
                                            ): void => {
                                                setStateFilter(e.target.value);
                                                setOffset(0);
                                                refresh();
                                            }
                                        }>
                                            <option value="">Filter By State</option>
                                            {(!fetching && data) && data.states.map((state: { id: number; name: string; abbr: string; }) => (
                                                <option key={nanoid()} value={state.abbr}>{state.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* TODO: Disposition, Timezone, Rep Filters */}
                                {/* TODO: Clear filters button */}
                            </div>

                            <h6 className="divider">Add Company</h6>

                            <AddCompanyForm />
                        </div>

                        <div className="col-md-8 mx-auto pl-0 pr-0">
                            {error && <AlertMessage type="danger" text={error.message} />}

                            <section className="section" style={{
                                paddingTop: '0rem',
                                paddingBottom: '5rem'
                            }}>
                                <div className="container">
                                    <nav
                                        className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white"
                                        style={{ top: 0 }}
                                    >
                                        <div className="container d-stick-none pl-0 pr-0">
                                            <div className="navbar-left" style={{ width: '60%' }}>
                                                <input
                                                    className="form-control"
                                                    placeholder="Search By Name"
                                                    value={nameFilter || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setNameFilter(e.target.value);
                                                        setOffset(0);
                                                        refresh();
                                                    }
                                                }/>
                                            </div>

                                            <section className="navbar-mobile">
                                                <nav className="nav nav-navbar ml-auto">
                                                    <span className="nav-link pr-0">
                                                        {(!fetching && data)
                                                            ? data.users_aggregate.aggregate.count
                                                            : 0
                                                        } Companies
                                                    </span>
                                                </nav>
                                            </section>
                                        </div>
                                    </nav>
                                </div>
                            </section>

                            {fetching ? <p>Loading...</p> : <CompanyList companies={data.users} />}

                            {(!fetching && data && data.users_aggregate.aggregate.count > 30) &&
                                <nav className="flexbox mb-2">
                                    <a
                                        className={`btn btn-primary ${(offset - limit)  + 1 <= 0 ? 'disabled' : ''}`}
                                        href="#"onClick={() : void => {
                                            setOffset(0);
                                            refresh();
                                        }}
                                    >
                                        <i className="fa fa-angle-left mr-1"></i> First
                                    </a>

                                    <a
                                        className={`btn btn-primary ${(offset - limit)  + 1 <= 0 ? 'disabled' : ''}`}
                                        href="#"onClick={() : void => {
                                            setOffset(offset - limit);
                                            refresh();
                                        }}
                                    >
                                        <i className="fa fa-angle-left mr-1"></i> Previous
                                    </a>

                                    <span className="mx-auto"><strong>Page {currentPage}</strong></span>

                                    <a
                                        className={`btn btn-primary ${(offset + limit) >= data.users_aggregate.aggregate.count ? 'disabled' : ''}`}
                                        href="#"onClick={() : void => {
                                            setOffset(offset + limit);

                                            refresh();
                                        }}
                                    >
                                        Next <i className="fa fa-angle-right ml-1"></i>
                                    </a>

                                    <a
                                        className={`btn btn-primary ${(offset + limit) >= data.users_aggregate.aggregate.count ? 'disabled' : ''}`}
                                        href="#"onClick={() : void => {
                                            setOffset(data.users_aggregate.aggregate.count - 1);
                                            refresh();
                                        }}
                                    >
                                        Last ({Math.ceil(data.users_aggregate.aggregate.count / limit) + 1}) <i className="fa fa-angle-right ml-1"></i>
                                    </a>
                                </nav>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </FullPage>;
};

export default CompanyListPage;
