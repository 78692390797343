import React, { useState, useEffect } from 'react';
import ls from 'local-storage';

const TimeslipTimer = () => {
    const [time, setTime] = useState(''); // Date.now()

    const currDiff = () => {
        const loggedInAt = new Date(ls.get('loggedInAt')).getTime(); //moment(ls.get('loggedInAt')).format('YYYY-MM-DD HH:mm:ss');
        const now = new Date().getTime();
        let diff = now - loggedInAt;

        diff /= 1000; // Stripe the ms

        const secs = Math.round(diff % 60); // Get the seconds from the date
        diff = Math.floor(diff / 60); // Remove the seconds from the date

        const mins = Math.round(diff % 60); // Get the minutes from the date
        diff /= Math.floor(diff / 60); // Remove the minutes from the date

        const hours = Math.floor(diff % 24); // Get the hours from the date
        diff /= Math.floor(diff / 24); // Remove the hours from the date

        return `${hours ? hours.toString().padStart(2, '0') : '00'}:${mins ? mins.toString().padStart(2, '0') : '00'}:${secs ? secs.toString().padStart(2, '0') : '00'}`;
    };

    useEffect(() => {
        const interval = setInterval(() => setTime(currDiff), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <a
            href="#"
            className="btn btn-sm btn-light ml-lg-5 mr-2"
        >
            {time || '00:00:00'}
        </a>
    );
};

export default TimeslipTimer;
