import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { useMutation } from 'urql';
import gql from 'graphql-tag';

interface IProps {
    companyId: string;
};

const AddContactForm: React.FC<IProps> = ({ companyId }) => {
    const [name, setName] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [added, setAdded] = useState<boolean>(false);

    const [addContactResult, executeContactMutation] = useMutation(gql`mutation AddContact($id: String!, $customer_old_id: String!, $name: String!, $title: String!, $email: String!, $phone: String!, $mobile: String!) {
        insert_contacts (objects: { id: $id, customer_old_id: $customer_old_id, name: $name, title: $title, email: $email, phone: $phone, mobile: $mobile }) {
            returning {
                id
                name
                title
                email
                phone
                mobile
            }
        }
    }`);

    const addContact = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            executeContactMutation({
                // id: firebaseUid,
                id: nanoid(),
                customer_old_id: companyId,
                name: name,
                title: title,
                email: email,
                phone: phone,
                mobile: mobile
            });

            // Clear the form inputs
            setName('');
            setTitle('');
            setEmail('');
            setPhone('');
            setMobile('');

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setAdded(true);
        } catch (err) {
            console.warn(err);
        }
    };

    return <form>
        <div className="border rounded shadow-2 p-4 mb-4 bg-white">
            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setName(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Title"
                        value={title}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setTitle(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setEmail(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Office Phone"
                        value={phone}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setPhone(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Mobile Phone"
                        value={mobile}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setMobile(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row">
                <div className="col lh-5 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={addContact}
                    >
                        Add Contact
                    </button>
                </div>
            </div>

            {added && <div className="alert alert-success mt-3" role="alert">Success!</div>}
        </div>
    </form>;
};

export default AddContactForm;
