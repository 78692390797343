import React, { useState, useEffect } from 'react';
import { useMutation } from 'urql';
import gql from 'graphql-tag';

interface IProps {
    contact: {
        id: string;
        name: string;
        title: string;
        email: string;
        phone: string;
        mobile: string;
    }
};

const EditContact: React.FC<IProps> = ({ contact }) => {
    const contactId = contact.id;

    const [editState, setEditState] = useState({
        name: '',
        title: '',
        email: '',
        phone: '',
        mobile: '',
        saved: false
    });

    const [editContactResult, executeContactMutation] = useMutation(gql`mutation EditContact (
        $id: String!,
        $name: String!,
        $title: String!,
        $email: String!,
        $phone: String!,
        $mobile: String!
    ) {
        update_contacts_by_pk(
            pk_columns: {id: $id},
            _set: {
                name: $name,
                title: $title,
                email: $email,
                phone: $phone,
                mobile: $mobile
            }
        ) {
            id
        }
    }`);

    useEffect(() => {
        if (contact && 'name' in contact) {
            setData(contact);
        }
    }, [contact]);

    const setData = (contact: any) => {
        setEditState({
            name: contact.name,
            title: contact.title,
            email: contact.email,
            phone: contact.phone,
            mobile: contact.mobile,
            saved: false
        });
    };

    const editContact = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        console.log('id', contactId);
        console.log('editState', editState);

        try {
            executeContactMutation({
                id: contactId,
                name: editState.name ? editState.name : 'Unknown Name',
                title: editState.title ? editState.title : '',
                email: editState.email ? editState.email : '',
                phone: editState.phone ? editState.phone : '',
                mobile: editState.mobile ? editState.mobile : ''
            });

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setEditState({ ...editState, saved: true });
        } catch (err) {
            console.warn(err);
        }
    };

    return <form>
        <div className="border rounded shadow-2 p-4 mb-4 bg-white">
            <div className="row pb-4">
                <div className="col lh-5">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Name</span>
                        </div>
                        <input
                            className="form-control"
                            placeholder=""
                            value={editState.name || ''}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => {
                                setEditState({ ...editState, name: e.target.value })
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Title</span>
                        </div>
                        <input
                            className="form-control"
                            placeholder=""
                            value={editState.title || ''}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => {
                                setEditState({ ...editState, title: e.target.value })
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Email</span>
                        </div>
                        <input
                            className="form-control"
                            placeholder=""
                            value={editState.email || ''}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => {
                                setEditState({ ...editState, email: e.target.value })
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Phone</span>
                        </div>
                        <input
                            className="form-control"
                            placeholder=""
                            value={editState.phone || ''}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => {
                                setEditState({ ...editState, phone: e.target.value })
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Mobile</span>
                        </div>
                        <input
                            className="form-control"
                            placeholder=""
                            value={editState.mobile || ''}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => {
                                setEditState({ ...editState, mobile: e.target.value })
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col lh-5 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={editContact}
                    >
                        Save Contact
                    </button>
                </div>
            </div>

            {editState.saved && <div className="alert alert-success mt-3" role="alert">Success!</div>}
        </div>
    </form>;
};

export default EditContact;
