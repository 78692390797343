import React, { useState } from 'react';

import { useMutation } from 'urql';
import gql from 'graphql-tag';
// import moment from 'moment-timezone';
import { nanoid } from 'nanoid';

interface IProps {
    contactId: any,
    eventTypes: any
}

const AddMarketingListEvent: React.FC<IProps> = (props) => {
    const contactId = props.contactId;

    const [eventTypeId, setEventTypeId] = useState<string>('');
    const [eventTime, setEventTime] = useState<string>('');
    const [eventDesc, setEventDesc] = useState<string>('');
    const [eventAdded, setEventAdded] = useState<boolean>(false);

    const [addEventResult, executeEventMutation] = useMutation(gql`mutation AddEvent ($id: String!, $type_id: Int!, $user_id: String!, $time: String!, $desc: String!) {
        insert_ml_events (objects: { id: $id, type_id: $type_id, user_id: $user_id, time: $time, desc: $desc }) {
            returning {
                id
                type_id
                user_id
                time
                desc
            }
        }
    }`);

    const addEvent = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            executeEventMutation({
                id: nanoid(),
                type_id: eventTypeId,
                // employee_id: ls.get('uid'),
                user_id: contactId,
                // client_id: company.id,
                time: eventTime,
                desc: eventDesc
            });

            // NOTE: Format the time as such, if possible: YYYY-MM-DD HH:MM:SS

            // Clear the form inputs
            setEventTypeId('');
            setEventTime('');
            setEventDesc('');

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setEventAdded(true);
        } catch (err) {
            console.warn(err);
        }
    };

    return (
        <React.Fragment>
            <p>
                <select
                    className="form-control"
                    value={eventTypeId || ''}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setEventTypeId(e.target.value)}
                >
                    <option value="">-- Select Type --</option>
                    {props.eventTypes.map((
                        event_type: {
                            id: number;
                            name: string;
                        }
                    ) => (
                        <option key={nanoid()} value={event_type.id}>{event_type.name}</option>
                    ))}
                </select>
            </p>
            <p>
                <input
                    className="form-control"
                    placeholder="Event Date / Time"
                    value={eventTime || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setEventTime(e.target.value)}
                />
            </p>
            <p>
                <textarea
                    className="form-control"
                    value={eventDesc || ''}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => setEventDesc(e.target.value)}
                />
            </p>
            <p>
                <button
                    className="btn btn-success btn-block"
                    onClick={addEvent}
                >
                    Create Event
                </button>
            </p>

            {eventAdded && <div className="alert alert-success" role="alert">Success!</div>}
        </React.Fragment>
    )

    {/*
    return <table className="table table-cart bg-white shadow-2">
        <tbody>
            {props.projects.map((
                project: {
                    id: string;
                    name: string;
                    year: number;
                    start: Date;
                    end: Date;
                    completed: boolean;
                    is_delayed: boolean;

                    type: {
                        id: number;
                        name: string;
                    }

                    client: {
                        id: number;
                        name: string;

                        company_type: {
                            id: number;
                            name: string;
                        }
                    }

                    milestone: {
                        id: number;
                        name: string;
                        step: number;
                    }
                }
            ) => (
                <tr key={nanoid()}>
                    <td>
                        <h5>
                            <Link to={`/projects/${project.id}`}>{project.name}</Link>
                        </h5>
                        <p>Project Type TBD</p>
                    </td>

                    <td>
                        <h5>{project.client.name || 'Unknown Company'}</h5>
                        <p>{project.client.company_type.name || <span>&nbsp;</span>}</p>
                    </td>

                    <td>
                        <h5>Start: {project.start || <span>Unknown</span>}</h5>
                        <p>End: {project.end || <span>Unknown</span>}</p>
                    </td>

                    <td>
                        {project.milestone
                            ? <span className={`badge badge-${project.is_delayed ? 'warning' : 'primary'}`}>M{project.milestone.step}: {project.milestone.name}</span>
                            : <>&nbsp;</>
                        }

                        {project.completed && <span className="badge badge-success">Completed</span>}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>;
    */}
};

export default AddMarketingListEvent;
