import React, { useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import InputMask from 'react-input-mask';
import axios from 'axios';

// import { useQuery } from 'urql';
// import gql from 'graphql-tag';

interface ICreateSalesProposalPageProps extends RouteComponentProps {}

const CreateSalesProposalPage: React.FC<ICreateSalesProposalPageProps> = () => {
    const [typeOfPub, setTypeOfPub] = useState<string>('Visitor Guide');
    const [businessName, setBusinessName] = useState<string>('');
    const [pubName, setPubName] = useState<string>('');
    const [pubYear, setPubYear] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [contactName, setContactName] = useState<string>('');
    const [contactTitle, setContactTitle] = useState<string>('');

    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');

    const [line1, setLine1] = useState<string>('');
    const [line2, setLine2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [zip, setZip] = useState<string>('');

    const [size, setSize] = useState<string>('');
    const [flatSize, setFlatSize] = useState<string>('');
    const [finishedSize, setFinishedSize] = useState<string>('');
    const [quantity, setQuantity] = useState<string>('');
    const [numBodyPages, setNumBodyPages] = useState<string>('');
    const [numCovers, setNumCovers] = useState<string>('');
    const [bodyWeight, setBodyWeight] = useState<string>('');
    const [coverWeight, setCoverWeight] = useState<string>('');
    const [paperGradeBody, setPaperGradeBody] = useState<string>('Grade 3');
    const [paperGradeCover, setPaperGradeCover] = useState<string>('Grade 3');
    const [coverPaperType, setCoverPaperType] = useState<string>('');
    const [coverCoating, setCoverCoating] = useState<string>('');
    const [binding, setBinding] = useState<string>('Perfect Binding');
    const [bodyPaperType, setBodyPaperType] = useState<string>('');
    const [packagingWeight, setPackagingWeight] = useState<string>('');
    const [proofCost, setProofCost] = useState<string>('$100');

    const [milestone1, setMilestone1] = useState<string>('1 Week');
    const [milestone2, setMilestone2] = useState<string>('1 Week');
    const [milestone3, setMilestone3] = useState<string>('1 Week');
    const [milestone4, setMilestone4] = useState<string>('1 Week');
    const [milestone5, setMilestone5] = useState<string>('1 Week');

    const [editorial, setEditorial] = useState<boolean>(false);
    const [editorialPrice, setEditorialPrice] = useState<string>('');
    const [design, setDesign] = useState<boolean>(false);
    const [designPrice, setDesignPrice] = useState<string>('');
    const [printing, setPrinting] = useState<boolean>(false);
    const [printingPrice, setPrintingPrice] = useState<string>('');
    const [photography, setPhotography] = useState<boolean>(false);
    const [photographyPrice, setPhotographyPrice] = useState<string>('');
    const [imap, setImap] = useState<boolean>(false);
    const [imapPrice, setImapPrice] = useState<string>('');
    const [goguide, setGoguide] = useState<boolean>(false);
    const [goguidePrice, setGoguidePrice] = useState<string>('');
    const [kiosk, setKiosk] = useState<boolean>(false);
    const [kioskPrice, setKioskPrice] = useState<string>('');
    const [adSales, setAdSales] = useState<boolean>(false);
    const [adSalesPrice, setAdSalesPrice] = useState<string>('');
    const [adSalesRevenue, setAdSalesRevenue] = useState<string>('');
    const [palletJack, setPalletJack] = useState<boolean>(false);
    const [insideDelivery, setInsideDelivery] = useState<boolean>(false);
    const [insideDeliveryPrice, setInsideDeliveryPrice] = useState<string>('');
    const [liftGate, setLiftGate] = useState<boolean>(false);
    const [multiYearAgreement, setMultiYearAgreement] = useState<boolean>(false);
    const [multiYearLength, setMultiYearLength] = useState<string>('1');
    const [payToLc, setPayToLc] = useState<boolean>(false);
    const [designOnly, setDesignOnly] = useState<boolean>(false);

    /*
        // Basic Info
        type_of_pub: 'Visitor Guide',
        business_name: '',
        pub_name: '',
        date: '',
        contact_name: '',
        contact_title: '',

        // Contact Info
        email: '',
        phone: '',

        // Address
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',

        // Publication Info
        size: '',
        flat_size: '',
        finished_size: '',
        quantity: '',
        num_body_pages: '',
        num_covers: '',
        body_weight: '',
        cover_weight: '',
        paper_grade_body: 'Grade 3',
        paper_grade_cover: 'Grade 3',
        cover_paper_type: '',
        cover_coating: '',
        binding: 'Perfect Binding',
        body_paper_type: '',
        packaging_weight: '',
        proof_cost: '$100',

        // Timeline
        milestone1: '1 Week',
        milestone2: '1 Week',
        milestone3: '1 Week',
        milestone4: '1 Week',
        milestone5: '1 Week',

        // Options
        editorial: false,
        editorial_price: 0,
        design: false,
        design_price: 0,
        printing: false,
        printing_price: 0,
        photography: false,
        photography_price: 0,
        imap: false,
        imap_price: 0,
        goguide: false,
        goguide_price: 0,
        kiosk: false,
        kiosk_price: 0,
        ad_sales: false,
        ad_sales_price: 0,
        ad_sales_revenue: 0,
        pallet_jack: false,
        inside_delivery: false,
        inside_delivery_price: 0,
        lift_gate: false,
        multi_year_agreement: false,
        multi_year_length: 1,
        pay_to_lc: false,
        design_only: false
    */

    const saveProposal = (e: React.FormEvent) => {
        e.preventDefault();

        axios
            .post(
                'https://api.lunarcow.com/sales-proposals', {
                    pub_year: pubYear,
                    type_of_pub: typeOfPub,
                    business_name: businessName,
                    pub_name: pubName,
                    date: date,
                    contact_name: contactName,
                    contact_title: contactTitle,

                    // Contact Info
                    email: email,
                    phone: phone,

                    // Address
                    line1: line1,
                    line2: line2,
                    city: city,
                    state: state,
                    zip: zip,

                    // Publication Info
                    size: size,
                    flat_size: flatSize,
                    finished_size: finishedSize,
                    quantity: quantity,
                    num_body_pages: numBodyPages,
                    num_covers: numCovers,
                    body_weight: bodyWeight,
                    cover_weight: coverWeight,
                    paper_grade_body: paperGradeBody,
                    paper_grade_cover: paperGradeCover,
                    cover_paper_type: coverPaperType,
                    cover_coating: coverCoating,
                    binding: binding,
                    body_paper_type: bodyPaperType,
                    packaging_weight: packagingWeight,
                    proof_cost: proofCost,

                    // Timeline
                    milestone1: milestone1,
                    milestone2: milestone2,
                    milestone3: milestone3,
                    milestone4: milestone4,
                    milestone5: milestone5,

                    // Options
                    editorial: editorial,
                    editorial_price: editorialPrice,
                    design: design,
                    design_price: designPrice,
                    printing: printing,
                    printing_price: printingPrice,
                    photography: photography,
                    photography_price: photographyPrice,
                    imap: imap,
                    imap_price: imapPrice,
                    goguide: goguide,
                    goguide_price: goguidePrice,
                    kiosk: kiosk,
                    kiosk_price: kioskPrice,
                    ad_sales: adSales,
                    ad_sales_price: adSalesPrice,
                    ad_sales_revenue: adSalesRevenue,
                    pallet_jack: palletJack,
                    inside_delivery: insideDelivery,
                    inside_delivery_price: insideDeliveryPrice,
                    lift_gate: liftGate,
                    multi_year_agreement: multiYearAgreement,
                    multi_year_length: multiYearLength,
                    pay_to_lc: payToLc,
                    design_only: designOnly
                }, {
                    headers: {
                        'x-auth-token': '_lc_token_'
                    }
                }
            )
            .then(() => navigate('/sales-proposals'))
            .catch(err => console.log('There was an error saving the sales proposal', ' => ', err.message));
    };

    return <FullPage>
        <section className="section" style={{ paddingTop: '2rem' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <header className="section-header">
                            <h2>Create Sales Proposal</h2>

                            <hr />

                            <p className="lead">
                                Enter the fields below to generate a new sales proposal.
                                The generated PDF will be available through the main sales-proposal screen.
                            </p>
                        </header>
                    </div>

                    <div className="col-md-4 mx-auto">
                        <h6 className="divider">Basic Info</h6>

                        <div className="form-group">
                            <label>Business Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Somewhere County"
                                value={businessName}
                                onChange={event => setBusinessName(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Publication Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="2020 Visitor Guide"
                                value={pubName}
                                onChange={event => setPubName(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Publication Year</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={pubYear}
                                onChange={event => setPubYear(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Type of Publication</label>
                            <select
                                className="form-control"
                                value={typeOfPub}
                                onChange={event => setTypeOfPub(event.target.value)}>
                                <option value="Visitor Guide">Visitor Guide</option>
                                <option value="Community Guide">Community Guide</option>
                                <option value="Business Directory">Business Directory</option>
                                <option value="Relocation Guide">Relocation Guide</option>
                                <option value="Activities Guide">Activities Guide</option>
                                <option value="Dining Guide">Dining Guide</option>
                                <option value="Travel Guide">Travel Guide</option>
                                <option value="Community Map">Community Map</option>
                                <option value="Community Profile Guide">Community Profile Guide</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Date</label>
                            <InputMask
                                mask="99/99/9999"
                                className="form-control"
                                onChange={event => setDate(event.target.value)}
                                value={date} />
                        </div>

                        <div className="form-group">
                            <label>Contact Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={contactName}
                                onChange={event => setContactName(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Contact Title</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={contactTitle}
                                onChange={event => setContactTitle(event.target.value)} />
                        </div>

                        <h6 className="divider">Contact Info</h6>

                        <div className="form-group">
                            <label>Email</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={email}
                                onChange={event => setEmail(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Phone</label>
                            <InputMask
                                mask="(999) 999-9999"
                                className="form-control"
                                onChange={event => setPhone(event.target.value)}
                                value={phone} />
                        </div>

                        <h6 className="divider">Address</h6>

                        <div className="form-group">
                            <label>Address Line 1</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={line1}
                                onChange={event => setLine1(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Address Line 2</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={line2}
                                onChange={event => setLine2(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>City</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={city}
                                onChange={event => setCity(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>State</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={state}
                                onChange={event => setState(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Zip</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={zip}
                                onChange={event => setZip(event.target.value)} />
                        </div>
                    </div>

                    <div className="col-md-4 mx-auto">
                        <h6 className="divider">Publication Info</h6>

                        {typeOfPub === 'Community Map' &&
                            <div className="form-group">
                                <label>Flat Size</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={flatSize}
                                    onChange={event => setFlatSize(event.target.value)} />
                            </div>
                        }

                        {typeOfPub === 'Community Map' &&
                            <div className="form-group">
                                <label>Finished Size</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={finishedSize}
                                    onChange={event => setFinishedSize(event.target.value)} />
                            </div>
                        }

                        {typeOfPub !== 'Community Map' &&
                            <div className="form-group">
                                <label>Size</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={size}
                                    onChange={event => setSize(event.target.value)} />
                            </div>
                        }

                        <div className="form-group">
                            <label>Quantity</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={quantity}
                                onChange={event => setQuantity(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Binding</label>
                            <select
                                className="form-control"
                                value={binding}
                                onChange={event => setBinding(event.target.value)}>
                                <option value="Perfect Binding">Perfect Binding</option>
                                <option value="Saddle Stitch">Saddle Stitch</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Packaging Weight</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={packagingWeight}
                                onChange={event => setPackagingWeight(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Printing Proof Cost</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={proofCost}
                                onChange={event => setProofCost(event.target.value)} />
                        </div>

                        <h6 className="divider">Cover Info</h6>

                        <div className="form-group">
                            <label>Number of Cover Pages</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={numCovers}
                                onChange={event => setNumCovers(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Cover Weight</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={coverWeight}
                                onChange={event => setCoverWeight(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Paper Grade Cover</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={paperGradeCover}
                                onChange={event => setPaperGradeCover(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Cover Coating</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={coverCoating}
                                onChange={event => setCoverCoating(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Cover Paper Type</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={coverPaperType}
                                onChange={event => setCoverPaperType(event.target.value)} />
                        </div>

                        <h6 className="divider">Body Info</h6>

                        <div className="form-group">
                            <label>Number of Body Pages</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={numBodyPages}
                                onChange={event => setNumBodyPages(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Body Weight</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={bodyWeight}
                                onChange={event => setBodyWeight(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Paper Grade Body</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={paperGradeBody}
                                onChange={event => setPaperGradeBody(event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Body Paper Type</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={bodyPaperType}
                                onChange={event => setBodyPaperType(event.target.value)} />
                        </div>
                    </div>

                    <div className="col-md-4 mx-auto">
                        <h6 className="divider">Timeline</h6>

                        <div className="form-group">
                            <label>Milestone 1</label>
                            <select
                                className="form-control"
                                value={milestone1}
                                onChange={event => setMilestone1(event.target.value)}>
                                <option value="1 Week">1 Week</option>
                                <option value="2 Weeks">2 Weeks</option>
                                <option value="3 Weeks">3 Weeks</option>
                                <option value="4 Weeks">4 Weeks</option>
                                <option value="5 Weeks">5 Weeks</option>
                                <option value="6 Weeks">6 Weeks</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Milestone 2</label>
                            <select
                                className="form-control"
                                value={milestone2}
                                onChange={event => setMilestone2(event.target.value)}>
                                <option value="1 Week">1 Week</option>
                                <option value="2 Weeks">2 Weeks</option>
                                <option value="3 Weeks">3 Weeks</option>
                                <option value="4 Weeks">4 Weeks</option>
                                <option value="5 Weeks">5 Weeks</option>
                                <option value="6 Weeks">6 Weeks</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Milestone 3</label>
                            <select
                                className="form-control"
                                value={milestone3}
                                onChange={event => setMilestone3(event.target.value)}>
                                <option value="1 Week">1 Week</option>
                                <option value="2 Weeks">2 Weeks</option>
                                <option value="3 Weeks">3 Weeks</option>
                                <option value="4 Weeks">4 Weeks</option>
                                <option value="5 Weeks">5 Weeks</option>
                                <option value="6 Weeks">6 Weeks</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Milestone 4</label>
                            <select
                                className="form-control"
                                value={milestone4}
                                onChange={event => setMilestone4(event.target.value)}>
                                <option value="1 Week">1 Week</option>
                                <option value="2 Weeks">2 Weeks</option>
                                <option value="3 Weeks">3 Weeks</option>
                                <option value="4 Weeks">4 Weeks</option>
                                <option value="5 Weeks">5 Weeks</option>
                                <option value="6 Weeks">6 Weeks</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Milestone 5</label>
                            <select
                                className="form-control"
                                value={milestone5}
                                onChange={event => setMilestone5(event.target.value)}>
                                <option value="1 Week">1 Week</option>
                                <option value="2 Weeks">2 Weeks</option>
                                <option value="3 Weeks">3 Weeks</option>
                                <option value="4 Weeks">4 Weeks</option>
                                <option value="5 Weeks">5 Weeks</option>
                                <option value="6 Weeks">6 Weeks</option>
                            </select>
                        </div>

                        <h6 className="divider">Options</h6>

                        <div className="custom-controls-stacked">
                            <div className="row mb-2">
                                <div className="col">
                                    <div className="mb-1">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!palletJack}
                                            onChange={e => setPalletJack(e.target.checked)} />
                                        <label className="">Pallet Jack</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col">
                                    <div className="mb-1">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!liftGate}
                                            onChange={e => setLiftGate(e.target.checked)} />
                                        <label className="">Lift Gate</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col">
                                    <div className="mb-1">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!payToLc}
                                            onChange={e => setPayToLc(e.target.checked)} />
                                        <label className="">Pay To Lunar Cow</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col">
                                    <div className="mb-1">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!multiYearAgreement}
                                            onChange={e => setMultiYearAgreement(e.target.checked)} />
                                        <label className="">Multi Year</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="Commitment Length"
                                        value={multiYearLength}
                                        onChange={event => setMultiYearLength(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!printing}
                                            onChange={e => setPrinting(e.target.checked)} />
                                        <label className="">Printing</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="Printing Price"
                                        value={printingPrice}
                                        onChange={event => setPrintingPrice(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!design}
                                            onChange={e => setDesign(e.target.checked)} />
                                        <label className="">Design</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="Design Price"
                                        value={designPrice}
                                        onChange={event => setDesignPrice(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!editorial}
                                            onChange={e => setEditorial(e.target.checked)} />
                                        <label className="">Editorial</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="Editorial Price"
                                        value={editorialPrice}
                                        onChange={event => setEditorialPrice(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!photography}
                                            onChange={e => setPhotography(e.target.checked)} />
                                        <label className="">Photography</label>
                                    </div>
                                </div>
                                <div className="col">
                                    {/* TODO: Disable input field if parent checkbox is not checked */}
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="Photography Price"
                                        value={photographyPrice}
                                        onChange={event => setPhotographyPrice(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!imap}
                                            onChange={e => setImap(e.target.checked)} />
                                        <label className="">iMap</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="iMap Price"
                                        value={imapPrice}
                                        onChange={event => setImapPrice(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!goguide}
                                            onChange={e => setGoguide(e.target.checked)} />
                                        <label className="">GoGuide</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="GoGuide Price"
                                        value={goguidePrice}
                                        onChange={event => setGoguidePrice(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!kiosk}
                                            onChange={e => setKiosk(e.target.checked)} />
                                        <label className="">Kiosk</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="Kiosk Price"
                                        value={kioskPrice}
                                        onChange={event => setKioskPrice(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!insideDelivery}
                                            onChange={e => setInsideDelivery(e.target.checked)} />
                                        <label className="">Inside Delivery</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="Inside Delivery Price"
                                        value={insideDeliveryPrice}
                                        onChange={event => setInsideDeliveryPrice(event.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className=""> {/* custom-control custom-checkbox */}
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!adSales}
                                            onChange={e => setAdSales(e.target.checked)} /> {/* custom-control-input */}
                                        <label className="">Ad Sales</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <input
                                        className="form-control form-control-sm mb-1"
                                        type="text"
                                        placeholder="Ad Sales Price"
                                        value={adSalesPrice}
                                        onChange={event => setAdSalesPrice(event.target.value)} />
                                    <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        placeholder="Ad Sales Revenue"
                                        value={adSalesRevenue}
                                        onChange={event => setAdSalesRevenue(event.target.value)} />
                                </div>
                            </div>

                            <h6 className="divider">Additional Flags</h6>

                            <div className="row mb-2">
                                <div className="col">
                                    <div className="mb-1">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={!!designOnly}
                                            onChange={e => setDesignOnly(e.target.checked)} />
                                        <label className="">Design Only</label>
                                    </div>
                                </div>
                            </div>

                            <h6 className="divider">Finalize</h6>

                            <button
                                type="button"
                                className="btn btn-block btn-primary"
                                onClick={saveProposal}>Save Proposal</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </FullPage>
};

export default CreateSalesProposalPage;
