import React, { useState } from 'react';
// import { Link } from '@reach/router';
import { nanoid } from 'nanoid';
import moment from 'moment';

import AddMarketingListCommunication from './AddMarketingListCommunication';
import AddMarketingListEvent from './AddMarketingListEvent';

interface IProps {
    prospect: any,
    communicationTypes: any,
    communicationDispositions: any,
    eventTypes: any
}

const ProjectList: React.FC<IProps> = (props) => {
    const [prospect, setProspect] = useState(props.prospect);

    const addCommunication = () => {
    };

    const addEvent = () => {
        // e.preventDefault()
    };

    return (
        <div className="card" key={nanoid()}>
            <h5 className="card-title bg-white">
                <a
                    data-toggle="collapse"
                    href={`#ml-${prospect.id}`}
                    className="collapsed"
                    aria-expanded="false"
                >
                    {prospect.name}
                    <span
                        className={`text-${prospect.notInterested ? 'danger' : (prospect.sold ? 'success' : 'primary')}`}
                        style={{ float: 'right', paddingRight: '20px' }}
                    >
                        <i className={
                            `fa fa-${prospect.notInterested ?
                                'times' :
                                (prospect.sold ? 'check' : '')}`
                            } />
                    </span>
                </a>
            </h5>

            <div id={`ml-${prospect.id}`} className="collapse" data-parent="#ml-accordion">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 mx-auto p-4">
                            {/*<strong>Contact:</strong> {prospect.contact || 'No Contact Provided'}{prospect.title ? ` (${prospect.title})` : ''}<br />*/}
                            <strong>Contact:</strong> {prospect.type.name || 'No Type Provided'}<br />
                            <strong>Email:</strong> {prospect.email || <span className="text-danger">No Email Provided</span>}<br />
                            <strong>URL:</strong> {prospect.url || 'No URL Provided'}<br />
                            <strong>Office Phone:</strong> {prospect.phone || 'No Phone Provided'}<br />
                            <strong>Mobile:</strong> {prospect.phone || 'No Mobile Provided'}<br />
                        </div>

                        <div className="col-md-6 mx-auto p-4">
                            <strong>Line 1:</strong> {prospect.line1 || 'No Info'}<br />
                            <strong>Line 2:</strong> {prospect.line2 || 'No Info'}<br />
                            <strong>City:</strong> {prospect.city || 'No Info'}<br />
                            <strong>State:</strong> {(prospect.state && prospect.state.abbr) ? prospect.state.abbr : 'No Info'}<br />
                            <strong>Zip:</strong> {prospect.zip || 'No Info'}<br />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mx-auto p-4">
                            {/*<strong>Contact:</strong> {prospect.contact || 'No Contact Provided'}{prospect.title ? ` (${prospect.title})` : ''}<br />*/}
                            <strong>Comments:</strong><br />
                            {prospect.comments || 'None'}
                        </div>
                    </div>

                    {/*prospect.email &&
                        <React.Fragment>
                            <div className="row">
                                <div className="col lh-5">
                                    <hr />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-9">
                                    <p>
                                        <select
                                            className="form-control"
                                            // value={newConnectionType || ''}
                                            // onChange={e => { e.preventDefault(); this.setState({ newConnectionType: e.target.value })}}
                                        >
                                            <option value="">-- Select Boilerplate --</option>
                                            <option value="1">Google Meet Invitation Follow Up</option>
                                            <option value="2">Discovery Meeting Follow Up</option>
                                            <option value="3">Day of Meeting Follow Up</option>
                                            <option value="4">Additional Info Follow Up</option>
                                            <option value="5">Proposal Follow Up</option>
                                            <option value="6">Follow Up Introduction</option>
                                        </select>
                                    </p>
                                </div>

                                <div className="col-3">
                                    <button
                                        className="btn btn-success btn-block"
                                        // onClick={e => this.addConnection(e, prospect.id)}
                                    >
                                        Send Email
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    */}

                    <div className="row">
                        <div className="col lh-5">
                            <hr />
                        </div>
                    </div>

                    {/*
                    <div className="row">
                        <div className="col-4">
                            <select
                                className="form-control"
                                value={size}
                                onChange={e => setSize(parseInt(e.target.value, 10))}>
                                    <option>1/2 Page</option>
                                    <option>1/4 Page</option>
                                    <option>Full Page</option>
                                    <option>Back Cover</option>
                            </select>
                        </div>

                        <div className="col-8">
                            <button
                                className={`btn ${prospect.email ? 'btn-primary' : 'btn-secondary'}`}
                                onClick={e => addAgreement(e, prospect.id)}
                                disabled={!prospect.email}
                            >
                                Create Agreement
                            </button>

                            <button className="btn btn-danger ml-3" onClick={e => notInterested(e, prospect.id)}>Not Interested</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col lh-5">
                            <hr />
                        </div>
                    </div>
                    */}

                    <div className="row">
                        <div className="col-4">
                            <AddMarketingListCommunication
                                contactId={prospect.id}
                                communicationTypes={props.communicationTypes}
                                communicationDispositions={props.communicationDispositions}
                            />
                        </div>

                        <div className="col-8">
                            {prospect.communications && prospect.communications.map((
                                communication: {
                                    comment: string;
                                    start: string;

                                    disposition: {
                                        name: string;
                                    }

                                    type: {
                                        name: string;
                                    }
                                }
                            ) => (
                                <div key={nanoid()} className="media align-items-center pb-2">
                                    <div className="media-body lh-4">
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="mb-0">
                                                    {(communication.type && communication.type.name) ? communication.type.name : 'Unkown Type'}, {communication.disposition ? communication.disposition.name : 'Unknown Disposition'}
                                                </h6>
                                            </div>
                                            <div className="col-auto">
                                                <h6 className="mb-0">{moment(communication.start).format('MM/DD/YYYY[, ]LT')}</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <p className="text-light mb-0 small-2">{communication.comment || 'No Comment'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col lh-5">
                            <hr />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <AddMarketingListEvent contactId={prospect.id} eventTypes={props.eventTypes} />
                        </div>

                        <div className="col-8">
                            {/* {!contact.events && <span className="text-warning">No Events</span>} */}

                            {prospect.events && prospect.events.map((
                                event: {
                                    type_id: number;
                                    type_id_old: number;
                                    time: string;
                                    desc: string;

                                    type: {
                                        name: string;
                                    }
                                }
                            ) => (
                                <div key={nanoid()} className="media align-items-center pb-2">
                                    <div className="media-body lh-4">
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="mb-0">{event.type.name || 'Unknown Type'}</h6>
                                            </div>
                                            <div className="col-auto">
                                                <h6 className="mb-0">{event.time}</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <p className="text-light mb-0 small-2">{event.desc || 'No Description'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectList;
