import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';

// TODO: Import components

interface ISalesRepReportPageProps extends RouteComponentProps { }

interface IState { }

class SalesRepReportPage extends React.Component<ISalesRepReportPageProps, IState> {
    // Define the constructor for the sales rep report page
    constructor (props: ISalesRepReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the sales rep report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Rep Report for Employee Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">
                                            <span className="badge badge-secondary mr-1">Day</span>
                                            <span className="badge badge-primary mr-1">Week</span>
                                            <span className="badge badge-secondary mr-1">Month</span>
                                            <span className="badge badge-secondary mr-1">Quarter</span>
                                            <span className="badge badge-secondary mr-1">Year</span>
                                            <span className="badge badge-secondary mr-1">All Time</span>
                                        </span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        {/* TODO: Add functionality and remove warning */}
                        <div className="alert alert-warning" role="alert">Sales Rep report is not yet functional</div>

                        {/*
                            [-] Agent Name
                            [-] Projects working on and worked on by Day / Week/ month / Quarter / Year
                            [-] Projects working on and worked on summarized by Day / Week/ month / Quarter / Year
                            [-] Hours worked by Day / Week/ month / Quarter / Year
                            [-] Decision Makers Completed by Hour / Day / Week/ month / Quarter / Year (DMPH)
                            [-] Sales Conversion Rate By Project and Summarized By Totals
                            [-] Sales Per Hour By Project and Summarized By Total
                            [-] Number of Times A Record Has Been Contacted By The Rep
                            [-] Total Sales broken out by project and summarized by total by Day / Week/ month / Quarter / Year
                            [-] Total Revenue broken out by project and summarized by total by Day / Week/ month / Quarter / Year
                            [-] Sizes of ads sold by project and summarized by total by Day / Week/ month / Quarter / Year
                            [-] Prices of ads sold by project and summarized by total by Day / Week/ month / Quarter / Year
                            [-] Penetration of marketing lists worked by project and summarized by total by Day / Week/ month / Quarter / Year
                            [-] Average Sale per project and summarized by total by Day / Week/ month / Quarter / Year
                            [-] Sales commission that have been paid to the sales rep by project and summarized by total by Day / Week/ month / Quarter / Year
                            [-] Art Work collected by project and summarized by total by Day / Week/ month / Quarter / Year
                            [-] Release Forms collected by project and summarized by total by Day / Week/ month / Quarter / Year
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default SalesRepReportPage;
