import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { useMutation } from 'urql';
import moment from 'moment-timezone';
import { AddRequestMutation } from '../../mutations/AddRequestMutation';

interface IProps {
    employeeId: string;

    types: [{
        id: number;
        name: string;
    }];

    refresh: () => void;
};

const AddUserRequestForm: React.FC<IProps> = ({ employeeId, types, refresh }) => {
    const [typeId, setTypeId] = useState<number>(0);
    const [start, setStart] = useState<string>('');
    const [end, setEnd] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);

    const [addRequestResult, executeRequestMutation] = useMutation(AddRequestMutation);

    const addRequest = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            executeRequestMutation({
                user_id: employeeId,
                type_id: typeId,
                start: moment(start).tz("America/New_York").format(),
                end: moment(end).tz("America/New_York").format()
            });

            // Set the fields to empty
            setTypeId(0);
            setStart('');
            setEnd('');

            // Set the success message to display
            setSuccess(true);

            refresh();
        } catch (err) {
            console.warn(err);
        }
    };

    return <form>
        <div className="border rounded shadow-2 p-4 mb-4 bg-white">
            <div className="row pb-4">
                <div className="col lh-5">
                    <select
                        className="form-control"
                        value={typeId || 0}
                        onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                        ): void => {
                            setTypeId(parseInt(e.target.value, 10))}
                        }
                    >
                        <option value="0">-- Request Type --</option>
                        {types.map((type: { id: number; name: string; }) => (
                            <option key={nanoid()} value={type.id}>{type.name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Start (MM/DD/YYYY)"
                        value={start}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setStart(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="End (MM/DD/YYYY)"
                        value={end}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setEnd(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row">
                <div className="col lh-5 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={addRequest}
                        disabled={typeId === 0 || start === '' || end === ''}
                    >
                        Add Request
                    </button>
                </div>
            </div>

            {success && <div className="alert alert-success mt-3" role="alert">Request submitted</div>}
        </div>
    </form>;
};

export default AddUserRequestForm;
