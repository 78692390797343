import React, { useState } from 'react';
import { RouteComponentProps, Link } from '@reach/router'; // match
import { useQuery, useMutation } from 'urql';
import { nanoid } from 'nanoid';
import moment from 'moment';
import FullPage from '../../layouts/FullPage';
import gql from 'graphql-tag';
import AlertMessage from '../../components/alerts/AlertMessage';

import AddIceForm from '../../components/employees/AddIceForm';
import AddRequestForm from '../../components/employees/AddRequestForm';
import AddDocumentForm from '../../components/employees/AddDocumentForm';

// import DocumentList from '../../components/employees/DocumentList';

interface MatchParams { id: string; }
interface MatchProps extends RouteComponentProps<MatchParams> { }

const q = gql`
    query (
        $employeeId: String!,
        $dateStart: timestamptz!,
        $dateEnd: timestamptz!
    ) {
        users (where: {id: {_eq: $employeeId }}) {
            id
            name
            email
            phone
            ext
            line1
            line2
            city
            hired
            anniversary
            birthday
            terminated

            state {
                id
                abbr
            }

            zip
            url

            type: employee_type {
                id
                name
            }

            absences {
                id
                day
                full_day_absence
                time_start
                time_end
            }

            benefits {
                id
                days_sick
                days_vacation
                days_personal
            }

            documents {
                id
                name
                url
                date
            }

            ice {
                id
                name
                relationship
                email
                phone
            }

            requests (order_by: {start: desc}, where: {start: {_gte: $dateStart, _lte: $dateEnd}}) {
                id
                start
                end
                deny_reason
                reviewed
                approved
                half_day

                type {
                    id
                    name
                }
            }

            salaries {
                id
                salary
                comments
            }
        }

        user_request_types (order_by: {name: asc}) {
            id
            name
        }

        user_document_types (order_by: {name: asc}) {
            id
            name
        }

        request_years: user_requests_aggregate (where: {user_id: {_eq: $employeeId}}) {
            aggregate {
                max {
                    start
                }
                min {
                    start
                }
            }
        }
    }
`;

// const EmployeePage: React.FC<MatchParams> = (match: MatchProps) => {
const EmployeePage: React.FC<MatchProps> = (match: MatchProps) => {
    const employeeId = match.id;
    const year = moment().year();
    
    const [reqYearFilter, setReqYearFilter] = useState<number>(year);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [line1, setLine1] = useState<string>('');
    const [line2, setLine2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [zip, setZip] = useState<string>('');

    const [hired, setHired] = useState<string>('');
    const [anniversary, setAnniversary] = useState<string>('');
    const [birthday, setBirthday] = useState<string>('');

    // console.warn(`employeeId: ${employeeId}`)
    // console.warn(`match`, ' =>> ', match)

    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: {
            employeeId: employeeId,
            dateStart: `${reqYearFilter}-01-01`,
            dateEnd: `${reqYearFilter}-12-31`
        }
    });

    const [approveRequestResult, executeApproveRequestMutation] = useMutation(gql`mutation approveUserRequest ($id: Int!, $approved: Int!) {
        update_user_requests_by_pk(pk_columns: {id: $id}, _set: { reviewed: 1, approved: $approved}) {
            id
        }
    }`);

    const [removeRequestResult, executeRemoveRequestMutation] = useMutation(gql`mutation removeUserRequest ($id: Int!) {
        delete_user_requests_by_pk(id: $id) {
            id
        }
    }`);

    const { data, fetching, error } = result;

    const refresh = () => reexecuteQuery({ requestPolicy: 'cache-and-network' });

    if (fetching) {
        return <FullPage>
            <div className="row gap-y">
                <div className="col-12 col-lg-2">
                    Loading...
                </div>
            </div>
        </FullPage>;
    }

    if (error) return <AlertMessage type="danger" text={error.message} />;

    const employee = data.users[0];
    const requests = employee.requests;

    console.log('requests', requests);

    // Define the variables to hold the total days
    let vacationDaysUsed = 0;
    let sickDaysUsed = 0;
    let personalDaysUsed = 0;
    let loaDaysUsed = 0;

    if (data && data.users[0].requests && data.users[0].requests.length > 0) {
        // Iterate over the request and total the days for each type
        data.users[0].requests.map((req: any) => {
            // Only add the days to the total if the request was approved
            if (req.reviewed && req.approved) {
                if (req.type.id === 1) { // Vacation request
                    if (req.half_day) {
                        vacationDaysUsed += 0.5;
                    } else {
                        // Sum the days for the request and add it to the vacation total
                        vacationDaysUsed += Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1;
                    }
                } else if (req.type.id === 2) { // Sick request
                    if (req.half_day) {
                        sickDaysUsed += 0.5;
                    } else {
                        // Sum the days for the request and add it to the sick total
                        sickDaysUsed += Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1;
                    }
                } else if (req.type.id === 3) { // Personal day
                    if (req.half_day) {
                        personalDaysUsed += 0.5;
                    } else {
                        // Sum the days for the request and add it to the personal total
                        personalDaysUsed += Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1;
                    }
                } else { // Leave of absence (type.id 4)
                    if (req.half_day) {
                        loaDaysUsed += 0.5;
                    } else {
                        // Sum the days for the request and add it to the leave of absence total
                        loaDaysUsed += Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1;
                    }
                }
            }
        });
    }

    // Extract the year from the min and max. They start off as timestamps (0000-00-00T00:00:00+00:00)
    const reqYears = data.request_years.aggregate;

    const minYear = (reqYears && reqYears.min && reqYears.min.start) ? reqYears.min.start.substring(0, 4) : moment().year();
    const maxYear = (reqYears && reqYears.max && reqYears.max.start) ? reqYears.max.start.substring(0, 4) : moment().year();

    // Create an array for the year range so that we can iterate over it for our filter
    const years = Array(maxYear - (minYear - 1)).fill('').map((v, idx) => maxYear- idx) as Array<number>;

    const updateInfo = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // 
    };

    const updateDates = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // 
    };

    const addSalary = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // 
    };

    const removeSalary = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // 
    };

    const approveReq = async (e: React.FormEvent, id: number) => {
        e.preventDefault();

        try {
            executeApproveRequestMutation({ id, approved: 1 });
            refresh();
        } catch (err) {
            console.warn(err);
        }
    };

    const denyReq = async (e: React.FormEvent, id: number) => {
        e.preventDefault();

        try {
            executeApproveRequestMutation({ id, approved: 0 });
            refresh();
        } catch (err) {
            console.warn(err);
        }
    };

    const removeReq = async (e: React.FormEvent, id: number) => {
        e.preventDefault();

        try {
            executeRemoveRequestMutation({ id });

            const reqIndex = requests.findIndex((o: any) => o.id === id);

            // If an index was found for the request ID
            if (reqIndex > -1) {
                // Remove it from the array
                requests.splice(reqIndex, 1);
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const addReq = async (type: number, start: string, end: string) => {

    };

    const updateYearFilter = (year: number) => setReqYearFilter(year);

    console.log('render triggered');

    return <FullPage>
        <section className="section" style={{ paddingTop: '1rem' }}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                    <div className="container d-stick-none">
                        <div className="row">
                            <div className="col-8">
                                <div className="nav nav-navbar">
                                    <span className="nav-link active">{employee.name}, {employee.type.name}</span>
                                </div>
                            </div>

                            <div className="col-4 ml-auto">
                                <nav className="nav nav-navbar justify-content-end">
                                    <Link to="/dashboard" className="nav-link">Back to Dashboard</Link>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <hr className="my-0 w-100" />

                    <div className="container">
                        <div className="navbar-left">
                            <button className="navbar-toggler" type="button">
                                <span className="navbar-toggler-icon" />
                            </button>
                        </div>

                        <section className="navbar-mobile">
                            <div className="nav nav-navbar mr-auto">
                                <a href="#info" className="nav-link active" data-toggle="tab">Info</a> {/* Info, benefits, etc */}
                                <a href="#ice" className="nav-link" data-toggle="tab">Emergency Contacts</a>
                                <a href="#reqs" className="nav-link" data-toggle="tab">Requests</a>
                                <a href="#docs" className="nav-link" data-toggle="tab">Documents</a>
                            </div>

                            <nav className="nav nav-navbar ml-lg-5">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Reports <span className="arrow" /></a>
                                    <nav className="nav dropdown-menu-right">
                                        <Link className="nav-link" to={`/employees/${employee.id}/reports/sales-commission`}>Sales Commission</Link>
                                        <Link className="nav-link" to={`/employees/${employee.id}/reports/sales-rep`}>Sales Rep</Link>
                                        <Link className="nav-link" to={`/employees/${employee.id}/reports/sales-rep-hours`}>Sales Rep Hours</Link>
                                        <Link className="nav-link" to={`/employees/${employee.id}/reports/designer-productivity`}>Design Productivity</Link>
                                        <Link className="nav-link" to={`/employees/${employee.id}/reports/designer-production`}>Design Production</Link>
                                        <Link className="nav-link" to={`/employees/${employee.id}/reports/lead-generation`}>Lead Generation</Link>
                                    </nav>
                                </li>
                            </nav>
                        </section>
                    </div>
                </nav>
            </div>
        </section>

        <div className="tab-content p-4">
            <section className="section tab-pane show active" id="info" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 pl-0">
                            <form>
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Name</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Name"
                                                    value={name || ''}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setName(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Email</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={email || employee.email}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setEmail(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Phone</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Cell Phone"
                                                    value={mobile || employee.phone}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setMobile(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Line 1</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Line 1"
                                                    value={line1 || employee.line1}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setLine1(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Line 2</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Line 2"
                                                    value={line2 || employee.line2}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setLine2(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">City</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="City"
                                                    value={city || employee.city}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setCity(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*
                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <select
                                                className="form-control"
                                                value={state || 0}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLSelectElement>
                                                ): void => {
                                                    setState(parseInt(e.target.value, 10))}
                                                }
                                            >
                                                <option value="">-- State --</option>
                                                {states.map((type: { id: number; name: string; }) => (
                                                    <option key={nanoid()} value={type.id}>{type.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    */}

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Zip</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Zip"
                                                    value={zip || employee.zip}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setZip(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5 text-right">
                                            <button
                                                className="btn btn-primary"
                                                onClick={updateInfo}
                                            >
                                                Save Info
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            {/*
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5">Name</div>
                                    <div className="col-auto text-center">{employee.name || 'Not Provided'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Email</div>
                                    <div className="col-auto text-center">{employee.email || 'Not Provided'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Mobile</div>
                                    <div className="col-auto text-center">{employee.phone || 'Not Provided'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Address</div>
                                    <div className="col-auto text-center">
                                        {employee.line1}<br />
                                        {`${employee.city}, ${employee.state.abbr} ${employee.zip}`}
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>

                        <div className="col-md-4 pl-0 pr-0">
                            <form>
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Hired</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Hired (MM/DD/YYYY)"
                                                    value={hired || moment(employee.hired).format('L')}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setHired(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Anniversary</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Anniversary (MM/DD/YYYY)"
                                                    value={anniversary || moment(employee.anniversary).format('L')}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setAnniversary(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        <div className="col lh-5">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Birthday</span>
                                                </div>
                                                <input
                                                    className="form-control"
                                                    placeholder="Birthday (MM/DD/YYYY)"
                                                    value={birthday || moment(employee.birthday).format('L')}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ): void => {
                                                        setBirthday(e.target.value)}
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5 text-right">
                                            <button
                                                className="btn btn-primary"
                                                onClick={updateDates}
                                            >
                                                Save Dates
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {/*
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5">Hire Date</div>
                                    <div className="col-auto text-center">{moment(employee.hired).format('L') || 'Not Provided'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Anniversary Date</div>
                                    <div className="col-auto text-center">{moment(employee.anniversary).format('L') || 'Not Provided'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Birthday</div>
                                    <div className="col-auto text-center">{moment(employee.birthday).format('L') || 'Not Provided'}</div>
                                </div>

                                {employee.terminated &&
                                    <div className="row">
                                        <div className="col lh-5">Termination Date</div>
                                        <div className="col-auto text-center">{moment(employee.terminated).format('L') || ''}</div>
                                    </div>
                                }
                            </div>
                            */}
                        </div>

                        <div className="col-md-4 pr-0">
                            <table className="table table-cart bg-white shadow-2">
                                <tbody>
                                    {(employee.salaries.length === 0) && 
                                        <tr key={nanoid()}>
                                            <td colSpan={11}>
                                                <h5 className="mx-auto">User does not have any salaries</h5>
                                            </td>
                                            <td colSpan={1}>
                                                <h5 className="mx-auto">&nbsp;</h5>
                                            </td>
                                        </tr>
                                    }

                                    {employee.salaries.map((
                                        salary: {
                                            id: string;
                                            salary: number;
                                            comments: string;
                                            date: string;
                                        }) => (
                                        <tr key={nanoid()}>
                                            <td colSpan={6}>
                                                <h5>${salary.salary}</h5>
                                                <p>{salary.comments ? salary.comments : 'No comments'}</p>
                                            </td>

                                            <td colSpan={6}>
                                                <h5>{salary.date ? moment(salary.date).format('L') : 'No Date'}</h5>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/*
                            <div className="row">
                                <div className="col lh-5">Salary:</div>
                                <div className="col-auto text-center">TBD</div>
                            </div>

                            <div className="row">
                                <div className="col lh-5">Comment</div>
                                <div className="col-auto text-center">TBD</div>
                            </div>

                            <div className="row">
                                <div className="col lh-5">Disposition</div>
                                <div className="col-auto text-center">TBD</div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="section tab-pane" id="ice" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 pl-0">
                            <AddIceForm employeeId={employee.id} />
                            {/* <div className="alert alert-warning" role="alert">ICE Create Form has not been created yet</div> */}
                        </div>
                        <div className="col-md-8 pl-0 pr-0">
                            {error && <AlertMessage type="danger" text={error.message} />}

                            <table className="table table-cart bg-white shadow-2">
                                <tbody>
                                    {(employee.ice.length === 0) && 
                                        <tr key={nanoid()}>
                                            <td colSpan={11}>
                                                <h5 className="mx-auto">User does not have any emergency contacts</h5>
                                            </td>
                                            <td colSpan={1}>
                                                <h5 className="mx-auto">&nbsp;</h5>
                                            </td>
                                        </tr>
                                    }

                                    {employee.ice.map((
                                        ice: {
                                            id: string;
                                            name: string;
                                            relationship: string;
                                            email: string;
                                            phone: number;
                                        }
                                    ) => (
                                        <tr key={nanoid()}>
                                            <td colSpan={6}>
                                                <h5>{ice.name}</h5>
                                                <p>{ice.relationship}</p>
                                            </td>

                                            <td colSpan={5}>
                                                <h5>{ice.phone}</h5>
                                                <p><a href={`mailto:${ice.email}`}>{ice.email}</a></p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section tab-pane" id="reqs" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mx-auto pl-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5">Vacation Days Used</div>
                                    <div className="col-auto text-center">{vacationDaysUsed} / {employee.benefits.days_vacation || '0'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Sick Days Used</div>
                                    <div className="col-auto text-center">{sickDaysUsed} / {employee.benefits.days_sick || '0'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Personal Days Used</div>
                                    <div className="col-auto text-center">{personalDaysUsed} / {employee.benefits.days_personal || '0'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Leave of Absence Days Used</div>
                                    <div className="col-auto text-center">{loaDaysUsed}</div>
                                </div>
                            </div>

                            <AddRequestForm employeeId={employee.id} types={data.user_request_types} />
                        </div>

                        <div className="col-md-8 mx-auto pl-0 pr-0">
                            {error && <AlertMessage type="danger" text={error.message} />}

                            <div className="border bg-white shadow-2 p-2 mb-4">
                                <div className="row align-items-center">
                                    <div className="col lh-5">
                                        <a className="text-default ml-2 fw-400" href="#">Filter By Year</a>
                                        <span className="topbar-divider"></span>
                                        {years.map(year =>
                                            <span
                                                key={nanoid()}
                                                className={`badge badge-${reqYearFilter === year ? 'primary' : 'secondary'} mr-1`}
                                                onClick={() => updateYearFilter(year)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {year}
                                            </span>
                                        )}
                                    </div>

                                    <div className="col-auto">
                                        <a className="text-default mr-2 fw-400" href="#">{requests.length || 0} Requests</a>
                                    </div>
                                </div>
                            </div>

                            <table className="table table-cart bg-white shadow-2">
                                <tbody>
                                    {(requests.length === 0) && 
                                        <tr key={nanoid()}>
                                            <td colSpan={11}>
                                                <h5 className="mx-auto">User does not have any requests</h5>
                                            </td>
                                            <td colSpan={1}>
                                                <h5 className="mx-auto">&nbsp;</h5>
                                            </td>
                                        </tr>
                                    }

                                    {requests.map((
                                        req: {
                                            id: number;
                                            reviewed: boolean;
                                            approved: boolean;
                                            start: string;
                                            end: string;
                                            half_day: boolean;
                                            deny_reason: string;

                                            type: {
                                                id: number;
                                                name: string;
                                            }
                                        }) => (
                                        <tr key={nanoid()} style={{
                                            borderLeft: `${
                                                req.reviewed
                                                ? (req.approved ? '3px solid #3cd458' : '3px solid #ff4954')
                                                : '3px solid #74b4ff'
                                            }`
                                        }}>
                                            <td colSpan={3}>
                                                <h5>{req.type.name}</h5>
                                                <p>{req.reviewed ? (req.approved ? 'Approved' : 'Denied') : 'Status Pending'}</p>
                                            </td>

                                            <td colSpan={3}>
                                                <h5>{moment(req.start).format('L')}</h5>
                                                <p>{moment(req.end).format('L')}</p>
                                            </td>

                                            <td colSpan={1}>
                                                <h5>
                                                    <span className="badge badge-pale badge-secondary fw-600">
                                                        {req.half_day ? '1/2 Day' : `${Math.abs(moment(req.start).diff(moment(req.end), 'days')) + 1} days`}
                                                    </span>
                                                </h5>
                                            </td>

                                            <td colSpan={5} className="text-right">
                                                {!req.reviewed &&
                                                    <p>
                                                        <button type="button" className="btn btn-outline-success mr-2" onClick={e => approveReq(e, req.id)}>Approve</button>
                                                        <button type="button" className="btn btn-outline-danger" onClick={e => denyReq(e, req.id)}>Deny</button>
                                                    </p>
                                                }
                                            </td>

                                            <td colSpan={1}>
                                                <div className="dropdown">
                                                    <span data-toggle="dropdown" style={{ cursor: 'pointer' }}> {/* className="dropdown-toggle" */}
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4C11 4.55228 11.4477 5 12 5ZM12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13ZM12 21C12.5523 21 13 20.5523 13 20C13 19.4477 12.5523 19 12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21ZM15 4C15 5.65685 13.6569 7 12 7C10.3431 7 9 5.65685 9 4C9 2.34315 10.3431 1 12 1C13.6569 1 15 2.34315 15 4ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12ZM12 23C13.6569 23 15 21.6569 15 20C15 18.3431 13.6569 17 12 17C10.3431 17 9 18.3431 9 20C9 21.6569 10.3431 23 12 23Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </span>

                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a className="dropdown-item" href={`/employees/${employeeId}/edit-request/${req.id}`}>Edit</a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item text-danger" href="#" onClick={e => removeReq(e, req.id)}>Delete</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section tab-pane" id="docs" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        {/* TODO: Refector the component back in. Typescript issues were preventing it from working */}
                        {/* <DocumentList documents={employee.documents} /> */}

                        <div className="col-md-4 pl-0">
                            <AddDocumentForm employeeId={employee.id} />
                            {/* <div className="alert alert-warning" role="alert">Document create form not functional</div> */}
                        </div>

                        <div className="col-md-8 pl-0 pr-0">
                            {error && <AlertMessage type="danger" text={error.message} />}

                            <table className="table table-cart bg-white shadow-2">
                                <tbody>
                                    {(employee.documents.length === 0) && 
                                        <tr key={nanoid()}>
                                            <td colSpan={11}>
                                                <h5 className="mx-auto">User does not have any documents</h5>
                                            </td>
                                            <td colSpan={1}>
                                                <h5 className="mx-auto">&nbsp;</h5>
                                            </td>
                                        </tr>
                                    }

                                    {employee.documents.map((document: {
                                        id: number,
                                        name: string,
                                        url: string,
                                        date: string
                                    }) => (
                                        <tr key={nanoid()}>
                                            <td colSpan={11}>
                                                <h5>{document.name}</h5>
                                                <p><a href={document.url} target="_blank"><strong>{document.url}</strong></a></p>
                                            </td>
                                            <td colSpan={1}>
                                                <h5>{moment(document.date).format('L')}</h5>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        



                        {/*(employee.documents.length === 0) && <div className="alert alert-info" role="alert">User does not have any documents</div>}
                        
                        {employee.documents.map((document: {
                            id: number,
                            name: string,
                            url: string
                        }) => (
                            <div className="border rounded bg-white shadow-4 p-4 mb-3" key={nanoid()}>
                                <div className="row align-items-center">
                                    <div className="col lh-5">
                                        {document.name}
                                    </div>
                                    <div className="col-auto">
                                        <small className="opacity-50"><a href={document.url} target="_blank"><strong>OPEN</strong></a></small>
                                    </div>
                                </div>
                            </div>
                        ))*/}
                    </div>
                </div>
            </section>
        </div>
    </FullPage>;
};

export default EmployeePage;
