import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';

// TODO: Import components

interface IWeeklyMeetingReportPageProps extends RouteComponentProps<{}> { }

interface IState { }

class ClientContactReportPage extends React.Component<IWeeklyMeetingReportPageProps, IState> {
    // Define the constructor for the ad audit page
    constructor (props: IWeeklyMeetingReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the ad audit container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Weekly Meeting Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Weekly Meeting report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>7/30/20</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nibh velit, consequat mattis eros ac, dapibus semper diam. Vivamus sed ipsum nec arcu molestie malesuada a quis dolor. Fusce quis purus ligula. Aenean tincidunt, odio vitae efficitur facilisis, lacus libero tristique nibh, nec sollicitudin felis libero ut quam. Vivamus et facilisis tellus. Donec id interdum quam. Proin eu risus est. Aliquam ullamcorper eros eget leo ultricies lacinia. Aliquam eget dapibus massa. Fusce nulla ante, commodo nec magna id, bibendum elementum dui. Quisque nec erat in enim blandit sagittis. Nunc egestas neque quis dui ultrices, vel pulvinar turpis tempor. Integer a semper diam. Mauris facilisis varius tincidunt.
                                        </div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>08/06/20</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            Client has approved revised project timeline. Area map as been purchased and being built.
                                        </div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>08/13/20</strong>
                                            </div>
                                        </div>
                                        <div className="col-auto lh-5 text-danger">
                                            <strong>Missed Meeting</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default ClientContactReportPage;
