import gql from 'graphql-tag';

export const AddRequestMutation = gql`mutation addRequest ($user_id: String!, $type_id: Int!, $start: timestamptz!, $end: timestamptz!) {
    insert_user_requests (objects: { user_id: $user_id, type_id: $type_id, start: $start, end: $end }) {
        returning {
            id
            start
            end
            deny_reason
            reviewed
            approved

            type {
                id
                name
            }
        }
    }
}`;
