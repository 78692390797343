import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';

// TODO: Import components

interface IImageReportPageProps extends RouteComponentProps { }

interface IState { }

class ImageReportPage extends React.Component<IImageReportPageProps, IState> {
    // Define the constructor for the image report page
    constructor (props: IImageReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the image report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <a className="nav-link" href="#">Image Report</a>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                {/*
                    [-] Client Name
                    [-] Project Name
                    [-] Date Materials were received?
                    [-] Platform / vehicle used to receive materials?
                    [-] Items Received (Need a detail lists of materials received broken down by each item received.)
                    [-] Need running list of materials received (date, time, person who sent, items received) This is for after the first items were received
                */}

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        <div className="row align-items-center">
                            <div className="col-8 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Image report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>Basecamp</strong><br />
                                                <small className="text-muted">
                                                    Items Received
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-7 lh-5"><strong>Filename</strong></div>
                                                <div className="col-3 text-left"><strong>Received By</strong></div>
                                                <div className="col-2 text-left"><strong>Date</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7 lh-5">Barnstorming_Template_Lenawee_LR2.pdf</div>
                                        <div className="col-3 text-left">Amber Copley</div>
                                        <div className="col-2 text-left">08/16/17</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7 lh-5">Renewals.xlsx</div>
                                        <div className="col-3 text-left">Amber Copley</div>
                                        <div className="col-2 text-left">08/16/17</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7 lh-5">Prospects.xlsx</div>
                                        <div className="col-3 text-left">Amber Copley</div>
                                        <div className="col-2 text-left">08/17/17</div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>Dropbox</strong><br />
                                                <small className="text-muted">
                                                    Items Received
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-7 lh-5"><strong>Filename</strong></div>
                                                <div className="col-3 text-left"><strong>Received By</strong></div>
                                                <div className="col-2 text-left"><strong>Date</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7 lh-5">Visit Lenawee Logo Guidelines.pdf</div>
                                        <div className="col-3 text-left">Amber Copley</div>
                                        <div className="col-2 text-left">08/09/17</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7 lh-5">Visit Lenawee Logo.ai</div>
                                        <div className="col-3 text-left">Amber Copley</div>
                                        <div className="col-2 text-left">08/09/17</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7 lh-5">2017 Travel Guide Media Kit.pdf</div>
                                        <div className="col-3 text-left">Amber Copley</div>
                                        <div className="col-2 text-left">08/16/17</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7 lh-5">ad sizes.pdf</div>
                                        <div className="col-3 text-left">Amber Copley</div>
                                        <div className="col-2 text-left">08/02/17</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7 lh-5">63 Cover Images</div>
                                        <div className="col-3 text-left">Amber Copley</div>
                                        <div className="col-2 text-left">10/03/17</div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>Email</strong><br />
                                                <small className="text-muted">
                                                    Items Received
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default ImageReportPage;
