import React from 'react';
import { Link } from '@reach/router';
import { nanoid } from 'nanoid';

interface ICompanyProps {
    companies: any,
}

const CompanyList: React.FC<ICompanyProps> = (props) => {
    return <table className="table table-cart bg-white shadow-2">
        <tbody>
            {props.companies.map((
                company: {
                    id: string;
                    is_client: boolean;
                    lead_type: string;
                    name: string;
                    line1: string;
                    city: string;
                    state_str: string;
                    zip: string;
                    do_not_call: boolean;
                    members: number;
                    status: {
                        name: string;
                    };
                    company_type: {
                        name: string;
                    }
                }) => (
                <tr key={nanoid()} style={{ borderLeft: `${company.is_client ? '3px solid #74b4ff' : ''}` }}>
                    <td colSpan={6}>
                        <h5>
                            <Link to={`/companies/${company.id}`}>
                                {company.name}{/*<small className="badge badge-secondary">A Lead</small>*/}
                            </Link>
                        </h5>
                        <p>{(company && company.company_type && company.company_type.name) ? (company.company_type.name + `, ${company.members || '0'} members`) : 'Unknown Type'}</p>
                    </td>

                    <td colSpan={5}>
                        <h5>{company.line1 || 'No Address'}</h5>
                        <p>{company.city && company.state_str ? `${company.city}, ${company.state_str} ${company.zip}` : 'No Location Info'}</p>
                    </td>

                    <td colSpan={3}>
                        <h5>{company.status ? company.status.name : 'Unknown Status'}</h5>
                        <p>{company.do_not_call ? <strong className="text-danger">DO NOT CALL</strong> : 'No Disposition'}</p>
                    </td>
                </tr>
                // TODO: Add last contact - result, date
            ))}
        </tbody>
    </table>;
};

export default CompanyList;
