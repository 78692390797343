import React, { useState } from 'react';
import { useQuery, useMutation, Connect } from 'urql';
import gql from 'graphql-tag';
import AlertMessage from '../../components/alerts/AlertMessage';
import { auth } from '../../firebase/firebase.utils';
import { nanoid } from 'nanoid';
import { navigate } from '@reach/router';

const AddCompanyForm = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [type, setType] = useState<number>(1);
    const [status, setStatus] = useState<number>(1);
    const [state, setState] = useState<number>(36);
    const [openAfterSave, setOpenAfterSave] = useState<boolean>(false);
    const [companyAdded, setCompanyAdded] = useState<boolean>(false);

    const [result, reexecuteQuery] = useQuery({
        query: gql`
            {
                users(order_by: {name: asc}, where: {type_id: {_eq: 2}}) {
                    id
                    name
                }

                company_types(order_by: {name: asc}) {
                    id
                    name
                }

                company_statuses(order_by: {name: asc}) {
                    id
                    name
                }

                states(order_by: {name: asc}) {
                    id
                    name
                }
            }
        `,
    });

    const [addCompanyResult, executeMutation] = useMutation(gql`mutation AddCompany($id: String!, $name: String!, $email: String!, $type_id: Int!, $subtype_id: Int!, $status_id: Int!, $state_id: Int!) {
        insert_users (objects: { id: $id, name: $name, email: $email, type_id: $type_id, subtype_id: $subtype_id, status_id: $status_id, state_id: $state_id }) {
            returning {
                id
                name
            }
        }
    }`);

    const addCompany = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const firebaseResponse = await auth.createUserWithEmailAndPassword(email, 'lunarcow');
            const firebaseUid = firebaseResponse.user?.uid;

            executeMutation({
                id: firebaseUid,
                name: name,
                email: email,
                type_id: 2,
                subtype_id: type,
                status_id: status,
                state_id: state
            });

            // Set the fields to empty
            setName('');
            setEmail('');
            setType(0);
            setStatus(0);
            setState(0);

            // Check if the user selected to open after save
            if (openAfterSave) {
                // Redirect the user to the new company
                navigate(`/companies/${firebaseUid}`);
            } else {
                setCompanyAdded(true);
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const { data, fetching, error } = result;

    if (fetching) return <p>Loading...</p>;
    if (error) return <AlertMessage type="danger" text={error.message} />;

    return <form>
        <div className="cart-price">
            <p>
                <input
                    className="form-control"
                    placeholder="Company Name"
                    value={name}
                    onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                        setName(e.target.value)}
                    }
                />
            </p>

            <p>
                <input
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                        setEmail(e.target.value)}
                    }
                />
            </p>

            <p>
                <select
                    className="form-control"
                    value={type || 0}
                    onChange={(
                        e: React.ChangeEvent<HTMLSelectElement>
                    ): void => {
                        setType(parseInt(e.target.value, 10))}
                    }
                >
                    <option value="">All Company Types</option>
                    {data.company_types.map((type: { id: number; name: string; }) => (
                        <option key={nanoid()} value={type.id}>{type.name}</option>
                    ))}
                </select>
            </p>

            <p>
                <select
                    className="form-control"
                    value={status || 0}
                    onChange={(
                        e: React.ChangeEvent<HTMLSelectElement>
                    ): void => {
                        setStatus(parseInt(e.target.value, 10))}
                    }
                >
                    <option value="">All Company Statuses</option>
                    {data.company_statuses.map((status: { id: number; name: string; }) => (
                        <option key={nanoid()} value={status.id}>{status.name}</option>
                    ))}
                </select>
            </p>

            <p>
                <select
                    className="form-control"
                    value={state || 0}
                    onChange={(
                        e: React.ChangeEvent<HTMLSelectElement>
                    ): void => {
                        setState(parseInt(e.target.value, 10))}
                    }
                >
                    <option value="">All Company Statuses</option>
                    {data.states.map((state: { id: number; name: string; }) => (
                        <option key={nanoid()} value={state.id}>{state.name}</option>
                    ))}
                </select>
            </p>

            <div className="form-group input-group" style={{ border: 'none' }}>
                <div className="input-group-prepend mr-4">
                    <button
                        className="btn btn-primary"
                        onClick={(e: React.FormEvent) => addCompany(e)}
                    >
                        Add Company
                    </button>
                </div>

                <div className="custom-control custom-checkbox mt-1">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={openAfterSave}
                        onChange={() => setOpenAfterSave(!openAfterSave)}
                    />

                    <label
                        className="custom-control-label"
                        onClick={() => setOpenAfterSave(!openAfterSave)}
                    >
                        Open after save
                    </label>
                </div>
            </div>

            {(!openAfterSave && companyAdded) &&
                <div className="alert alert-success mt-3" role="alert">Success!</div>
            }
        </div>
    </form>;
};

export default AddCompanyForm;
