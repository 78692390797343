import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import ConceptFilter from '../../components/concepts/ConceptFilter';
import ConceptList from '../../components/concepts/ConceptList';

// TODO: Import components

interface IConceptListPageProps extends RouteComponentProps { }

interface IState {
    concept_sizes: [],
    concept_types: []
}

class ConceptListPage extends React.Component<IConceptListPageProps, IState> {
    // Define the constructor for the concept list page
    constructor (props: IConceptListPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the concept list container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container pl-0 pr-0">
                        <ConceptFilter />

                        {/* TODO: Add functionality and remove warning */}
                        <div className="alert alert-warning" role="alert">Concepts have not been migrated from the legacy database yet</div>

                        <ConceptList />
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default ConceptListPage;
