import React, { Suspense } from 'react';
import { RouteComponentProps } from '@reach/router';
import EmployeeContactList from '../common/EmployeeContactList';

interface IAdminDashboardProps extends RouteComponentProps {}

const AdminDashboard: React.FC<IAdminDashboardProps> = () => {
    return <div className="row">
        <div className="col-md-8 mx-auto">
            <h6 className="divider">TBD</h6>
            Admin Dashboard
        </div>

        <div className="col-md-4 mx-auto">
            <Suspense fallback={<div>Loading...</div>}>
                <EmployeeContactList />
            </Suspense>
        </div>
    </div>;
}

export default AdminDashboard;
