import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';

// TODO: Import components

interface IDesignerVarianceReportPageProps extends RouteComponentProps { }

interface IState { }

class DesignerVarianceReportPage extends React.Component<IDesignerVarianceReportPageProps, IState> {
    // Define the constructor for the designer variance report page
    constructor (props: IDesignerVarianceReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the designer variance report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Designer Variance Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        {/* TODO: Add functionality and remove warning */}
                        <div className="alert alert-warning" role="alert">Designer variance report is not yet functional</div>

                        {/*
                            [-] Forecasted Hours by Project
                            [-] Actual Hours By Project
                            [-] Variance By Project
                            [-] These reports need to be broken down by Milestone
                            [-] Each designer needs to be listed per project and broken out by milestone, the report needs to reflect if multiple designers have worked on the project.
                            [-] The report needs to be broken down by project and summarized by totals
                            [-] Reason for variance broken out by project
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default DesignerVarianceReportPage;
