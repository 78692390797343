import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';
import { useQuery } from 'urql';
import gql from 'graphql-tag';
import moment from 'moment';

import SetAppointmentList from '../../../components/employees/reports/SetAppointmentList';

interface MatchParams { id: string; }
interface MatchProps extends RouteComponentProps<MatchParams> { }

const q = gql`
    query (
        $employeeId: String!,
        $dateStart: timestamptz!,
        $dateEnd: timestamptz!
    ) {
        appointments: communications (where: {rep_id: {_eq: $employeeId }, start: {_gte: $dateStart, _lte: $dateEnd}, disposition_id: { _eq: 19 }}) {
            id
            comment
            disposition_desc
            start
            end
            comm_paid

            type {
                id
                name
            }

            disposition {
                id
                name
            }

            contact {
                id
                name
                email
            }
        }

        follow_ups: communications_aggregate(where: {rep_id: {_eq: $employeeId}, start: {_gte: $dateStart, _lte: $dateEnd}, disposition_id: {_eq: 15}}) {
            aggregate {
                count(columns: id)
            }
        }

        call_backs: communications_aggregate(where: {rep_id: {_eq: $employeeId}, start: {_gte: $dateStart, _lte: $dateEnd}, disposition_id: {_eq: 9}}) {
            aggregate {
                count(columns: id)
            }
        }
    }
`;

// class LeadGenerationReportPage extends React.Component<ILeadGenerationReportPageProps, IState> {
const LeadGenerationReportPage: React.FC<MatchProps> = (match: MatchProps) => {
    const employeeId = match.id;
    // const year = moment().year();
    const today = moment();

    // const [reqYearFilter, setReqYearFilter] = useState<number>(year);
    const [filterRange, setFilterRange] = useState<string>('month');
    const [startFilter, setStartFilter] = useState<string>(today.startOf('month').format('L'));
    const [endFilter, setEndFilter] = useState<string>(today.endOf('month').format('L'));

    console.log('employee ID: ', employeeId);
    console.log('start: ', startFilter);
    console.log('end: ', endFilter);
    // Define the constructor for the designer report page
    /*
    constructor (props: ILeadGenerationReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }
    */
    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: {
            employeeId: employeeId,
            dateStart: startFilter,//`${reqYearFilter}-01-01`,
            dateEnd: endFilter ///`${reqYearFilter}-12-31`
        }
    });

    const { data, fetching, error } = result;

    if (fetching) {
        return 'loading...';
    }

    if (error) {
        return error;
    }

    console.log('data', ' => ', data);

    const updateRange = (e: React.FormEvent, range: string) => {
        e.preventDefault();

        setFilterRange(range);

        if (range === 'day') {}
        else if (range === 'week') {}
        else if (range === 'month') {}
        else if (range === 'quarter') {}
        else if (range === 'year') {}
        else if (range === 'all') {}

        // TODO:
        // refresh();
    };

    // Render the TSX for the designer report container
    // render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Lead Generation Report for Employee Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">
                                            <span
                                                className={`badge ${filterRange === 'day' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                onClick={(e: React.FormEvent) => updateRange(e, 'day')}
                                            >
                                                Day
                                            </span>

                                            <span
                                                className={`badge ${filterRange === 'week' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                onClick={(e: React.FormEvent) => updateRange(e, 'week')}
                                            >
                                                Week
                                            </span>

                                            <span
                                                className={`badge ${filterRange === 'month' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                onClick={(e: React.FormEvent) => updateRange(e, 'month')}
                                            >
                                                Month
                                            </span>

                                            <span
                                                className={`badge ${filterRange === 'quarter' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                onClick={(e: React.FormEvent) => updateRange(e, 'quarter')}
                                            >
                                                Quarter
                                            </span>

                                            <span
                                                className={`badge ${filterRange === 'year' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                onClick={(e: React.FormEvent) => updateRange(e, 'year')}
                                            >
                                                Year
                                            </span>

                                            <span
                                                className={`badge ${filterRange === 'all' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                onClick={(e: React.FormEvent) => updateRange(e, 'all')}
                                            >
                                                All Time
                                            </span>
                                        </span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                {/*
                    [-] Leads set by day, week, month, quarter, year
                    [-] Leads ran by day, week, month, quarter, year
                    [-] Leads closed, amount of the sale that was closed, commission paid (always $1,000),
                        segmented by project type and market type. Reported by day, week, month, quarter, year
                    [x] Report on leads that are ranked A, B, C, D

                    [x] Number of events for proposal follow-up
                    [x] Number of rescheduled appointments
                    [x] Number of rescheduled appointment events
                    [x] Call back events

                    [x] Hours reported by (day, week, month, quarter, year)
                    [x] Leads per hour
                    [x] Leads set by type of industry
                */}

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 pl-0">
                                <SetAppointmentList appointments={data.appointments} />

                                {/*
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Chamber of Commerce
                                                </small>
                                            </div>
                                        </div>

                                        <div className="col-auto text-right">
                                            <div>
                                                <span className="badge badge-secondary">B Lead</span><br />
                                                <small>&nbsp;</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Chamber of Commerce
                                                </small>
                                            </div>
                                        </div>

                                        <div className="col-auto text-right">
                                            <div>
                                                <span className="badge badge-secondary">A Lead</span><br />
                                                <small>&nbsp;</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                <strong>Company Name</strong><br />
                                                <small className="text-muted">
                                                    Convention and Visitor Bureau
                                                </small>
                                            </div>
                                        </div>

                                        <div className="col-auto text-right">
                                            <div>
                                                <span className="badge badge-secondary">D Lead</span><br />
                                                <small><span className="badge badge-success">Paid $1,000 on 09/01/20</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                */}
                            </div>

                            <div className="col-md-4 pr-0">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Hours</div>
                                        <div className="col-auto text-center">18</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">CVB Leads</div>
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">CoC Leads</div>
                                        <div className="col-auto text-center">2</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Leads Per Hour</div>
                                        <div className="col-auto text-center">3</div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Sales Closed</div>
                                        <div className="col-auto text-center">3</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Amount of Sale</div>
                                        <div className="col-auto text-center">$35,000</div> {/* total revenue, by timeframe */}
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Closed CVB's</div>
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Closed CoC's</div>
                                        <div className="col-auto text-center">2</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Commission Paid</div>
                                        <div className="col-auto text-center">$3,000</div> {/* $1,000 * closed leads */}
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Proposal Follow Ups</div>
                                        <div className="col-auto text-center">{data.follow_ups.aggregate.count}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Call Backs</div>
                                        <div className="col-auto text-center">{data.call_backs.aggregate.count}</div>
                                    </div>

                                    {/*
                                    <div className="row">
                                        <div className="col lh-5">Rescheduled Appointments</div>
                                        <div className="col-auto text-center">2</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Rescheduled Events</div>
                                        <div className="col-auto text-center">8</div>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>

                        {/*
                            TODO:
                            Add a indicator on the lead block to show that it was "closed"
                            Input disposition for "Sold"
                            Input field to rank
                        */}

                        {/*
                            [-] Leads set by day, week, month, quarter, year
                            [-] Leads ran by day, week, month, quarter, year
                            [-] Leads closed, amount of the sale that was closed, commission paid (always $1,000),
                                segmented by project type and market type. Reported by day, week, month, quarter, year
                            [-] Report on leads that are ranked A, B, C, D

                            [x] Number of events for proposal follow-up
                            [x] Number of rescheduled appointments
                            [x] Number of rescheduled appointment events
                            [x] Call back events

                            [x] Hours reported by (day, week, month, quarter, year)
                            [x] Leads per hour
                            [x] Leads set by type of industry
                        */}
                    </div>
                </section>
            </FullPage>
        );
    // }
}

export default LeadGenerationReportPage;
