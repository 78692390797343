import React, { useState } from 'react';
import { useMutation } from 'urql';
import gql from 'graphql-tag';

interface IProps {
    employeeId: string;
};

const AddIceForm: React.FC<IProps> = ({ employeeId }) => {
    const [name, setName] = useState<string>('');
    const [relationship, setRelationship] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [addIceResult, executeIceMutation] = useMutation(gql`mutation addIce ($user_id: String!, $name: String!, $relationship: String!, $email: String!, $phone: String!) {
        insert_user_ice (objects: { user_id: $user_id, name: $name, relationship: $relationship, email: $email, phone: $phone }) {
            returning {
                id
                name
            }
        }
    }`);

    const addIce = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            executeIceMutation({
                user_id: employeeId,
                name,
                relationship,
                phone,
                email
            });

            // Set the fields to empty
            setName('');
            setRelationship('');
            setPhone('');
            setEmail('');
        } catch (err) {
            console.warn(err);
        }
    };

    return <form>
        <div className="border rounded shadow-2 p-4 mb-4 bg-white">
            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setName(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Relationship"
                        value={relationship}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setRelationship(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Phone"
                        value={phone}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setPhone(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setEmail(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row">
                <div className="col lh-5 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={addIce}
                    >
                        Add Contact
                    </button>
                </div>
            </div>
        </div>
    </form>;
};

export default AddIceForm;
