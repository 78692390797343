import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IContentReportPageProps extends RouteComponentProps { }

interface IState { }

class ContentReportPage extends React.Component<IContentReportPageProps, IState> {
    // Define the constructor for the content report page
    constructor (props: IContentReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the content report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Content Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                {/*
                    [-] Client Name
                    [-] Project Name
                    [-] Date Materials were received?
                    [-] Platform / vehicle used to receive materials?
                    [-] Items Received (Need a detail lists of materials received broken down by each item received.)
                    [-] Need running list of materials received (date, time, person who sent, items received) This is for after the first items were received
                */}

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        {/* TODO: Add functionality and remove warning */}
                        <div className="alert alert-warning" role="alert">Content report is not yet functional</div>

                        <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                            <div className="row align-items-center">
                                <div className="col lh-5">
                                    <div>
                                        <strong>1. Cover</strong><br />
                                        <small className="text-muted">
                                            <em className="text-warning">No Assigned Content</em>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                            <div className="row align-items-center">
                                <div className="col lh-5">
                                    <div>
                                        <strong>2. Inside Front Cover / Advertising</strong><br />
                                        <small className="text-muted">
                                            Attractions (2).docx
                                        </small>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col lh-5">
                                    <hr />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col lh-5">
                                    Playground/Discovery Garden; Buzzed bee Meadery
                                </div>
                            </div>
                        </div>

                        <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                            <div className="row align-items-center">
                                <div className="col lh-5">
                                    <div>
                                        <strong>3. Advertising</strong><br />
                                        <small className="text-muted">
                                            <em className="text-warning">No Assigned Content</em>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                            <div className="row align-items-center">
                                <div className="col lh-5">
                                    <div>
                                        <strong>4. TOC / Welcome</strong><br />
                                        <small className="text-muted">
                                            Welcome.docx; Photo credits
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                            <div className="row align-items-center">
                                <div className="col lh-5">
                                    <div>
                                        <strong>5. Photo Page</strong><br />
                                        <small className="text-muted">
                                            <em className="text-warning">No Assigned Content</em>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default ContentReportPage;
