import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface ISalesReportPageProps extends RouteComponentProps { }

interface IState { }

class SalesReportPage extends React.Component<ISalesReportPageProps, IState> {
    // Define the constructor for the sales rep report page
    constructor (props: ISalesReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the sales rep report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Sales Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem', paddingBottom: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Sales report is not yet functional</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Hours</div>
                                        <div className="col-auto text-center">320</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Sales Per Hour</div>
                                        <div className="col-auto text-center">0.8</div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Designing New</div>
                                        <div className="col-auto text-center">2</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Revised Ad</div>
                                        <div className="col-auto text-center">1</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Camera Ready</div>
                                        <div className="col-auto text-center">4</div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Records Contacted</div>
                                        <div className="col-auto text-center">85</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Left To Contact</div>
                                        <div className="col-auto text-center">144</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">DM's Contacted</div>
                                        <div className="col-auto text-center">25</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">DM's Per Hour</div>
                                        <div className="col-auto text-center">1.4</div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5"><strong>Ad Size</strong></div>
                                        <div className="col-auto text-center"><strong>Ads Sold</strong></div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">1/4 Panel</div>
                                        <div className="col-auto text-center">4</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">1/2 Page</div>
                                        <div className="col-auto text-center">8</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Full Page</div>
                                        <div className="col-auto text-center">3</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col-6 lh-5"><strong>Sales Rep</strong></div>
                                        <div className="col-2 text-left"><strong>Sales</strong></div>
                                        <div className="col-4 text-right"><strong>Avg Sale</strong></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 lh-5">Abigail Riley</div>
                                        <div className="col-2 text-left">1</div>
                                        <div className="col-4 text-right">$450</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 lh-5">Diana Riley</div>
                                        <div className="col-2 text-left">2</div>
                                        <div className="col-4 text-right">$125</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 lh-5">Christian Hinton</div>
                                        <div className="col-2 text-left">6</div>
                                        <div className="col-4 text-right">$807</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 lh-5">Marsha McKinsey</div>
                                        <div className="col-2 text-left">1</div>
                                        <div className="col-4 text-right">$597</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 lh-5"><strong>Total</strong></div>
                                        <div className="col-2 text-left"><strong>10</strong></div>
                                        <div className="col-4 text-right"><strong>$369</strong></div>
                                    </div>
                                </div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                    <div className="row">
                                        <div className="col lh-5">Renewal Revenue</div>
                                        <div className="col-auto text-center">$5,750</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">Prospect Revenue</div>
                                        <div className="col-auto text-center">$10,000</div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5"><strong>Total</strong></div>
                                        <div className="col-auto text-center"><strong>$15,750</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className="row">
                            <div className="col-md-12">
                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Advertiser</strong></div>
                                                <div className="col-1 text-left"><strong>Date</strong></div>
                                                <div className="col-2 text-left"><strong>Size</strong></div>
                                                <div className="col-2 text-left"><strong>Amount</strong></div>
                                                <div className="col-2 text-left"><strong>Rep</strong></div>
                                                <div className="col-2 text-left"><strong>&nbsp;</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5">Albella Family Restaurant</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/4 Page</div>
                                                <div className="col-2 text-left">$665</div>
                                                <div className="col-2 text-left">Abigail Riley</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Beer World</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/4 Page</div>
                                                <div className="col-2 text-left">$800</div>
                                                <div className="col-2 text-left">Marsha McKinsey</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Bethel Business Association</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/2 Page</div>
                                                <div className="col-2 text-left">$395</div>
                                                <div className="col-2 text-left">Diana Riley</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Building Traditions</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">Full Page</div>
                                                <div className="col-2 text-left">$1,000</div>
                                                <div className="col-2 text-left">Christian Hinton</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Downtown Barn</div>
                                                <div className="col-1 text-left">09/02/20</div>
                                                <div className="col-2 text-left">1/8 Page</div>
                                                <div className="col-2 text-left">$625</div>
                                                <div className="col-2 text-left">Christian Hinton</div>
                                                <div className="col-2 text-right">
                                                    {/* Payment Status / Received */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11 19V22H13V19H14C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11H13V7H15V9H17V5H13V2H11V5H10C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13H11V17H9V15H7V19H11ZM13 17H14C15.1046 17 16 16.1046 16 15C16 13.8954 15.1046 13 14 13H13V17ZM11 11V7H10C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11H11Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Artwork Received (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary mr-1" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7 7C5.34315 7 4 8.34315 4 10C4 11.6569 5.34315 13 7 13C8.65685 13 10 11.6569 10 10C10 8.34315 8.65685 7 7 7ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M3 3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6C24 4.34315 22.6569 3 21 3H3ZM21 5H3C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H7.31374L14.1924 12.1214C15.364 10.9498 17.2635 10.9498 18.435 12.1214L22 15.6863V6C22 5.44772 21.5523 5 21 5ZM21 19H10.1422L15.6066 13.5356C15.9971 13.145 16.6303 13.145 17.0208 13.5356L21.907 18.4217C21.7479 18.7633 21.4016 19 21 19Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>

                                                    {/* Release Form (collected or not, and received date) */}
                                                    <button type="button" className="btn btn-xs btn-square rounded btn-secondary" style={{ padding: 0 }}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path d="M7 18H17V16H7V18Z" fill="currentColor" />
                                                        <path d="M17 14H7V12H17V14Z" fill="currentColor" />
                                                        <path d="M7 10H11V8H7V10Z" fill="currentColor" />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*
                            Hours                  DM Contacted 20 (1.5 /hr)              Number of reps on project
                            Sales Per Hour






                            - Rep       20
                            - Rep       10
                            - Rep       6
                            - Total Sales     0

                            Renewal Revenue         $10,000
                            Prospect Revenue         $12,000

                            [-] TBD
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default SalesReportPage;
