import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IActionItemReportPageProps extends RouteComponentProps { }

interface IState { }

class ActionItemReportPage extends React.Component<IActionItemReportPageProps, IState> {
    // Define the constructor for the milestone report page
    constructor (props: IActionItemReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the milestone report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Action Item Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Action item report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Contact Name</strong><br />
                                                <small className="text-muted">
                                                    (xxx) xxx-xxxx
                                                </small>
                                                {/*</Link>*/}
                                            </div>
                                        </div>

                                        <div className="col-2 text-center">
                                            <div>
                                                <strong>Start</strong><br />
                                                <small>01/01/20</small> {/*moment(agreement.createdAt).format('L')*/}
                                            </div>
                                        </div>

                                        <div className="col-2 text-center">
                                            <div>
                                                <strong>End</strong><br />
                                                <small>11/25/20</small> {/*moment(agreement.createdAt).format('L')*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Milestone 1</strong><br />
                                                <small className="text-muted">
                                                    Ad Sales
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Action Item</strong></div>
                                                <div className="col-1 text-left"><strong>Start</strong></div>
                                                <div className="col-1 text-left"><strong>End</strong></div>
                                                <div className="col-2 text-left"><strong>Modified Date</strong></div>
                                                <div className="col-2 text-left"><strong>Days Added</strong></div>
                                                <div className="col-3 text-left"><strong>Modification Reason</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5 text-success">Barnstorming Summit</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5 text-success">Timeline</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5 text-success">Provide Logo &amp; Prospect List</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5 text-success">Rate Card</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5 text-success">Ad Sales Begin</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Milestone 2</strong><br />
                                                <small className="text-muted">
                                                    Design &amp; Layout
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Action Item</strong></div>
                                                <div className="col-1 text-left"><strong>Start</strong></div>
                                                <div className="col-1 text-left"><strong>End</strong></div>
                                                <div className="col-2 text-left"><strong>Modified Date</strong></div>
                                                <div className="col-2 text-left"><strong>Days Added</strong></div>
                                                <div className="col-3 text-left"><strong>Modification Reason</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5 text-success">Content / Images for Page Styles</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5 text-danger">Cover Concept</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5 text-warning">Cover Approval</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5 text-warning">Remaining Content and Images</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Page Styles Concept</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Page Styles Approval</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Milestone 3</strong><br />
                                                <small className="text-muted">
                                                    Full Proof
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Action Item</strong></div>
                                                <div className="col-1 text-left"><strong>Start</strong></div>
                                                <div className="col-1 text-left"><strong>End</strong></div>
                                                <div className="col-2 text-left"><strong>Modified Date</strong></div>
                                                <div className="col-2 text-left"><strong>Days Added</strong></div>
                                                <div className="col-3 text-left"><strong>Modification Reason</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5">Guide Layout</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Full Soft Proof Posted</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">First Round of Revisions</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Milestone 4</strong><br />
                                                <small className="text-muted">
                                                    Final Revisions
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Action Item</strong></div>
                                                <div className="col-1 text-left"><strong>Start</strong></div>
                                                <div className="col-1 text-left"><strong>End</strong></div>
                                                <div className="col-2 text-left"><strong>Modified Date</strong></div>
                                                <div className="col-2 text-left"><strong>Days Added</strong></div>
                                                <div className="col-3 text-left"><strong>Modification Reason</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5">Revised Proof Posted</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Second Round of Revisions</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Third Round of Revisions</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Approval to Print</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Milestone 5</strong><br />
                                                <small className="text-muted">
                                                    Printing &amp; Delivery
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Action Item</strong></div>
                                                <div className="col-1 text-left"><strong>Start</strong></div>
                                                <div className="col-1 text-left"><strong>End</strong></div>
                                                <div className="col-2 text-left"><strong>Modified Date</strong></div>
                                                <div className="col-2 text-left"><strong>Days Added</strong></div>
                                                <div className="col-3 text-left"><strong>Modification Reason</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5">Printer Hard Proof</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Press Revisions (if necessary)</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Printing</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">GoGuide Conversion</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Delivery</div>
                                                <div className="col-1 text-left">08/11/20</div>
                                                <div className="col-1 text-left">08/17/20</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-2 text-left">&nbsp;</div>
                                                <div className="col-3 text-left">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                            [x] Client Name
                            [x] Client Contact Name
                            [x] Project Name
                            [-] Date of Report
                            [x] Initial Project Start Date
                            [x] Initial Project Completion Date

                            [-] Milestone One
                            [-] List out action items for this milestone
                            [-] Action Items Completed / Date Completed
                            [-] Action Items Open

                            [-] Milestone Two
                            [-] List out action items for this milestone
                            [-] Action Items Completed / Date Completed
                            [-] Action Items Open

                            [-] Milestone Three
                            [-] List out action items for this milestone
                            [-] Action Items Completed / Date Completed
                            [-] Action Items Open

                            [-] Milestone Four
                            [-] List out action items for this milestone
                            [-] Action Items Completed / Date Completed
                            [-] Action Items Open

                            [-] Milestone Five
                            [-] List out action items for this milestone
                            [-] Action Items Completed / Date Completed
                            [-] Action Items Open
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default ActionItemReportPage;
