import React, { useState } from 'react';
import { Link, navigate, RouteComponentProps } from '@reach/router'; // match
import { useQuery, useMutation } from 'urql';
import ls from 'local-storage';
import FullPage from '../../layouts/FullPage';
import gql from 'graphql-tag';
import AlertMessage from '../../components/alerts/AlertMessage';
import AddContactForm from '../../components/company/AddContactForm';
import AddProjectForm from '../../components/company/AddProjectForm';
// import AddDispositionForm from '../../components/company/dispositions/AddDispositionForm';
// import moment from 'moment';
import moment from 'moment-timezone';
import { nanoid } from 'nanoid';

// import { Accordion, Dropdown } from 'react-bootstrap';
// import SelectableContext from 'react-bootstrap/SelectableContext';

interface MatchParams { id: string; }
interface MatchProps extends RouteComponentProps<MatchParams> { }

const q = gql`
    query($userId: String!) {
        users(where: {id: {_eq: $userId}}) {
            city
            comments
            do_not_call
            email
            ext
            fax
            id
            is_client
            lead_type
            line1
            line2
            name
            phone
            toll_free
            url
            zip

            state {
                id
                name
                abbr
            }

            company_type {
                id
                name
            }

            status {
                id
                name
            }

            projects(order_by: {year: desc}) {
                id
                name
                year
                type_id
            }

            contacts {
                id
                name
                title
                primary
                phone
                email
                mobile
                ext

                communications (order_by: {start: desc}) {
                    id
                    contact_id
                    type_id
                    type_id_old
                    disposition_id_old
                    disposition_desc
                    rep_id
                    start
                    end
                    comment

                    disposition {
                        id
                        name
                    }

                    type {
                        id
                        name
                    }
                }

                events (order_by: {time: desc}) {
                    id
                    employee_id_old
                    type_id
                    type_id_old
                    time
                    desc

                    type {
                        id
                        name
                    }
                }
            }
        }

        latest_contacts: contacts (where: {customer_old_id: {_eq: $userId}}) {
            id
            name

            communications(order_by: {start: desc}, limit: 1) {
                id
                comment
                disposition_desc
                start

                disposition {
                    id
                    name
                }
            }
        }

        communication_dispositions(order_by: {name: asc}) {
            id
            name
        }

        communication_types(order_by: {name: asc}) {
            id
            name
        }

        event_types(order_by: {name: asc}) {
            id
            name
        }
    }
`;

const CompanyPage: React.FC<MatchProps> = (match: MatchProps) => {
    const userId = match.id;

    const [eventTypeId, setEventTypeId] = useState<string>('');
    const [eventTime, setEventTime] = useState<string>('');
    const [eventDesc, setEventDesc] = useState<string>('');
    const [eventAdded, setEventAdded] = useState<boolean>(false);

    const [communicationTypeId, setCommunicationTypeId] = useState<number>(0);
    const [communicationComment, setCommunicationComment] = useState<string>('');
    const [communicationDispositionId, setCommunicationDispositionId] = useState<number>(0);
    const [communicationAdded, setCommunicationAdded] = useState<boolean>(false);

    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: { userId }
    });

    const [addDispositionResult, executeDispositionMutation] = useMutation(gql`mutation AddCommunication ($id: String!, $contact_id: String!, $rep_id: String!, $type_id: Int!, $start: timestamptz!, $end: timestamptz!, $comment: String!, $disposition_id: Int!, $disposition_desc: String!) {
        insert_communications (objects: { id: $id, contact_id: $contact_id, rep_id: $rep_id, type_id: $type_id, start: $start, end: $end, comment: $comment, disposition_id: $disposition_id, disposition_desc: $disposition_desc }) {
            returning {
                id
                contact_id
                rep_id
                type_id
                start
                end
                comment
                disposition_id
                disposition_desc
            }
        }
    }`);

    const [addEventResult, executeEventMutation] = useMutation(gql`mutation AddEvent ($type_id: Int!, $employee_id: String!, $contact_id: String!, $client_id: String!, $time: String!, $desc: String!) {
        insert_events (objects: { type_id: $type_id, employee_id: $employee_id, contact_id: $contact_id, client_id: $client_id, time: $time, desc: $desc }) {
            returning {
                id
                type_id
                employee_id
                contact_id
                client_id
                time
                desc
            }
        }
    }`);

    const { data, fetching, error } = result;

    if (fetching) {
        return <FullPage>
            <div className="row gap-y">
                <div className="col-12 col-lg-2">
                    Loading...
                </div>
            </div>
        </FullPage>;
    }

    // console.log('data', ' => ', data);

    if (error) return <AlertMessage type="danger" text={error.message} />;

    const company = data.users[0];
    const latestContact = data.latest_contacts ? data.latest_contacts[0] : null;//[0].communications[0];
    const latestConnection = latestContact ? latestContact.communications[0] : null;

    // console.log('comm', ' => ', latestContact);

    /*
    const [addDispositionResult, executeAddDisposition] = useMutation(gql`mutation addDisposition($id: String!, $name: String!, $email: String!, $type_id: Int!, $subtype_id: Int!, $status_id: Int!) {
        insert_users (objects: { id: $id, name: $name, email: $email, type_id: $type_id, subtype_id: $subtype_id, status_id: $status_id }) {
            returning {
                id
                name
            }
        }
    }`);

    const addDisposition = (
        e: React.FormEvent,
        contact_id: string,
        rep_id: string,
        type_id: number,
        start: string,
        end: string,
        comment: stringf
    ) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            executeAddDisposition({
                contact_id,
                rep_id,
                type_id,
                start,
                end,
                comment

                // name: name,
                // type_id: 2,
                // comment: notes
            });

            // Set the fields to empty
            // setName('');
            // setType(0);
            // setNotes('');

            // Add the new disposition into the array
            // TODO:
        } catch (err) {
            console.warn(err);
        }
    };
    */

    if (!company) {
        return <FullPage>
            <section className="section" style={{ paddingTop: '1rem' }}>
                <div className="container">
                    <AlertMessage type="danger" text="Company not found" />
                </div>
            </section>
        </FullPage>;
    }

    const addCommunication = (e: React.FormEvent, contactId: string) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            const now = moment();

            executeDispositionMutation({
                id: nanoid(),
                contact_id: contactId,
                rep_id: ls.get('uid'),
                type_id: communicationTypeId,
                start: moment(now).tz("America/New_York").format(),
                end: moment(now).tz("America/New_York").format(),
                comment: communicationComment,
                disposition_id: communicationDispositionId,
                disposition_desc: communicationComment
            });

            // Clear the form inputs
            setCommunicationTypeId(0);
            setCommunicationComment('');
            setCommunicationDispositionId(0);

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setCommunicationAdded(true);
        } catch (err) {
            console.warn(err);
        }
    };

    const addEvent = (e: React.FormEvent, contactId: string,) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            executeEventMutation({
                type_id: eventTypeId,
                employee_id: ls.get('uid'),
                contact_id: contactId,
                client_id: company.id,
                time: eventTime,
                desc: eventDesc
            });

            // NOTE: Format the time as such, if possible: YYYY-MM-DD HH:MM:SS

            // Clear the form inputs
            setEventTypeId('');
            setEventTime('');
            setEventDesc('');

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setEventAdded(true);
        } catch (err) {
            console.warn(err);
        }
    };

    const editContact = (e: React.FormEvent, id: string) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // setEditingContact(true);
    };

    const saveContactData = (e: React.FormEvent, id: string) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // Reset the contact ID to zero
        // setEditingContact(0);
    };

    return <FullPage>
        <section className="section" style={{ paddingTop: '1rem' }}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                    <div className="container d-stick-none">
                        <div className="row">
                            <div className="col-8">
                                <div className="nav nav-navbar">
                                    <span className="nav-link active">
                                        {company.name}
                                        <span className="ml-5"><small><Link to={`/companies/${company.id}/edit`} className="badge badge-secondary fw-700">EDIT</Link></small></span>
                                    </span>
                                </div>
                            </div>

                            <div className="col-4 ml-auto">
                                <nav className="nav nav-navbar justify-content-end">
                                    <span className="nav-link">{company.company_type.name}</span>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <hr className="my-0 w-100" />

                    <div className="container">
                        <section className="navbar-mobile">
                            <div className="nav nav-navbar mr-auto">
                                <a href="#info" className="nav-link active" data-toggle="tab">Info</a>
                                <a href="#contacts" className="nav-link" data-toggle="tab">Contacts</a>
                                <a href="#projects" className="nav-link" data-toggle="tab">Projects</a>
                                {/* <a href="#events" className="nav-link" data-toggle="tab">Events</a> */}
                                {/* <a href="#communications" className="nav-link" data-toggle="tab">Communications</a> */}

                                {/* TODO: Create history functionality for actions taken on a company record */}
                                {/* <a href="#history" className="nav-link active" data-toggle="tab">History</a> */}
                            </div>

                            <nav className="nav nav-navbar ml-lg-5">
                                <li className="nav-item">
                                    <span className="nav-link">{company.status.name}</span>
                                </li>
                            </nav>
                        </section>
                    </div>
                </nav>
            </div>
        </section>

        <div className="tab-content p-4">
            <section className="section tab-pane show active" id="info" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 pl-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5">Website</div>
                                    {company.url && <div className="col-auto text-center"><a href={company.url} target="_blank">{company.url}</a></div>}
                                    {!company.url && <div className="col-auto text-center">None</div>}
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Email</div>
                                    <div className="col-auto text-center">{company.email || 'None'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Phone</div>
                                    <div className="col-auto text-center">{company.phone || 'None'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Toll Free</div>
                                    <div className="col-auto text-center">{company.toll_free || 'None'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Fax</div>
                                    <div className="col-auto text-center">{company.fax || 'None'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 pl-0 pr-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5">Line 1</div>
                                    <div className="col-auto text-center">{company.line1 || 'None'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Line 2</div>
                                    <div className="col-auto text-center">{company.line2 || 'None'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">City</div>
                                    <div className="col-auto text-center">{company.city || 'None'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">State</div>
                                    <div className="col-auto text-center">{company.state.name || 'None'}</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Zip</div>
                                    <div className="col-auto text-center">{company.zip || 'None'}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pr-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                {latestConnection ? (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col lh-5"><strong>Last Contacted</strong></div>
                                            <div className="col-auto text-center">{moment(latestConnection.start).format('L')}</div>
                                        </div>

                                        <div className="row">
                                            <div className="col lh-5"><strong>Disposition</strong></div>
                                            <div className="col-auto text-center">{(latestConnection.disposition && latestConnection.disposition.name) ? latestConnection.disposition.name : 'None'}</div>
                                        </div>

                                        <div className="row">
                                            <div className="col lh-5">
                                                <strong>Comment</strong><br />
                                                {latestConnection.comment || 'None'}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col lh-5">
                                                <strong>Disposition Desc</strong><br />
                                                {latestConnection.disposition_desc || 'None'}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div className="row">
                                        <div className="col lh-5">No latest connection</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section tab-pane" id="contacts" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 pl-0">
                            <AddContactForm companyId={company.id} />
                        </div>
                        <div className="col-md-8 pl-0 pr-0">
                            {/*company.contacts.map((
                                contact: {
                                    id: string;
                                    name: string;
                                    title: string;
                                    email: string;
                                    phone: string;
                                    mobile: string;

                                    communications: [{
                                        id: number;
                                        contact_id: number;
                                        comment: string;
                                        start: string;

                                        disposition: {
                                            name: string;
                                        }

                                        type: {
                                            name: string;
                                        }
                                    }];

                                    events: [{
                                        id: number;
                                        employee_id_old: string;
                                        type_id: number;
                                        type_id_old: number;
                                        time: string;
                                        desc: string;

                                        type: {
                                            name: string;
                                        }
                                    }];
                                }
                            ) => (
                                <Accordion>
                                    <Accordion.Toggle eventKey={contact.id}>{contact.name}</Accordion.Toggle>
                                    <Accordion.Collapse>
                                        <SelectableContext.Provider value={false}>
                                            <Dropdown>
                                                <Dropdown.Toggle>Title</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item>Item1</Dropdown.Item>
                                                    <Dropdown.Item>Item2</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </SelectableContext.Provider>
                                    </Accordion.Collapse>
                                </Accordion>
                            ))*/}

                            <div className="accordion accordion-light" id="contact-list">
                                {company.contacts.map((
                                    contact: {
                                        id: string;
                                        name: string;
                                        title: string;
                                        email: string;
                                        phone: string;
                                        mobile: string;

                                        communications: [{
                                            id: number;
                                            contact_id: number;
                                            comment: string;
                                            start: string;

                                            disposition: {
                                                name: string;
                                            }

                                            type: {
                                                name: string;
                                            }
                                        }];

                                        events: [{
                                            id: number;
                                            employee_id_old: string;
                                            type_id: number;
                                            type_id_old: number;
                                            time: string;
                                            desc: string;

                                            type: {
                                                name: string;
                                            }
                                        }];
                                    }
                                ) => (
                                    <div className="card" key={`contact-${contact.id}`}>
                                        <h5 className="card-title">
                                            <a
                                                className="collapsed"
                                                data-toggle="collapse"
                                                href={`#contact-${contact.id}`}
                                            >
                                                {contact.name}

                                                {contact.title && <span className="mb-0 text-muted" style={{ float: 'right' }}>
                                                    {contact.title}
                                                </span>}
                                            </a>
                                        </h5>

                                        <div id={`contact-${contact.id}`}  className="collapse" data-parent="#contact-list">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <strong>Email:</strong> {contact.email || <span className="text-danger">None</span>}<br />
                                                        <strong>Phone:</strong> {contact.phone || 'None'}<br />
                                                        <strong>Mobile:</strong> {contact.mobile || 'None'}<br />
                                                        {/* <button className="btn btn-success" onClick={() => navigate(`/contacts/edit/${contact.id}`)}>Edit Info</button> */}
                                                        <Link className="btn btn-success" to={`/contacts/${contact.id}/edit`}>Edit Info</Link>
                                                    </div>
                                                </div>

                                                {/*
                                                    <p>
                                                        <input
                                                            className="form-control"
                                                            placeholder="Event Time"
                                                            value={eventTime || ''}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setEventTime(e.target.value)
                                                        }/>
                                                    </p>
                                                    <p>
                                                        <textarea
                                                            className="form-control"
                                                            value={eventDesc || ''}
                                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => setEventDesc(e.target.value)}
                                                        />
                                                    </p>
                                                    <p>
                                                        <button
                                                            className="btn btn-success btn-block"
                                                            onClick={(e: React.FormEvent) => addEvent(e, contact.id)}
                                                            // onClick={e => this.addConnection(e, prospect.id)}
                                                        >
                                                            Create Event
                                                        </button>
                                                    </p>
                                                */}

                                                {contact.email &&
                                                    <>
                                                        <div className="row">
                                                            <div className="col lh-5">
                                                                <hr />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-9">
                                                                <p>
                                                                    <select
                                                                        className="form-control"
                                                                        // value={newConnectionType || ''}
                                                                        // onChange={e => { e.preventDefault(); this.setState({ newConnectionType: e.target.value })}}
                                                                    >
                                                                        <option value="">-- Select Boilerplate --</option>
                                                                        <option value="1">Google Meet Invitation Follow Up</option>
                                                                        <option value="2">Discovery Meeting Follow Up</option>
                                                                        <option value="3">Day of Meeting Follow Up</option>
                                                                        <option value="4">Additional Info Follow Up</option>
                                                                        <option value="5">Proposal Follow Up</option>
                                                                        <option value="6">Follow Up Introduction</option>
                                                                    </select>
                                                                </p>
                                                            </div>

                                                            <div className="col-3">
                                                                <button
                                                                    className="btn btn-success btn-block"
                                                                    // onClick={e => this.addConnection(e, prospect.id)}
                                                                >
                                                                    Send Email
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                <div className="row">
                                                    <div className="col lh-5">
                                                        <hr />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-4">
                                                        {/*
                                                        <AddDispositionForm
                                                            contact_id={contact.id}
                                                            types={data.communication_types}
                                                            dispositions={data.communication_dispositions}
                                                            addDisposition={addDisposition}
                                                        />
                                                        */}

                                                        <p>
                                                            <select
                                                                className="form-control"
                                                                value={communicationTypeId || ''}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCommunicationTypeId(parseInt(e.target.value, 10))}
                                                                // onChange={e => { e.preventDefault(); this.setState({ newConnectionType: e.target.value })}}
                                                            >
                                                                <option value="">-- Select Type --</option>
                                                                {data.communication_types.map((
                                                                    type: {
                                                                        id: number;
                                                                        name: string;
                                                                    }
                                                                ) => (
                                                                    <option key={nanoid()} value={type.id}>{type.name}</option>
                                                                ))}
                                                                {/*
                                                                <option value="Outbound Call">Outbound Call</option>
                                                                <option value="Outbound Email">Outbound Email</option>
                                                                */}
                                                            </select>
                                                        </p>
                                                        <p>
                                                            <select
                                                                className="form-control"
                                                                value={communicationDispositionId || ''}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setCommunicationDispositionId(parseInt(e.target.value, 10));
                                                                }}
                                                                // onChange={e => { e.preventDefault(); this.setState({ newConnectionDisposition: e.target.value })}}
                                                            >
                                                                <option value="">-- Select Disposition --</option>
                                                                {data.communication_dispositions.map((
                                                                    disposition: {
                                                                        id: number;
                                                                        name: string;
                                                                    }
                                                                ) => (
                                                                    <option key={disposition.id} value={disposition.id}>{disposition.name}</option>
                                                                ))}

                                                                {/* <option value="Spoke With Them">Spoke With Them</option> */}
                                                                {/* <option value="Voicemail">Voicemail</option> */}
                                                                {/* <option value="Not Interested">Not Interested</option> */}

                                                                {/*
                                                                <option value="Email">Email</option>
                                                                <option value="Sale">Sale</option>
                                                                */}
                                                            </select>
                                                        </p>
                                                        <p>
                                                            <textarea
                                                                className="form-control"
                                                                value={communicationComment || ''}
                                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => { e.preventDefault(); setCommunicationComment(e.target.value); }}
                                                            />
                                                        </p>
                                                        <p>
                                                            <button
                                                                className="btn btn-success btn-block"
                                                                onClick={(e: React.FormEvent) => { e.preventDefault(); addCommunication(e, contact.id); }}
                                                                // onClick={e => this.addConnection(e, prospect.id)}
                                                            >
                                                                Disposition Record
                                                            </button>
                                                        </p>
                                                        
                                                        {communicationAdded && <div className="alert alert-success" role="alert">Success!</div>}
                                                    </div>

                                                    <div className="col-8">
                                                        {!contact.communications && <span className="text-warning">No Communications</span>}

                                                        {/*
                                                        <ul>
                                                            {contact.communications.map((communication: { comment: string; }) => (
                                                                <li>{communication.comment}</li>
                                                            ))}
                                                        </ul>
                                                        */}

                                                        {contact.communications && contact.communications.map((
                                                            communication: {
                                                                comment: string;
                                                                start: string;

                                                                disposition: {
                                                                    name: string;
                                                                }

                                                                type: {
                                                                    name: string;
                                                                }
                                                            }
                                                        ) => (
                                                            <div key={nanoid()} className="media align-items-center pb-2">
                                                                <div className="media-body lh-4">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h6 className="mb-0">
                                                                                {(communication.type && communication.type.name) ? communication.type.name : 'Unkown Type'}, {communication.disposition ? communication.disposition.name : 'Unknown Disposition'}
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <h6 className="mb-0">{moment(communication.start).format('MM/DD/YYYY[, ]LT')}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p className="text-light mb-0 small-2">{communication.comment || 'No Comment'}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col lh-5">
                                                        <hr />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-4">
                                                        <p>
                                                            <select
                                                                className="form-control"
                                                                value={eventTypeId || ''}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { e.preventDefault(); setEventTypeId(e.target.value); }}
                                                                // onChange={e => { e.preventDefault(); this.setState({ newConnectionType: e.target.value })}}
                                                            >
                                                                <option value="">-- Select Type --</option>
                                                                {data.event_types.map((
                                                                    event_type: {
                                                                        id: number;
                                                                        name: string;
                                                                    }
                                                                ) => (
                                                                    <option key={nanoid()} value={event_type.id}>{event_type.name}</option>
                                                                ))}
                                                            </select>
                                                        </p>
                                                        <p>
                                                            <input
                                                                className="form-control"
                                                                placeholder="Event Time"
                                                                value={eventTime || ''}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => { e.preventDefault(); setEventTime(e.target.value); }
                                                            }/>
                                                        </p>
                                                        <p>
                                                            <textarea
                                                                className="form-control"
                                                                value={eventDesc || ''}
                                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => { e.preventDefault(); setEventDesc(e.target.value); }}
                                                            />
                                                        </p>
                                                        <p>
                                                            <button
                                                                className="btn btn-success btn-block"
                                                                onClick={(e: React.FormEvent) => addEvent(e, contact.id)}
                                                                // onClick={e => this.addConnection(e, prospect.id)}
                                                            >
                                                                Create Event
                                                            </button>
                                                        </p>

                                                        {eventAdded && <div className="alert alert-success" role="alert">Success!</div>}
                                                    </div>

                                                    <div className="col-8">
                                                        {!contact.events && <span className="text-warning">No Events</span>}

                                                        {contact.events && contact.events.map((
                                                            event: {
                                                                type_id: number;
                                                                type_id_old: number;
                                                                time: string;
                                                                desc: string;

                                                                type: {
                                                                    name: string;
                                                                }
                                                            }
                                                        ) => (
                                                            <div key={nanoid()} className="media align-items-center pb-2">
                                                                <div className="media-body lh-4">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h6 className="mb-0">{event.type.name || 'Unknown Type'}</h6>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <h6 className="mb-0">{moment(event.time).format('MM/DD/YYYY[, ]LT')}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <p className="text-light mb-0 small-2">{event.desc || 'No Description'}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/* TODO: Show card/message if no contacts exist */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section tab-pane" id="projects" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 pl-0">
                            <AddProjectForm clientId={userId} />
                        </div>
                        <div className="col-md-8 pl-0 pr-0">
                            <div className="accordion accordion-light" id="project-list">
                                {company.projects.map((
                                    project: {
                                        id: string;
                                        name: string;
                                    }
                                ) => (
                                    <div className="card">
                                        <h5 className="card-title">
                                            <a className="collapsed" data-toggle="collapse" href={`#contact-${project.id}`} >{project.name}</a>
                                        </h5>

                                        <div id={`contact-${project.id}`}  className="collapse" data-parent="#project-list">
                                            <div className="card-body">
                                                <Link to={`/projects/${project.id}`} className="btn btn-primary">Open Project</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/* TODO: Show card/message if no contacts exist */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*
            <section className="section tab-pane" id="events" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    Events Tab
                </div>
            </section>

            <section className="section tab-pane" id="communications" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    Communications Tab
                </div>
            </section>
            */}
        </div>
    </FullPage>;
};

export default CompanyPage;
