import React, { Component, ReactNode } from 'react';
import HeaderBar from '../components/common/HeaderBar';

interface IProps {
    children: ReactNode
}

export class FullPage extends Component<IProps> {
    render () {
        return <>
            {/*
            <section className="topbar d-lg-flex">
                <div className="container small-3">
                    <nav className="nav">
                        <a className="nav-link" href="#">Link 1</a>
                        <a className="nav-link" href="#">Link 2</a>
                        <a className="nav-link" href="#">Link 3</a>
                    </nav>

                    <div>
                        Some Data
                        <span className="topbar-divider"></span>
                        dev@lunarcow.com
                    </div>
                </div>
            </section>
            */}

            {/* NOTE: If topbar is set, HeaderBar needs to have marginTop: 40px applied */}

            <HeaderBar />

            <main className="main-content">
                {this.props.children}
            </main>
        </>
    }
}

export default FullPage;
