import React, { useState } from 'react';
import { useMutation } from 'urql';
import gql from 'graphql-tag';
import moment from 'moment';
import { nanoid } from 'nanoid';

interface IProps {
    clientId: string | undefined;
};

const AddProjectForm: React.FC<IProps> = ({ clientId }) => {
    const [name, setName] = useState<string>('');
    const [year, setYear] = useState<number>(moment().year() + 1);

    const [addProjectResult, executeProjectMutation] = useMutation(gql`mutation addProject ($id: String!, $name: String!, $client_id: String!, $year: Int!) {
        insert_projects (objects: { id: $id, name: $name, client_id: $client_id, year: $year }) {
            returning {
                id
                name
            }
        }
    }`);

    const addProject = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            executeProjectMutation({
                id: nanoid(),
                client_id: clientId,
                name,
                year,
            });

            // Set the fields to empty
            setName('');
            setYear(moment().year() + 1);
        } catch (err) {
            console.warn(err);
        }
    };

    return <form>
        <div className="border rounded shadow-2 p-4 mb-4 bg-white">
            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Project Name"
                        value={name}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setName(e.target.value)}
                        }
                    />
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <input
                        className="form-control"
                        placeholder="Year"
                        value={year}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setYear(parseInt(e.target.value, 10))}
                        }
                    />
                </div>
            </div>

            <div className="row">
                <div className="col lh-5 text-right">
                    <button
                        className="btn btn-primary"
                        onClick={addProject}
                    >
                        Add Project
                    </button>
                </div>
            </div>
        </div>
    </form>;
};

export default AddProjectForm;
