import React, { Suspense, useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import moment from 'moment';
import EmployeeContactList from '../common/EmployeeContactList';
import ls from 'local-storage';
// import nanoid from 'nanoid';

import { useQuery } from 'urql';
import gql from 'graphql-tag';
import { nanoid } from 'nanoid';
// import ContactPage from '../../containers/contacts/ContactPage';

const q = gql`
    query ($employeeId: String!) {
        communications (where: {rep_id: {_eq: $employeeId}, start: {_lte: "2020-11-26", _gte: "2020-11-20"}}) {
            id
            disposition_desc
            start
            comment

            contact {
                id
                name
                title
            }

            type {
                id
                name
            }

            disposition {
                id
                name
            }
        }

        events (where: {employee_id: {_eq: $employeeId}, time: {_lte: "2020-11-26", _gte: "2020-11-20"}}) {
            id
            time
            desc

            type {
                id
                name
            }

            company {
                id
                name
            }

            contact {
                id
                name
                phone
                title
                email
            }
        }
    }
`;

interface IAdSalesDashboardProps extends RouteComponentProps {}

const AdSalesDashboard: React.FC<IAdSalesDashboardProps> = () => {
    const [salesIncentive, setSalesIncentive] = useState<string>('');
    const [salesIncentiveSeen, setSalesIncentiveSeen] = useState<boolean>(false);

    const [showCommunications, setShowCommunications] = useState<boolean>(false);

    const [showTeamProductivity, setShowTeamProductivity] = useState<boolean>(false);
    const [filterMyProductivityWeekly, setFilterMyProductivityWeekly] = useState<boolean>(false);
    const [filterTeamProductivityWeekly, setFilterTeamProductivityWeekly] = useState<boolean>(false);

    const [hidePastEvents, setHidePastEvents] = useState<boolean>(false);
    const [hideFutureEvents, setHideFutureEvents] = useState<boolean>(false);

    const [dayFilter, setDayFilter] = useState<string>('Today');
    const [dayFilterQuery, setDayFilterQuery] = useState<string>(moment().format('YYYY-MM-DD'));

    // const today = moment().format('YYYY-MM-DD');

    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: {
            employeeId: ls.get('uid'),
            start: dayFilterQuery,
            end: dayFilterQuery
        }
    });

    const { data, fetching, error } = result;

    if (fetching) {
        return <div className="row gap-y">
            <div className="col-12 col-lg-2">
                Loading...
            </div>
        </div>;
    }

    console.log('events', ' => ', data.events);
    console.log('communications', ' => ', data.communications);

    // Refetch the query and skip the cache
    const refresh = () => reexecuteQuery({ requestPolicy: 'cache-and-network' });

    // if (error) return <AlertMessage type="danger" text={error.message} />;

    const updateDayFilter = (e: React.FormEvent, day: string) => {
        e.preventDefault();

        // Update the day filter
        setDayFilter(day);

        if (day === 'Yesterday') { // Subtract one day for yesterday
            setDayFilterQuery(moment().subtract(1, 'day').format('YYYY-MM-DD'));
        } else if (day === 'Tomorrow') { // Add one day for tomorrow
            setDayFilterQuery(moment().add(1, 'day').format('YYYY-MM-DD'));
        }

        // Otherwise set the day to today
        setDayFilterQuery(moment().format('YYYY-MM-DD'));

        // Update the cache / requery
        refresh();
    };

    // Mark the incentive seen for a user
    const markIncentiveSeen = () => {};

    // Return the appropriate color for the event time badge
    const eventTimeColor = (t: string) => {
        const now = moment();
        const thirtyMin = moment().add(30, 'minutes');
        const midnight = moment().endOf('day');
        const eventTime = moment(t);

        if (eventTime.isBetween(now, thirtyMin)) {
            return 'badge-warning';
        } else if (eventTime.isBetween(now, midnight)) {
            return 'badge-success';
        } else {
            return 'badge-secondary';
        }
    };

    return (
        <React.Fragment>
            {(salesIncentive && !salesIncentiveSeen) &&
                <div className="row">
                    <div className="col-md-12 mx-md-auto">
                        <div className="alert alert-primary alert-dismissible fade show" role="alert">
                            {salesIncentive}

                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true" onClick={markIncentiveSeen}>×</span>
                            </button>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-md-12 mx-md-auto">
                    <div className="alert alert-info" role="alert">
                        Today, you need to spend 2 hours in Fenton, 1 hour in Keweenaw, and 5 hours in Canton-Stark
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 mx-auto">
                    <h6 className="divider">
                        <a
                            href=""
                            onClick={e => { e.preventDefault(); setShowCommunications(!showCommunications)}}
                        >
                            {showCommunications ? 'Communications' : 'Events'}
                        </a>
                    </h6>

                    {!showCommunications &&
                        <div>
                            <div className="border rounded bg-white p-2 mb-4">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                    </div>
                                    <div className="col lh-5">
                                        <a className="text-default fw-400" href="#">Filters</a>
                                        <span className="topbar-divider"></span>
                                        <span
                                            className={`badge ${dayFilter === 'Yesterday' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                            onClick={(e: React.FormEvent) => updateDayFilter(e, 'Yesterday')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Yesterday
                                        </span>
                                        <span
                                            className={`badge ${dayFilter === 'Today' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                            onClick={(e: React.FormEvent) => updateDayFilter(e, 'Today')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Today
                                        </span>
                                        <span
                                            className={`badge ${dayFilter === 'Tomorrow' ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                            onClick={(e: React.FormEvent) => updateDayFilter(e, 'Tomorrow')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Tomorrow
                                        </span>
                                    </div>

                                    <div className="col-auto">
                                        <small className="opacity-65">
                                            <a
                                                href=""
                                                className={`badge ${hidePastEvents ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                onClick={e => { e.preventDefault(); setHidePastEvents(!hidePastEvents)}}
                                            >
                                                Show Past
                                            </a>

                                            <a
                                                href=""
                                                className={`badge ${hideFutureEvents ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                onClick={e => { e.preventDefault(); setHideFutureEvents(!hideFutureEvents)}}
                                            >
                                                Show Future
                                            </a>
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion accordion-light" id="event-list">
                                {/*contacts.map(contact => (
                                    <div className="card" key={nanoid()}>
                                        <h5 className="card-title">
                                            <a data-toggle="collapse" href={`#contact-${contact.id}`} className="collapsed" aria-expanded="false">{contact.name}</a>
                                        </h5>

                                        <div id={`contact-${contact.id}`} className="collapse" data-parent="#contact-list">
                                            <div className="card-body">
                                                TBD
                                            </div>
                                        </div>
                                    </div>
                                ))*/}

                                {data.events.map((ev: any) => (
                                    <div className="card">
                                        <div className="card-img-top">
                                            <div className="badges badges-right">
                                                <span className="badge badge-primary mr-1">{ev.type.name || 'Unknown type'}</span>
                                                <span className={`badge ${eventTimeColor(moment(ev.time).format('MM/DD/YYYY LT'))}`}>{moment(ev.time).format('MM/DD/YYYY LT')}</span>
                                            </div>
                                        </div>

                                        <h5 className="card-title">
                                            <a className="collapsed" data-toggle="collapse" href="#event-four">
                                                Holiday Inn Tampa Airport Westshore
                                            </a>
                                        </h5>

                                        <div id="event-four" className="collapse" data-parent="#event-list">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        Name, Title<br />
                                                        Email<br />
                                                        Phone
                                                    </div>
                                                    <div className="col-md-6">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/*
                                <div className="card">
                                    <div className="card-img-top">
                                        <div className="badges badges-right">
                                            <span className="badge badge-primary mr-1">Ad Sale Call Back</span>
                                            <span className={`badge ${eventTimeColor('6/2/2020 4:55 PM')}`}>6/2/2020 @ 4:55 PM</span>
                                        </div>
                                    </div>

                                    <h5 className="card-title">
                                        <a className="collapsed" data-toggle="collapse" href="#event-three">
                                            Home Care Assistance of South Tampa
                                        </a>
                                    </h5>

                                    <div id="event-three" className="collapse" data-parent="#event-list">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    Name, Title<br />
                                                    Email<br />
                                                    Phone<br /><br />
                                                    Address Line1, Line 2<br />
                                                    City, State Zip
                                                </div>
                                                <div className="col-md-6">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-img-top">
                                        <div className="badges badges-right">
                                            <span className="badge badge-primary mr-1">Phone Discovery Meeting</span>
                                            <span className={`badge ${eventTimeColor('6/2/2020 3:03 PM')}`}>6/2/2020 @ 3:03 PM</span>
                                        </div>
                                    </div>

                                    <h5 className="card-title">
                                        <a className="collapsed" data-toggle="collapse" href="#event-one">
                                            Safe House Thrift Store
                                        </a>
                                    </h5>

                                    <div id="event-one" className="collapse" data-parent="#event-list">
                                        <div className="card-body">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-img-top">
                                        <div className="badges badges-right">
                                            <span className="badge badge-primary mr-1">Phone Discovery Meeting</span>
                                            <span className={`badge ${eventTimeColor('6/2/2020 2:40 PM')}`}>6/2/2020 @ 2:40 PM</span>
                                        </div>
                                    </div>

                                    <h5 className="card-title">
                                        <a className="collapsed" data-toggle="collapse" href="#event-two">
                                            Show Low Motor Sports
                                        </a>
                                    </h5>

                                    <div id="event-two" className="collapse" data-parent="#event-list">
                                        <div className="card-body">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                        </div>
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                    }

                    {showCommunications &&
                        <div>
                            <div className="border rounded bg-white p-2 mb-2">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                    </div>
                                    <div className="col lh-5">
                                        <a className="text-default fw-400" href="#">Filters</a>
                                        <span className="topbar-divider"></span>
                                        <span className="badge badge-secondary mr-1">Yesterday</span>
                                        <span className="badge badge-primary mr-1">Today</span>
                                        <span className="badge badge-secondary mr-1">Tomorrow</span>
                                    </div>

                                    <div className="col-auto">
                                        <span className="badge badge-primary mr-1 opacity-65">Sale</span>
                                        <span className="badge badge-success mr-1">Spoke With Them</span>
                                        <span className="badge badge-info mr-1 opacity-65">Voicemail</span>
                                        <span className="badge badge-warning mr-1 opacity-65">Email</span>
                                        <span className="badge badge-danger mr-1 opacity-65">NO</span>
                                    </div>
                                </div>
                            </div>

                            {data.communications.map((communication: any) => (
                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2" key={nanoid()}>
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">{communication.contact.name || 'Contact name unknown'}</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">Advertiser (/company) Name</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">{communication.type.name || 'None'}</span>
                                            <span className="badge badge-success">{communication.disposition.name || 'None'}</span>
                                        </div>
                                        {moment(communication.start).format('MM/DD/YYYY [@] LT')}
                                    </div>
                                </div>
                            ))}

                            {/*
                            <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                <div className="media-body lh-3">
                                    <h6 className="mb-0">
                                        <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Julie Carman</Link>
                                    </h6>
                                    <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                </div>

                                <div className="col-auto text-right">
                                    <div className="mb-0">
                                        <span className="badge badge-secondary mr-1">Outbound Call</span>
                                        <span className="badge badge-info">Left voicemail</span>
                                    </div>
                                    6/2/2020 @ 9:59am
                                </div>
                            </div>

                            <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                <div className="media-body lh-3">
                                    <h6 className="mb-0">
                                        <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Wendy Wurz</Link>
                                    </h6>
                                    <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                </div>

                                <div className="col-auto text-right">
                                    <div className="mb-0">
                                        <span className="badge badge-secondary mr-1">Outbound Call</span>
                                        <span className="badge badge-success">Spoke with them</span>
                                    </div>
                                    6/2/2020 @ 9:59am
                                </div>
                            </div>

                            <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                <div className="media-body lh-3">
                                    <h6 className="mb-0">
                                        <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Tawanna Simmons</Link>
                                    </h6>
                                    <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                </div>

                                <div className="col-auto text-right">
                                    <div className="mb-0">
                                        <span className="badge badge-secondary mr-1">Outbound Call</span>
                                        <span className="badge badge-info">Left Voicemail</span>
                                    </div>
                                    6/2/2020 @ 10:11am
                                </div>
                            </div>

                            <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                <div className="media-body lh-3">
                                    <h6 className="mb-0">
                                        <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Nicole Gibson</Link>
                                    </h6>
                                    <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                </div>

                                <div className="col-auto text-right">
                                    <div className="mb-0">
                                        <span className="badge badge-secondary mr-1">Outbound Call</span>
                                        <span className="badge badge-success">Spoke With Them</span>
                                    </div>
                                    6/2/2020 @ 10:18am
                                </div>
                            </div>

                            <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                <div className="media-body lh-3">
                                    <h6 className="mb-0">
                                        <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">David Hoffman</Link>
                                    </h6>
                                    <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                </div>

                                <div className="col-auto text-right">
                                    <div className="mb-0">
                                        <span className="badge badge-secondary mr-1">Outbound Call</span>
                                        <span className="badge badge-danger">Not Interested</span>
                                    </div>
                                    6/2/2020 @ 10:26am
                                </div>
                            </div>

                            <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                <div className="media-body lh-3">
                                    <h6 className="mb-0">
                                        <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Beth Kerly</Link>
                                    </h6>
                                    <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                </div>

                                <div className="col-auto text-right">
                                    <div className="mb-0">
                                        <span className="badge badge-secondary mr-1">Outbound Call</span>
                                        <span className="badge badge-info">Left voicemail</span>
                                    </div>
                                    6/2/2020 @ 10:30am
                                </div>
                            </div>

                            <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                <div className="media-body lh-3">
                                    <h6 className="mb-0">
                                        <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Ann Madsen</Link>
                                    </h6>
                                    <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                </div>

                                <div className="col-auto text-right">
                                    <div className="mb-0">
                                        <span className="badge badge-secondary mr-1">Outbound Call</span>
                                        <span className="badge badge-info">Left voicemail</span>
                                    </div>
                                    6/2/2020 @ 10:33am
                                </div>
                            </div>

                            <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                <div className="media-body lh-3">
                                    <h6 className="mb-0">
                                        <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Laura DeLise</Link>
                                    </h6>
                                    <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                </div>

                                <div className="col-auto text-right">
                                    <div className="mb-0">
                                        <span className="badge badge-secondary mr-1">Outbound Call</span>
                                        <span className="badge badge-warning">Sent Email</span>
                                    </div>
                                    6/2/2020 @ 10:36am
                                </div>
                            </div>
                            */}
                        </div>
                    }

                    <h6 className="divider">Artwork Status</h6>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-4">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Community Bank</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">Livingston</p>
                        </div>

                        <div className="col-auto">
                            <small className="opacity-65">
                                <span className="badge badge-danger">5 Days</span>
                            </small>
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-4">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Ashton Chase Apartments</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">South Lake</p>
                        </div>

                        <div className="col-auto">
                            <small className="opacity-65">
                                <span className="badge badge-warning">19 Days</span>
                            </small>
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-4">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Mill Creek Optical</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">Livingston</p>
                        </div>

                        <div className="col-auto">
                            <small className="opacity-65">
                                <span className="badge badge-warning">26 Days</span>
                            </small>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mx-auto">
                    <h6 className="divider">
                        <a
                            href=""
                            onClick={e => { e.preventDefault(); setShowTeamProductivity(!showTeamProductivity)}}
                        >
                            {showTeamProductivity ? 'Team' : 'My'} Productivity
                        </a>
                    </h6>

                    {showTeamProductivity &&
                        <div className="border rounded bg-white p-2 mb-4">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                </div>
                                <div className="col lh-5">
                                    <a className="text-default fw-400" href="#">Filters</a>
                                    <span className="topbar-divider"></span>
                                    <a
                                        href=""
                                        className={`badge ${filterTeamProductivityWeekly ? 'badge-secondary' : 'badge-primary'} mr-1`}
                                        onClick={e => { e.preventDefault(); setFilterTeamProductivityWeekly(!filterTeamProductivityWeekly)}}
                                    >
                                        Daily
                                    </a>
                                    <a
                                        href=""
                                        className={`badge ${filterTeamProductivityWeekly ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                        onClick={e => { e.preventDefault(); setFilterTeamProductivityWeekly(!filterTeamProductivityWeekly)}}
                                    >
                                        Weekly 
                                    </a>
                                </div>
                            </div>
                        </div>
                    }

                    {!showTeamProductivity &&
                        <div className="border rounded bg-white p-2 mb-4">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                </div>
                                <div className="col lh-5">
                                    <a className="text-default fw-400" href="#">Filters</a>
                                    <span className="topbar-divider"></span>
                                    <a
                                        href=""
                                        className={`badge ${filterMyProductivityWeekly ? 'badge-secondary' : 'badge-primary'} mr-1`}
                                        onClick={e => { e.preventDefault(); setFilterMyProductivityWeekly(!filterMyProductivityWeekly)}}
                                    >
                                        Daily
                                    </a>
                                    <a
                                        href=""
                                        className={`badge ${filterMyProductivityWeekly ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                        onClick={e => { e.preventDefault(); setFilterMyProductivityWeekly(!filterMyProductivityWeekly)}}
                                    >
                                        Weekly
                                    </a>
                                </div>
                            </div>
                        </div>
                    }

                    {showTeamProductivity &&
                        <div className="cart-price">
                            <div className="flexbox">
                                <div>
                                    <p>Dials /hr</p>
                                    <p>Completed Calls /hr</p>
                                    <p>Sales</p>
                                    <p>Sales /hr</p>
                                    <p>Conversion Rate</p>
                                    <p>Average Sale</p>
                                </div>

                                <div>
                                    <p>N/A</p> {/*DPH = (Dispositions/Connections) / hours worked */}
                                    <p>N/A</p> {/* Completed calls per hour = (Completed Calls - yes/no/maybe) / hours worked (timeframe - for the day, week, month, etc) */}
                                    <p>N/A</p> {/* Sales = total sales */}
                                    <p>N/A</p> {/* Sales per hour = total sales / hours worked */}
                                    <p>N/A</p> {/* Conversion Rate = (sales) / (dials per hour) */}
                                    <p>N/A</p> {/* Average Sale =  */}
                                </div>
                            </div>
                        </div>
                    }

                    {!showTeamProductivity &&
                        <div className="cart-price">
                            <div className="flexbox">
                                <div>
                                    <p>Dials /hr</p>
                                    <p>Completed Calls /hr</p>
                                    <p>Sales</p>
                                    <p>Sales /hr</p>
                                    <p>Conversion Rate</p>
                                    <p>Average Sale</p>
                                </div>

                                <div>
                                    <p>N/A</p> {/*DPH = (Dispositions/Connections) / hours worked */}
                                    <p>N/A</p> {/* Completed calls per hour = (Completed Calls - yes/no/maybe) / hours worked (timeframe - for the day, week, month, etc) */}
                                    <p>N/A</p> {/* Sales = total sales */}
                                    <p>N/A</p> {/* Sales per hour = total sales / hours worked */}
                                    <p>N/A</p> {/* Conversion Rate = (sales) / (dials per hour) */}
                                    <p>N/A</p> {/* Average Sale =  */}
                                </div>
                            </div>
                        </div>
                    }

                    <h6 className="divider">Your Assigned Projects</h6>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <Link to="/projects/751/marketing-list">
                            <div className="media-body lh-4">
                                <h6 className="mb-0">Canton-Stark County</h6>
                                <p className="text-lighter mb-0 small-2">Convention and Visitor Bureau</p>
                            </div>
                        </Link>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <Link to="/projects/557/marketing-list">
                            <div className="media-body lh-4">
                                <h6 className="mb-0">Fenton Area</h6>
                                <p className="text-lighter mb-0 small-2">Chamber of Commerce</p>
                            </div>
                        </Link>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <Link to="/projects/936/marketing-list">
                            <div className="media-body lh-4">
                                <h6 className="mb-0">Keweenaw</h6>
                                <p className="text-lighter mb-0 small-2">Convention and Visitor Bureau</p>
                            </div>
                        </Link>
                    </div>

                    <Suspense fallback={<div>Loading...</div>}>
                        <EmployeeContactList />
                    </Suspense>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdSalesDashboard;
