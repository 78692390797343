import React, { useState } from 'react';

import { useMutation } from 'urql';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { nanoid } from 'nanoid';

interface IProps {
    contactId: any,
    communicationTypes: any,
    communicationDispositions: any
}

const AddMarketingListCommunication: React.FC<IProps> = (props) => {
    const contactId = props.contactId;

    const [communicationTypeId, setCommunicationTypeId] = useState<number>(0);
    const [communicationComment, setCommunicationComment] = useState<string>('');
    const [communicationDispositionId, setCommunicationDispositionId] = useState<number>(0);
    const [communicationAdded, setCommunicationAdded] = useState<boolean>(false);

    const [addDispositionResult, executeDispositionMutation] = useMutation(gql`mutation AddCommunication ($id: String!, $user_id: String!, $type_id: Int!, $start: timestamptz!, $end: timestamptz!, $comment: String!, $disposition_id: Int!) {
        insert_ml_communications (objects: { id: $id, user_id: $user_id, type_id: $type_id, start: $start, end: $end, comment: $comment, disposition_id: $disposition_id }) {
            returning {
                id
                user_id
                type_id
                start
                end
                comment
                disposition_id
            }
        }
    }`);

    const addCommunication = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        try {
            const now = moment();

            executeDispositionMutation({
                id: nanoid(),
                user_id: contactId,
                // rep_id: ls.get('uid'),
                type_id: communicationTypeId,
                start: moment(now).tz("America/New_York").format(),
                end: moment(now).tz("America/New_York").format(),
                comment: communicationComment,
                disposition_id: communicationDispositionId,
                // disposition_desc: communicationComment
            });

            // Clear the form inputs
            setCommunicationTypeId(0);
            setCommunicationComment('');
            setCommunicationDispositionId(0);

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setCommunicationAdded(true);
        } catch (err) {
            console.warn(err);
        }
    };

    return (
        <React.Fragment>
            <p>
                <select
                    className="form-control"
                    value={communicationTypeId || ''}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCommunicationTypeId(parseInt(e.target.value, 10))}
                    // onChange={e => { e.preventDefault(); this.setState({ newConnectionType: e.target.value })}}
                >
                    <option value="">-- Select Type --</option>
                    {props.communicationTypes.map((
                        type: {
                            id: number;
                            name: string;
                        }
                    ) => (
                        <option key={nanoid()} value={type.id}>{type.name}</option>
                    ))}
                    {/*
                    <option value="Outbound Call">Outbound Call</option>
                    <option value="Outbound Email">Outbound Email</option>
                    */}
                </select>
            </p>
            <p>
                <select
                    className="form-control"
                    value={communicationDispositionId || ''}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setCommunicationDispositionId(parseInt(e.target.value, 10));
                    }}
                    // onChange={e => { e.preventDefault(); this.setState({ newConnectionDisposition: e.target.value })}}
                >
                    <option value="">-- Select Disposition --</option>
                    {props.communicationDispositions.map((
                        disposition: {
                            id: number;
                            name: string;
                        }
                    ) => (
                        <option key={disposition.id} value={disposition.id}>{disposition.name}</option>
                    ))}

                    {/* <option value="Spoke With Them">Spoke With Them</option> */}
                    {/* <option value="Voicemail">Voicemail</option> */}
                    {/* <option value="Not Interested">Not Interested</option> */}

                    {/*
                    <option value="Email">Email</option>
                    <option value="Sale">Sale</option>
                    */}
                </select>
            </p>
            <p>
                <textarea
                    className="form-control"
                    value={communicationComment || ''}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCommunicationComment(e.target.value)}
                />
            </p>
            <p>
                <button
                    className="btn btn-success btn-block"
                    onClick={addCommunication}
                >
                    Disposition Record
                </button>
            </p>
            
            {communicationAdded && <div className="alert alert-success" role="alert">Success!</div>}
        </React.Fragment>
    )

    {/*
    return <table className="table table-cart bg-white shadow-2">
        <tbody>
            {props.projects.map((
                project: {
                    id: string;
                    name: string;
                    year: number;
                    start: Date;
                    end: Date;
                    completed: boolean;
                    is_delayed: boolean;

                    type: {
                        id: number;
                        name: string;
                    }

                    client: {
                        id: number;
                        name: string;

                        company_type: {
                            id: number;
                            name: string;
                        }
                    }

                    milestone: {
                        id: number;
                        name: string;
                        step: number;
                    }
                }
            ) => (
                <tr key={nanoid()}>
                    <td>
                        <h5>
                            <Link to={`/projects/${project.id}`}>{project.name}</Link>
                        </h5>
                        <p>Project Type TBD</p>
                    </td>

                    <td>
                        <h5>{project.client.name || 'Unknown Company'}</h5>
                        <p>{project.client.company_type.name || <span>&nbsp;</span>}</p>
                    </td>

                    <td>
                        <h5>Start: {project.start || <span>Unknown</span>}</h5>
                        <p>End: {project.end || <span>Unknown</span>}</p>
                    </td>

                    <td>
                        {project.milestone
                            ? <span className={`badge badge-${project.is_delayed ? 'warning' : 'primary'}`}>M{project.milestone.step}: {project.milestone.name}</span>
                            : <>&nbsp;</>
                        }

                        {project.completed && <span className="badge badge-success">Completed</span>}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>;
    */}
};

export default AddMarketingListCommunication;
