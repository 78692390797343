import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';
import { nanoid } from 'nanoid';

// TODO: Import components

interface IDailySalesReportPageProps extends RouteComponentProps { }

interface IState { }

class DailySalesReportPage extends React.Component<IDailySalesReportPageProps, IState> {
    // Define the constructor for the daily sales report page
    constructor (props: IDailySalesReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the daily sales report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Daily Sales Report</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Week of 08/18/2020 - 08/21/2020</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container pl-0 pr-0">
                        <div className="row">
                            <div className="col-8 mx-auto">
                                {/* TODO: Add functionality and remove warning */}
                                <div className="alert alert-warning" role="alert">Daily Sales report is not yet functional</div>

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Monday</strong><br />
                                                <small className="text-muted">
                                                    08/17/2020
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Sales Rep</strong></div>
                                                <div className="col-1 text-left"><strong>DM</strong></div>
                                                <div className="col-1 text-left"><strong>Hours</strong></div>
                                                <div className="col-1 text-left"><strong>Sales</strong></div>
                                                <div className="col-1 text-left"><strong>SPH</strong></div>
                                                <div className="col-2 text-left"><strong>Revenue</strong></div>
                                                <div className="col-1 text-left"><strong>RPH</strong></div>
                                                <div className="col-2 text-left"><strong>Close Rate</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                            {/* Ed	20	8	3	0.38	$3,475.00	$434.38	15.0% */}
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Tuesday</strong><br />
                                                <small className="text-muted">
                                                    08/18/2020
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Sales Rep</strong></div>
                                                <div className="col-1 text-left"><strong>DM</strong></div>
                                                <div className="col-1 text-left"><strong>Hours</strong></div>
                                                <div className="col-1 text-left"><strong>Sales</strong></div>
                                                <div className="col-1 text-left"><strong>SPH</strong></div>
                                                <div className="col-2 text-left"><strong>Revenue</strong></div>
                                                <div className="col-1 text-left"><strong>RPH</strong></div>
                                                <div className="col-2 text-left"><strong>Close Rate</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                            {/* Ed	20	8	3	0.38	$3,475.00	$434.38	15.0% */}
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Wednesday</strong><br />
                                                <small className="text-muted">
                                                    08/19/2020
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Sales Rep</strong></div>
                                                <div className="col-1 text-left"><strong>DM</strong></div>
                                                <div className="col-1 text-left"><strong>Hours</strong></div>
                                                <div className="col-1 text-left"><strong>Sales</strong></div>
                                                <div className="col-1 text-left"><strong>SPH</strong></div>
                                                <div className="col-2 text-left"><strong>Revenue</strong></div>
                                                <div className="col-1 text-left"><strong>RPH</strong></div>
                                                <div className="col-2 text-left"><strong>Close Rate</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                            {/* Ed	20	8	3	0.38	$3,475.00	$434.38	15.0% */}
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Thursday</strong><br />
                                                <small className="text-muted">
                                                    08/20/2020
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Sales Rep</strong></div>
                                                <div className="col-1 text-left"><strong>DM</strong></div>
                                                <div className="col-1 text-left"><strong>Hours</strong></div>
                                                <div className="col-1 text-left"><strong>Sales</strong></div>
                                                <div className="col-1 text-left"><strong>SPH</strong></div>
                                                <div className="col-2 text-left"><strong>Revenue</strong></div>
                                                <div className="col-1 text-left"><strong>RPH</strong></div>
                                                <div className="col-2 text-left"><strong>Close Rate</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                            {/* Ed	20	8	3	0.38	$3,475.00	$434.38	15.0% */}
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Friday</strong><br />
                                                <small className="text-muted">
                                                    08/21/2020
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Sales Rep</strong></div>
                                                <div className="col-1 text-left"><strong>DM</strong></div>
                                                <div className="col-1 text-left"><strong>Hours</strong></div>
                                                <div className="col-1 text-left"><strong>Sales</strong></div>
                                                <div className="col-1 text-left"><strong>SPH</strong></div>
                                                <div className="col-2 text-left"><strong>Revenue</strong></div>
                                                <div className="col-1 text-left"><strong>RPH</strong></div>
                                                <div className="col-2 text-left"><strong>Close Rate</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                            {/* Ed	20	8	3	0.38	$3,475.00	$434.38	15.0% */}
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                {/* <h6 className="divider"><a href="" onClick={e => {}}>Projects</a></h6> */}

                                <div className="border rounded shadow-2 p-4 mb-4 bg-white" key={nanoid()}>
                                    <div className="row align-items-center">
                                        <div className="col lh-5">
                                            <div>
                                                {/*<Link to={`/agreements/${agreement.id}`} className="text-default fw-400">*/}
                                                <strong>Weekly Totals</strong><br />
                                                <small className="text-muted">
                                                    08/18/2020 - 08/21/2020
                                                </small>

                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                                <div className="col-3 lh-5"><strong>Sales Rep</strong></div>
                                                <div className="col-1 text-left"><strong>DM</strong></div>
                                                <div className="col-1 text-left"><strong>Hours</strong></div>
                                                <div className="col-1 text-left"><strong>Sales</strong></div>
                                                <div className="col-1 text-left"><strong>SPH</strong></div>
                                                <div className="col-2 text-left"><strong>Revenue</strong></div>
                                                <div className="col-1 text-left"><strong>RPH</strong></div>
                                                <div className="col-2 text-left"><strong>Close Rate</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col lh-5">
                                            <div className="row">
                                            {/* Ed	20	8	3	0.38	$3,475.00	$434.38	15.0% */}
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 lh-5">Agent Name</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                                <div className="col-1 text-left">TBD</div>
                                                <div className="col-2 text-left">TBD</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                            Key:
                                DM = Decision Maker
                                RPH = Revenue Per Hour
                                SPH = Sales Per Hour


                            Monday 1/7/2019     | DM    | Hours     | # Sales   | SPH   | Revenue   | RPH       | Close Rate
                            --------------------|-------|-----------|-----------|-------|-----------|-----------|------------
                            Ed                  | 20    | 8         | 3         | 0.38  | $3,475    | $434.38   | 15.0%
                            --------------------|-------|-----------|-----------|-------|-----------|-----------|------------
                            Christian
                            --------------------|-------|-----------|-----------|-------|-----------|-----------|------------
                            Etc. All Reps
                            --------------------|-------|-----------|-----------|-------|-----------|-----------|------------
                            Total               | 100   | 40        | 7         | 0.18  | $11,475   | $286.88   | 7.0%

                            Repeat for tues - fri, then one extra section for "weekly totals" - same headers
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default DailySalesReportPage;
