import React, { useState } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { useQuery } from 'urql';
import FullPage from '../../layouts/FullPage';
import gql from 'graphql-tag';
import moment from 'moment';
import AlertMessage from '../../components/alerts/AlertMessage';
import { firestore, storage } from '../../firebase/firebase.utils';
import { nanoid } from 'nanoid';

interface MatchParams { id: string; }
interface MatchProps extends RouteComponentProps<MatchParams> { }

const q = gql`
    query ($projectId: String!) {
        projects (where: {id: {_eq: $projectId }}) {
            id
            name
        }
    }
`;

const ProjectPage: React.FC<MatchProps> = (match: MatchProps) => {
    // const projectId = match.match.params.id;
    const projectId = match.id;

    /*
    const [state, setState] = useState({
        rcSlug: '',
        rcName: '',
        rcDirector: '',
        rcDirectorTitle: '',
        rcHero: '',
        rcDirectorPhoto: '',
        rcLogo: '',
        rcThumb: '',
        rcYear: moment().year() + 1,
        rcSecondaryHero: '',
        // sizes: []
    });
    */

    const [ratecard, setRatecard] = useState({
        slug: '',
        name: '',
        director: '',
        directorTitle: '',
        hero: '',
        // directorPhoto: '',
        // logo: '',
        // thumb: '',
        url: '',
        year: moment().year() + 1,
        directorMessageLeft: '',
        directorMessageRight: '',
        // secondaryHero: '',
        // sizes: []
        urlHero: '',
        urlDirectorPhoto: '',
        urlLogo: '',
        urlRatecardThumb: '',
        urlSecondaryHero: ''
    });

    /*
    firestore
        .collection('concepts')
        .doc(projectId)
        .get()
        .then(doc => {
            if (doc.exists) {
                // this.setState({ ...doc.data() }, () => { console.log(this.state) });
                // setRatecard({
                    // slug: doc.data().slug,
                // });
            }
        })
        .catch(error => {
            // this.setState({ error });
        });
    */

    const [sizes, setSizes] = useState([{ name: '', cost: '0' }]);

    const [result, reexecuteQuery] = useQuery({
        query: q,
        variables: { projectId: projectId }
    });

    const { data, fetching, error } = result;

    if (fetching) {
        return <FullPage>
            <div className="row gap-y">
                <div className="col-12 col-lg-2">
                    Loading...
                </div>
            </div>
        </FullPage>;
    }

    if (error) return <AlertMessage type="danger" text={error.message} />;

    const addSize = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // Append a new size to the array
        // setSizes([...sizes, { name: '', cost: '0' }]);
        setSizes(sizes => [...sizes, { name: '', cost: '0' }]);
    };


    /*
    urlHero
    urlDirectorPhoto
    urlChamberLogo
    urlRatecardThumb
    urlSecondaryHero
    */


    const rcUploadHero = (e: any) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        if (e.target.files[0]) {
            // Set the loading state of the thumbnail to true
            // this.setState({ loadingThumb: true });

            // Extract the image from the file upload form event
            const img = e.target.files[0];

            // Set a new name with a uuid to avoid name-collision
            const imgName = `${nanoid()}.${img.name.split('.').pop()}`;

            // Use the firebase storage service to put the file in the S3 resource
            const uploadTask = storage.ref(`ratecard/images/${imgName}`).put(img);

            uploadTask.on(
                'state_changed',
                // Handle progress callback
                snapshot => {
                    // Get the whole-number percentage of the progress
                    // const progressThumb = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                    // Display upload progress in the console for debugging purposes
                    // console.log('progress', ' => ', progressThumb);

                    // Set the upload progress in the state
                    // this.setState({ progressThumb });
                },
                error => console.log(`Error uploading thumb: ${error}`),
                () => {
                    // When complete, set the url in the state
                    storage
                        .ref(`ratecard/images`)
                        .child(imgName)
                        .getDownloadURL()
                        .then(urlHero => {
                            setRatecard({ ...ratecard, urlHero });
                            firestore.collection('projects').doc(projectId).set({ imgHero: urlHero }, { merge: true });
                        });
                }
            );
        } else {
            console.log('No file selected');
        }
    };

    const rcUploadDirectorPhoto = (e: any) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        if (e.target.files[0]) {
            // Set the loading state of the thumbnail to true
            // this.setState({ loadingThumb: true });

            // Extract the image from the file upload form event
            const img = e.target.files[0];

            // Set a new name with a uuid to avoid name-collision
            const imgName = `${nanoid()}.${img.name.split('.').pop()}`;

            // Use the firebase storage service to put the file in the S3 resource
            const uploadTask = storage.ref(`ratecard/images/${imgName}`).put(img);

            uploadTask.on(
                'state_changed',
                // Handle progress callback
                snapshot => {
                    // Get the whole-number percentage of the progress
                    // const progressThumb = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                    // Display upload progress in the console for debugging purposes
                    // console.log('progress', ' => ', progressThumb);

                    // Set the upload progress in the state
                    // this.setState({ progressThumb });
                },
                error => console.log(`Error uploading thumb: ${error}`),
                () => {
                    // When complete, set the url in the state
                    storage
                        .ref(`ratecard/images`)
                        .child(imgName)
                        .getDownloadURL()
                        .then(urlDirectorPhoto => {
                            setRatecard({ ...ratecard, urlDirectorPhoto });
                            firestore.collection('projects').doc(projectId).set({ imgDirector: urlDirectorPhoto }, { merge: true });
                        });
                }
            );
        } else {
            console.log('No file selected');
        }
    };

    const rcUploadChamberLogo  = (e: any) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        if (e.target.files[0]) {
            // Set the loading state of the thumbnail to true
            // this.setState({ loadingThumb: true });

            // Extract the image from the file upload form event
            const img = e.target.files[0];

            // Set a new name with a uuid to avoid name-collision
            const imgName = `${nanoid()}.${img.name.split('.').pop()}`;

            // Use the firebase storage service to put the file in the S3 resource
            const uploadTask = storage.ref(`ratecard/images/${imgName}`).put(img);

            uploadTask.on(
                'state_changed',
                // Handle progress callback
                snapshot => {
                    // Get the whole-number percentage of the progress
                    // const progressThumb = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                    // Display upload progress in the console for debugging purposes
                    // console.log('progress', ' => ', progressThumb);

                    // Set the upload progress in the state
                    // this.setState({ progressThumb });
                },
                error => console.log(`Error uploading thumb: ${error}`),
                () => {
                    // When complete, set the url in the state
                    storage
                        .ref(`ratecard/images`)
                        .child(imgName)
                        .getDownloadURL()
                        .then(urlLogo => {
                            setRatecard({ ...ratecard, urlLogo });
                            firestore.collection('projects').doc(projectId).set({ imgLogo: urlLogo }, { merge: true });
                        });
                }
            );
        } else {
            console.log('No file selected');
        }
    };

    const rcUploadThumb = (e: any) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        if (e.target.files[0]) {
            // Set the loading state of the thumbnail to true
            // this.setState({ loadingThumb: true });

            // Extract the image from the file upload form event
            const img = e.target.files[0];

            // Set a new name with a uuid to avoid name-collision
            const imgName = `${nanoid()}.${img.name.split('.').pop()}`;

            // Use the firebase storage service to put the file in the S3 resource
            const uploadTask = storage.ref(`ratecard/images/${imgName}`).put(img);

            uploadTask.on(
                'state_changed',
                // Handle progress callback
                snapshot => {
                    // Get the whole-number percentage of the progress
                    const progressThumb = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // Display upload progress in the console for debugging purposes
                    console.log('progress', ' => ', progressThumb);

                    // Set the upload progress in the state
                    // this.setState({ progressThumb });
                },
                error => console.log(`Error uploading thumb: ${error}`),
                () => {
                    // When complete, set the url in the state
                    storage
                        .ref(`ratecard/images`)
                        .child(imgName)
                        .getDownloadURL()
                        .then(urlRatecardThumb => {
                            setRatecard({ ...ratecard, urlRatecardThumb });
                            firestore.collection('projects').doc(projectId).set({ ratecardThumb: urlRatecardThumb }, { merge: true });
                        });
                }
            );
        } else {
            console.log('No file selected');
        }
    };

    const rcUploadSecondaryHero = (e: any) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        if (e.target.files[0]) {
            // Set the loading state of the thumbnail to true
            // this.setState({ loadingThumb: true });

            // Extract the image from the file upload form event
            const img = e.target.files[0];

            // Set a new name with a uuid to avoid name-collision
            const imgName = `${nanoid()}.${img.name.split('.').pop()}`;

            // Use the firebase storage service to put the file in the S3 resource
            const uploadTask = storage.ref(`ratecard/images/${imgName}`).put(img);

            uploadTask.on(
                'state_changed',
                // Handle progress callback
                snapshot => {
                    // Get the whole-number percentage of the progress
                    // const progressThumb = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                    // Display upload progress in the console for debugging purposes
                    // console.log('progress', ' => ', progressThumb);

                    // Set the upload progress in the state
                    // this.setState({ progressThumb });
                },
                error => console.log(`Error uploading thumb: ${error}`),
                () => {
                    // When complete, set the url in the state
                    storage
                        .ref(`ratecard/images`)
                        .child(imgName)
                        .getDownloadURL()
                        .then(urlSecondaryHero => {
                            setRatecard({ ...ratecard, urlSecondaryHero });
                            firestore.collection('projects').doc(projectId).set({ imgSecondaryHero: urlSecondaryHero }, { merge: true });
                        });
                }
            );
        } else {
            console.log('No file selected');
        }
    };

    const saveInfo = (e: React.FormEvent) => {
        e.preventDefault();

        try {
            // Update the concept's data
            // this.conceptRef
            firestore
                .collection('projects')
                .doc(projectId)
                .update({
                    // name, type, size, url, urlThumb
                    // companyName: '',
                    directorMessageLeft: ratecard.directorMessageLeft,
                    directorMessageRight: ratecard.directorMessageRight,
                    directorName: ratecard.director,
                    directorTitle: ratecard.directorTitle,
                    // imgDirector: '',
                    // imgHero: '',
                    // imgLogo: '',
                    // imgSecondaryHero: '',
                    name: ratecard.name,
                    // ratecardThumb: '',
                    ratecardUrl: ratecard.url,
                    slug: ratecard.slug,
                    year: ratecard.year
                })
                .then(() => {
                    // Clear the state back to the original state for
                    // the next time we try to edit a concept
                    /*
                    this.setState({
                        name: '',
                        type: '',
                        size: '',
                        url: '',
                        urlThumb: '',
                        visible: true
                    });
                    */

                    // Redirect back to the concept library page
                    // this.props.history.push('/concepts');
                })
                .catch((error) => {
                    // console.error(`Error saving concept: ${error}`);
                });
        } catch (error) {
            // this.setState({ error, loading: false });
        }
    };

    const saveSizes = (e: React.FormEvent) => {
        e.preventDefault();

        try {
            // Update the concept's data
            // this.conceptRef
            firestore
                .collection('projects')
                .doc(projectId)
                .update({
                    sizes: ''
                })
                .then(() => {})
                .catch((error) => {});
        } catch (error) {
            // this.setState({ error, loading: false });
        }
    };

    const project = data.projects[0];

    return <FullPage>
        <section className="section" style={{ paddingTop: '1rem' }}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                    <div className="container d-stick-none">
                        <div className="row">
                            <div className="col-8">
                                <div className="nav nav-navbar">
                                    <span className="nav-link active">{project.name}</span>
                                </div>
                            </div>

                            <div className="col-4 ml-auto">
                                <nav className="nav nav-navbar justify-content-end">
                                    <span className="nav-link">Project Type TBD</span>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <hr className="my-0 w-100" />

                    <div className="container">
                        <div className="navbar-left">
                            <button className="navbar-toggler" type="button">
                                <span className="navbar-toggler-icon" />
                            </button>
                        </div>

                        <section className="navbar-mobile">
                            <div className="nav nav-navbar mr-auto">
                                <a href="#info" className="nav-link active" data-toggle="tab">Info</a>
                                <a href="#rate-card" className="nav-link" data-toggle="tab">Rate Card</a>
                                <a href="#agreements" className="nav-link" data-toggle="tab">Agreements</a>
                                <a href="#marketing-list" className="nav-link" data-toggle="tab">Marketing List</a>
                                {/*<a href="#events" className="nav-link" data-toggle="tab">Events</a>*/}
                            </div>

                            <nav className="nav nav-navbar ml-lg-5">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Reports <span className="arrow" /></a>
                                    <nav className="nav dropdown-menu-right">
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/action-items`}>Action Items</Link>
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/ad-audit`}>Ad Audit</Link>
                                        {/* <Link className="nav-link" to={`/projects/${projectId}/reports/change-request`}>Change Request</Link> */}
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/client-specs`}>Client Specs</Link>
                                        {/* <Link className="nav-link" to={`/projects/${projectId}/reports/collections`}>Collections</Link> */}
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/content`}>Content</Link>
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/images`}>Images</Link>
                                        {/* <Link className="nav-link" to={`/projects/${projectId}/reports/money`}>Money</Link> */}
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/multi-day`}>15, 30, 45 Day</Link>
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/timeline`}>Timeline</Link>
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/weekly-meeting`}>Weekly Meeting</Link>
                                        <Link className="nav-link" to={`/projects/${projectId}/reports/sales`}>Sales Report</Link> {/* TODO */}
                                        {/* <Link className="nav-link" to={`/projects/${projectId}/reports/designer-variance`}>Designer Variance</Link> */}
                                    </nav>
                                </li>
                            </nav>
                        </section>
                    </div>
                </nav>
            </div>
        </section>

        <div className="tab-content p-4">
            <section className="section tab-pane show active" id="info" style={{ paddingTop: '2rem' }}>
                <div className="container">
                        {/* TODO: Add functionality and remove warning */}
                        <div className="alert alert-warning" role="alert">Project info is not yet functional</div>
                    {/*
                    Info
                    -----------------------
                    Cost / Goal

                    Assigned Designer(s)
                    Assigned Project Manager
                    Photographer
                    Copywriter
                    */}
                </div>
            </section>

            <section className="section tab-pane" id="rate-card" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    {/*
                    Rate Card + Sizes
                    -----------------
                    (4) Sizes | (4) Hero Img        | Secondary Hero
                                    Director Img    | (4) Message Left
                                    Director Name         Message Right
                                    Director Title
                                    Chamber Logo

                                    Ratecard Url
                    */}

                    {(ratecard && ratecard.slug && ratecard.name) &&
                        <div className="row">
                            <div className="col-md-4 pl-0">
                                <div className="alert alert-success mt-3" role="alert">Landing page ready. <a href={`https://ratecard.lunarcow.com/${ratecard.slug}`} target="_blank">Click here to open the landing page in a new tab</a>.</div>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-md-4 pl-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                {/*
                                <div className="row">
                                    <div className="col lh-5"><strong>Primary Info</strong></div>
                                    <div className="col-auto text-center"><strong>Value</strong></div>
                                </div>
                                */}

                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">URL Slug</span>
                                            </div>
                                            <input
                                                className="form-control"
                                                placeholder={`${moment().year() + 1}-name-type`}
                                                value={ratecard.slug || ''}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ): void => {
                                                    setRatecard({ ...ratecard, slug: e.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Name Override</span>
                                            </div>
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                value={ratecard.name || ''}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ): void => {
                                                    setRatecard({ ...ratecard, name: e.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Year</span>
                                            </div>
                                            <input
                                                className="form-control"
                                                placeholder={`${moment().year() + 1}`}
                                                value={ratecard.year || ''}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ): void => {
                                                    setRatecard({ ...ratecard, year: parseInt(e.target.value, 10) })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Director Name</span>
                                            </div>
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                value={ratecard.director || ''}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ): void => {
                                                    setRatecard({ ...ratecard, director: e.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Director Title</span>
                                            </div>
                                            <input
                                                className="form-control"
                                                placeholder="Executive Director"
                                                value={ratecard.directorTitle || ''}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ): void => {
                                                    setRatecard({ ...ratecard, directorTitle: e.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Ratecard URL</span>
                                            </div>
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                value={ratecard.url || ''}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ): void => {
                                                    setRatecard({ ...ratecard, url: e.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5"><strong>Size Name</strong></div>
                                    <div className="col-auto"><strong>Cost</strong></div>
                                </div>

                                <div className="row">
                                    <div className="col-8 pr-0 lh-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value="Inside Front Cover (Page 3)"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value="1500"
                                        />
                                    </div>
                                </div>

                                {/*
                                <div className="row">
                                    <div className="col lh-5">Inside Front Cover (Page 3)</div>
                                    <div className="col-auto ">$1,500</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Inside Front Cover (Page 3)</div>
                                    <div className="col-auto ">$1,500</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Inside Front Cover (Page 3)</div>
                                    <div className="col-auto ">$1,500</div>
                                </div>

                                <div className="row">
                                    <div className="col lh-5">Inside Front Cover (Page 3)</div>
                                    <div className="col-auto ">$1,500</div>
                                </div>
                                */}

                                <div className="row pb-4 mt-2">
                                    <div className="col lh-5">
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e: React.FormEvent) => addSize(e)}
                                        >
                                            Add Size
                                        </button>
                                    </div>
                                </div>

                                <div className="row pb-4 mt-2">
                                    <div className="col lh-5">
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e: React.FormEvent) => saveSizes(e)}
                                        >
                                            Save Sizes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pl-0 pr-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5"><strong>Hero Banner</strong></div>
                                    <div className="col-auto text-center">
                                        {ratecard.urlHero && <img src={ratecard.urlHero} style={{ width: '300px' }} />}
                                        {!ratecard.urlHero && <input type="file" className="form-control" accept="image/*" onChange={e => rcUploadHero(e)} />}
                                    </div>
                                </div>
                            </div>

                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5"><strong>Director Photo</strong></div>
                                    <div className="col-auto text-center">
                                        {ratecard.urlDirectorPhoto && <img src={ratecard.urlDirectorPhoto} style={{ width: '300px' }} />}
                                        {!ratecard.urlDirectorPhoto && <input type="file" className="form-control" accept="image/*" onChange={e => rcUploadDirectorPhoto(e)} />}
                                    </div>
                                </div>
                            </div>

                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5"><strong>Chamber Logo</strong></div>
                                    <div className="col-auto text-center">
                                        {ratecard.urlLogo && <img src={ratecard.urlLogo} style={{ width: '300px' }} />}
                                        {!ratecard.urlLogo && <input type="file" className="form-control" accept="image/*" onChange={e => rcUploadChamberLogo(e)} />}
                                    </div>
                                </div>
                            </div>

                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5"><strong>Ratecard Thumbnail</strong></div>
                                    <div className="col-auto text-center">
                                        {ratecard.urlRatecardThumb && <img src={ratecard.urlRatecardThumb} style={{ width: '300px' }} />}
                                        {!ratecard.urlRatecardThumb && <input type="file" className="form-control" accept="image/*" onChange={e => rcUploadThumb(e)} />}
                                    </div>
                                </div>
                            </div>

                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row">
                                    <div className="col lh-5"><strong>Secondary Hero</strong></div>
                                    <div className="col-auto text-center">
                                        {ratecard.urlSecondaryHero && <img src={ratecard.urlSecondaryHero} style={{ width: '300px' }} />}
                                        {!ratecard.urlSecondaryHero && <input type="file" className="form-control" accept="image/*" onChange={e => rcUploadSecondaryHero(e)} />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pr-0">
                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <strong>Director Message Left</strong><br />
                                        <textarea
                                            className="form-control"
                                            value={ratecard.directorMessageLeft}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRatecard({ ...ratecard, directorMessageLeft: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="border rounded shadow-2 p-4 mb-4 bg-white">
                                <div className="row pb-4">
                                    <div className="col lh-5">
                                        <strong>Director Message Right</strong><br />
                                        <textarea
                                            className="form-control"
                                            value={ratecard.directorMessageRight}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRatecard({ ...ratecard, directorMessageRight: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row pb-4">
                                <div className="col lh-5">
                                    <button
                                        className="btn btn-primary"
                                        onClick={(e: React.FormEvent) => saveInfo(e)}
                                    >
                                        Save Info
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section tab-pane" id="agreements" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    {/* TODO: Add functionality and remove warning */}
                    <div className="alert alert-warning" role="alert">Project agreements are not yet functional</div>
                    {/* Agreements */}
                </div>
            </section>

            <section className="section tab-pane" id="marketing-list" style={{ paddingTop: '2rem' }}>
                <div className="container">
                    {/* TODO: Add functionality and remove warning */}
                    <div className="alert alert-warning" role="alert">Project marketing list is not yet functional</div>
                    {/*
                    Marketing List

                    Advertiser Name     | Prospect / Renewal    |         Assigned Rep
                    Contact Name, Email, Phone, URL
                    */}
                </div>
            </section>
        </div>
    </FullPage>;
};

export default ProjectPage;
