import React, { useEffect, useState } from 'react';
import { Router, Redirect } from '@reach/router';
import ls from 'local-storage';

// Import the authentication pages
import LoginPage from '../containers/auth/LoginPage';

// Import the dashboard page
import DashboardPage from '../containers/dashboard/DashboardPage';

// Import the dashboard page
import AccountPage from '../containers/account/AccountPage';

// Import the company pages
import CompanyListPage from '../containers/companies/CompanyListPage';
import CompanyPage from '../containers/companies/CompanyPage';
import CompanyEditPage from '../containers/companies/CompanyEditPage';

// Import the project pages
import ProjectListPage from '../containers/projects/ProjectListPage';
import ProjectPage from '../containers/projects/ProjectPage';
import ProjectMarketingListPage from '../containers/projects/ProjectMarketingListPage';
import ProjectCallListPage from '../containers/projects/ProjectCallListPage';
import ProjectCreateConceptPage from '../containers/projects/ProjectCreateConceptPage';

import ActionItemReportPage from '../containers/projects/reports/ActionItemReportPage';
import AdAuditPage from '../containers/projects/reports/AdAuditPage';
import ChangeRequestReportPage from '../containers/projects/reports/ChangeRequestReportPage';
import ClientSpecsReportPage from '../containers/projects/reports/ClientSpecsReportPage';
import CollectionsReportPage from '../containers/projects/reports/CollectionsReportPage'; // update from sheet
import ContentReportPage from '../containers/projects/reports/ContentReportPage';
import ImageReportPage from '../containers/projects/reports/ImageReportPage';
import MoneyReportPage from '../containers/projects/reports/MoneyReportPage';
import MultiDayReportPage from '../containers/projects/reports/MultiDayReportPage';
import TimelineReportPage from '../containers/projects/reports/TimelineReportPage';
import WeeklyMeetingReportPage from '../containers/projects/reports/WeeklyMeetingReportPage';
import DesignerVarianceReportPage from '../containers/projects/reports/DesignerVarianceReportPage';
import SalesReportPage from '../containers/projects/reports/SalesReportPage';

// Import the concept pages
import ConceptListPage from '../containers/concepts/ConceptListPage';

// Import the contact pages
import ContactPage from '../containers/contacts/ContactPage';
import EditContactPage from '../containers/contacts/EditContactPage';

// Import the event pages
import EventPage from '../containers/events/EventPage';

// Import the employee pages
import CreateEmployeePage from '../containers/employees/CreateEmployeePage';
import EmployeePage from '../containers/employees/EmployeePage';
import EditEmployeePage from '../containers/employees/EditEmployeePage';

import EmployeeRequestEditPage from '../containers/employees/EmployeeRequestEditPage';

import SalesCommissionReportPage from '../containers/employees/reports/SalesCommissionReportPage';
import SalesRepReportPage from '../containers/employees/reports/SalesRepReportPage';
import SalesRepHoursReportPage from '../containers/employees/reports/SalesRepHoursReportPage';
import DesignerProductivityReportPage from '../containers/employees/reports/DesignerProductivityReportPage';
import DesignerProductionReportPage from '../containers/employees/reports/DesignerProductionReportPage';
import LeadGenerationReportPage from '../containers/employees/reports/LeadGenerationReportPage';

// Import the agreement pages
import AgreementPage from '../containers/agreements/AgreementPage';

// Import the report pages
import ClientContactReportPage from '../containers/reports/ClientContactReportPage';
import DailySalesReportPage from '../containers/reports/DailySalesReportPage';
import DesignerVarianceSummaryReportPage from '../containers/reports/DesignerVarianceSummaryReportPage';
import SalesAuditReportPage from '../containers/reports/SalesAuditReportPage';

// Import the sales proposal pages
import SalesProposalListPage from '../containers/sales-proposal/SalesProposalListPage';
import CreateSalesProposalPage from '../containers/sales-proposal/CreateSalesProposalPage';
import SalesProposalPage from '../containers/sales-proposal/SalesProposalPage';
import EditSalesProposalPage from '../containers/sales-proposal/EditSalesProposalPage';

// Import the history for our router
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

const AppRouter = () => {
    // Store the logged-in state of a user
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    // If the user key is set, the user is logged in
    useEffect(() => { ls.get('uid') && setLoggedIn(true) }, []);
    
    return (
        <Router>
            <LoginPage path="/" />

            {/*
                loggedIn ?*/}
                    <>
                        <DashboardPage path="/dashboard"/>

                        <AccountPage path="/account" />

                        <CompanyListPage path="/companies" />
                        <CompanyPage path="/companies/:id" />
                        <CompanyEditPage path="/companies/:id/edit" />

                        <ProjectListPage path="/projects" />
                        <ProjectPage path="/projects/:id" />
                        <ProjectMarketingListPage path="/projects/:id/marketing-list" />
                        <ProjectCallListPage path="/projects/:id/call-list" />
                        <ProjectCreateConceptPage path="/projects/:id/concept" />

                        <ActionItemReportPage path="/projects/:id/reports/action-items" />
                        <AdAuditPage path="/projects/:id/reports/ad-audit" />
                        <ChangeRequestReportPage path="/projects/:id/reports/change-request" />
                        <ClientSpecsReportPage path="/projects/:id/reports/client-specs" />
                        <CollectionsReportPage path="/projects/:id/reports/collections" />
                        <ContentReportPage path="/projects/:id/reports/content" />
                        <ImageReportPage path="/projects/:id/reports/images" />
                        <MoneyReportPage path="/projects/:id/reports/money" />
                        <MultiDayReportPage path="/projects/:id/reports/multi-day" />
                        <TimelineReportPage path="/projects/:id/reports/timeline" />
                        <WeeklyMeetingReportPage path="/projects/:id/reports/weekly-meeting" />
                        <DesignerVarianceReportPage path="/projects/:id/reports/designer-variance" />
                        <SalesReportPage path="/projects/:id/reports/sales" />

                        <ConceptListPage path="/concepts" />

                        <ContactPage path="/contacts/:id" />
                        <EditContactPage path="/contacts/:id/edit" />

                        <EventPage path="/events/:id" />

                        <CreateEmployeePage path="/employees/create" />
                        <EmployeePage path="/employees/:id" />
                        <EditEmployeePage path="/employees/:id/edit" />

                        <EmployeeRequestEditPage path="/employees/:id/edit-request/:reqid" />

                        <SalesCommissionReportPage path="/employees/:id/reports/sales-commission" />
                        <SalesRepReportPage path="/employees/:id/reports/sales-rep" />
                        <SalesRepHoursReportPage path="/employees/:id/reports/sales-rep-hours" />
                        <DesignerProductivityReportPage path="/employees/:id/reports/designer-productivity" />
                        <DesignerProductionReportPage path="/employees/:id/reports/designer-production" />
                        <LeadGenerationReportPage path="/employees/:id/reports/lead-generation" />

                        <AgreementPage path="/agreements/:id" />

                        <ClientContactReportPage path="/reports/client-contact" />
                        <DailySalesReportPage path="/reports/daily-sales"/>
                        <DesignerVarianceSummaryReportPage path="/reports/designer-variance" />
                        <SalesAuditReportPage path="/reports/sales-audit" />

                        <SalesProposalListPage path="/sales-proposals" />
                        <CreateSalesProposalPage path="/sales-proposals/create" />
                        <SalesProposalPage path="/sales-proposals/:id" />
                        <EditSalesProposalPage path="/sales-proposals/:id/edit" />
                    </>
                {/*}: <Redirect from="" to="/" noThrow />
            }
            */}
        </Router>
    );
};

/*
const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
                <Route path="/" exact component={LoginPage} />

                <Route path="/dashboard" exact component={DashboardPage} />

                <Route path="/account" exact component={AccountPage} />

                <Route path="/companies" exact component={CompanyListPage} />
                <Route path="/companies/:id" exact component={CompanyPage} />

                <Route path="/projects" exact component={ProjectListPage} />
                <Route path="/projects/:id" exact component={ProjectPage} />
                <Route path="/projects/:id/marketing-list" exact component={ProjectMarketingListPage} />
                <Route path="/projects/:id/call-list" exact component={ProjectCallListPage} />
                <Route path="/projects/:id/concept" exact component={ProjectCreateConceptPage} />

                <Route path="/projects/:id/reports/action-items" exact component={ActionItemReportPage} />
                <Route path="/projects/:id/reports/ad-audit" exact component={AdAuditPage} />
                <Route path="/projects/:id/reports/change-request" exact component={ChangeRequestReportPage} />
                <Route path="/projects/:id/reports/client-specs" exact component={ClientSpecsReportPage} />
                <Route path="/projects/:id/reports/collections" exact component={CollectionsReportPage} />
                <Route path="/projects/:id/reports/content" exact component={ContentReportPage} />
                <Route path="/projects/:id/reports/images" exact component={ImageReportPage} />
                <Route path="/projects/:id/reports/money" exact component={MoneyReportPage} />
                <Route path="/projects/:id/reports/multi-day" exact component={MultiDayReportPage} />
                <Route path="/projects/:id/reports/timeline" exact component={TimelineReportPage} />
                <Route path="/projects/:id/reports/weekly-meeting" exact component={WeeklyMeetingReportPage} />
                <Route path="/projects/:id/reports/designer-variance" exact component={DesignerVarianceReportPage} />
                <Route path="/projects/:id/reports/sales" exact component={SalesReportPage} />

                <Route path="/concepts" exact component={ConceptListPage} />

                <Route path="/contacts/:id" exact component={ContactPage} />

                <Route path="/events/:id" exact component={EventPage} />

                <Route path="/employees/create" exact component={CreateEmployeePage} />
                <Route path="/employees/:id" exact component={EmployeePage} />
                <Route path="/employees/:id/edit" exact component={EditEmployeePage} />

                <Route path="/employees/:id/reports/sales-commission" exact component={SalesCommissionReportPage} />
                <Route path="/employees/:id/reports/sales-rep" exact component={SalesRepReportPage} />
                <Route path="/employees/:id/reports/sales-rep-hours" exact component={SalesRepHoursReportPage} />
                <Route path="/employees/:id/reports/designer-productivity" exact component={DesignerProductivityReportPage} />
                <Route path="/employees/:id/reports/designer-production" exact component={DesignerProductionReportPage} />
                <Route path="/employees/:id/reports/lead-generation" exact component={LeadGenerationReportPage} />

                <Route path="/agreements/:id" exact component={AgreementPage} />

                <Route path="/reports/client-contact" exact component={ClientContactReportPage} />
                <Route path="/reports/daily-sales" exact component={DailySalesReportPage} />
                <Route path="/reports/designer-variance" exact component={DesignerVarianceSummaryReportPage} />
                <Route path="/reports/sales-audit" exact component={SalesAuditReportPage} />

                <Route path="/sales-proposals" exact component={SalesProposalListPage} />
                <Route path="/sales-proposals/create" exact component={CreateSalesProposalPage} />
                <Route path="/sales-proposals/:id" exact component={SalesProposalPage} />
                <Route path="/sales-proposals/:id/edit" exact component={EditSalesProposalPage} />
            </Switch>
        </div>
    </Router>
);
*/

export default AppRouter;
