import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../../layouts/FullPage';

// TODO: Import components

interface IChangeRequestReportPageProps extends RouteComponentProps { }

interface IState { }

class ChangeRequestReportPage extends React.Component<IChangeRequestReportPageProps, IState> {
    // Define the constructor for the change request report page
    constructor (props: IChangeRequestReportPageProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the change request report container
    render () {
        return (
            <FullPage>
                <section className="section" style={{ paddingTop: '1rem' }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark flex-column shadow-2 bg-white">
                            <div className="container d-stick-none">
                                <div className="navbar-left">
                                    <button className="navbar-toggler" type="button"><span className="navbar-toggler-icon"></span></button>
                                    <span className="nav-link fw-600">Company Name, Project Name</span>
                                </div>

                                <section className="navbar-mobile">
                                    <nav className="nav nav-navbar ml-auto">
                                        <span className="nav-link">Change Request Report</span>
                                    </nav>
                                </section>
                            </div>
                        </nav>
                    </div>
                </section>

                <section className="section" style={{ paddingTop: '0rem' }}>
                    <div className="container">
                        {/* TODO: Add functionality and remove warning */}
                        <div className="alert alert-warning" role="alert">Change Request report is not yet functional</div>

                        {/*
                            [-] Items Being Changed?
                            [-] Why Are The Items Being Changed?
                            [-] Who had Requested The Items To Be Changed?
                            [-] Date of request?
                            [-] Date of Modifications?
                            [-] Original Specifications?
                            [-] Modified Specifications?
                            [-] New Costs? (What are the new costs, What were The Old Costs, Difference in Price)
                            [-] Is there a new timeline? If so, what is the new timeline?
                        */}
                    </div>
                </section>
            </FullPage>
        );
    }
}

export default ChangeRequestReportPage;
