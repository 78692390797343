import React from 'react';
import { RouteComponentProps } from '@reach/router';
import FullPage from '../../layouts/FullPage';

// TODO: Import components

interface IProps extends RouteComponentProps { }

interface IState { }

class EditEmployeePage extends React.Component<IProps, IState> {
    // Define the constructor for the edit employee page
    constructor (props: IProps) {
        // Pull in parent props
        super(props);
    }

    // TODO: When the component mounts
    componentDidMount () {
    }

    // Render the TSX for the edit employee container
    render () {
        return (
            <FullPage>
                Edit Employee page
            </FullPage>
        );
    }
}

export default EditEmployeePage;
