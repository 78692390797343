import React, { useState, useEffect } from 'react';
import { useMutation } from 'urql';
import gql from 'graphql-tag';
import { nanoid } from 'nanoid';
import moment from 'moment-timezone';

interface IProps {
    employeeRequest: {
        id: string;
        type_id: number;
        start: string;
        end: string;
        half_day: boolean;
    }

    types: [{
        id: number;
        name: string;
    }]
};

const EmployeeRequestEditForm: React.FC<IProps> = ({ employeeRequest, types }) => {
    const requestId = employeeRequest.id;

    const [editState, setEditState] = useState({
        type_id: 0,
        start: '',
        end: '',
        half_day: false,
        saved: false
    });

    const [editRequestResult, executeRequestMutation] = useMutation(gql`mutation EditUserRequest (
        $id: Int!,
        $type_id: Int!,
        $start: timestamptz!,
        $end: timestamptz!,
        $half_day: Boolean!
    ) {
        update_user_requests_by_pk(
            pk_columns: {id: $id},
            _set: {
                type_id: $type_id,
                start: $start,
                end: $end,
                half_day: $half_day
            }
        ) {
            id
        }
    }`);

    useEffect(() => {
        if (employeeRequest && 'start' in employeeRequest) {
            setData(employeeRequest);
        }
    }, [employeeRequest]);

    const setData = (employeeRequest: any) => {
        setEditState({
            type_id: employeeRequest.type_id,
            start: moment(employeeRequest.start).tz('America/New_York').format('MM/DD/YYYY'),
            end: moment(employeeRequest.end).tz('America/New_York').format('MM/DD/YYYY'),
            half_day: employeeRequest.half_day,
            saved: false
        });
    };

    const editRequest = (e: React.FormEvent) => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        console.log(`Type: ${moment(editState.type_id).tz('America/New_York').format()}`)
        console.log(`Start: ${moment(editState.start).tz('America/New_York').format()}`)
        console.log(`End: ${moment(editState.end).tz('America/New_York').format()}`)
        console.log(`Half Day: ${editState.half_day}`)

        try {
            executeRequestMutation({
                id: requestId,
                type_id: editState.type_id,
                start: moment(editState.start).tz('America/New_York').format(),
                end: moment(editState.end).tz('America/New_York').format(),
                half_day: editState.half_day
            });

            // TODO:
            // - Refresh the contact list after the contact gets added so that we
            //   can see the contact in the list without having to refesh

            // TODO: Temp, remove.
            setEditState({ ...editState, saved: true });
        } catch (err) {
            console.warn(err);
        }
    };

    return <form>
        <div className="border rounded shadow-2 p-4 mb-4 bg-white">
            <div className="row pb-4">
                <div className="col lh-5">
                    <select
                        className="form-control"
                        value={editState.type_id || 0}
                        onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>
                        ): void => {
                            // setTypeId(parseInt(e.target.value, 10))}
                            setEditState({ ...editState, type_id: parseInt(e.target.value, 10) })
                        }}
                    >
                        <option value="">-- Request Type --</option>
                        {types.map((type: { id: number; name: string; }) => (
                            <option key={nanoid()} value={type.id}>{type.name}</option>
                        ))}
                    </select>

                    {/*
                    <input
                        className="form-control"
                        placeholder=""
                        value={editState.type_id || 0}
                        onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                            setEditState({ ...editState, parseInt(type_id: e.target.value, 10) })
                        }}
                    />
                    */}
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Start</span>
                        </div>
                        <input
                            className="form-control"
                            placeholder=""
                            value={editState.start || ''}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => {
                                setEditState({ ...editState, start: e.target.value })
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="row pb-4">
                <div className="col lh-5">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">End</span>
                        </div>
                        <input
                            className="form-control"
                            placeholder=""
                            value={editState.end || ''}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => {
                                setEditState({ ...editState, end: e.target.value })
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group input-group" style={{ border: 'none' }}>
                <div className="input-group-prepend mr-4">
                    <button
                        className="btn btn-primary"
                        onClick={editRequest}
                        disabled={(!editState.start || !editState.end || !editState.type_id)}
                    >
                        Save Request
                    </button>
                </div>

                <div className="custom-control custom-checkbox mt-1">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={editState.half_day}
                        onClick={() => setEditState({ ...editState, half_day: !editState.half_day })}
                    />

                    <label
                        className="custom-control-label"
                        onClick={() => setEditState({ ...editState, half_day: !editState.half_day })}
                    >
                        Half Day
                    </label>
                </div>
            </div>

            {editState.saved && <div className="alert alert-success mt-3" role="alert">Success!</div>}
        </div>
    </form>;
};

export default EmployeeRequestEditForm;
