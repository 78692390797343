import React, { Suspense, useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
// import EmployeeContactList from './EmployeeContactList';
// import { firestore } from '../../firebase/firebase.utils';
// import ls from 'local-storage';
import EmployeeContactList from '../common/EmployeeContactList';
import moment from 'moment';

interface ILeadGenDashboardProps extends RouteComponentProps {}

const LeadGenDashboard: React.FC<ILeadGenDashboardProps> = () => {
    const [showCommunications, setShowCommunications] = useState(false);
    const [hidePastEvents, setHidePastEvents] = useState(false);
    const [hideFutureEvents, setHideFutureEvents] = useState(false);
    const [filterMyProductivityWeekly, setFilterMyProductivityWeekly] = useState(false);

    // eventsYesterday,
    // eventsToday,
    // eventsTomorrow,

    // communicationsYesterday,
    // communicationsToday,
    // communicationsTomorrow,

    // Return the appropriate color for the event time badge
    const eventTimeColor = (t: string) => {
        const now = moment();
        const thirtyMin = moment().add(30, 'minutes');
        const midnight = moment().endOf('day');
        const eventTime = moment(t);

        if (eventTime.isBetween(now, thirtyMin)) {
            return 'badge-warning';
        } else if (eventTime.isBetween(now, midnight)) {
            return 'badge-success';
        } else {
            return 'badge-secondary';
        }
    };

    return <div className="row">
        <div className="col-md-8 mx-auto">
            <h6 className="divider">
                <a
                    href=""
                    onClick={e => { e.preventDefault(); setShowCommunications(!showCommunications) }}
                >
                    {showCommunications ? 'Communications' : 'Events'}
                </a>
            </h6>

            {!showCommunications &&
                <div>
                    <div className="border rounded bg-white p-2 mb-4">
                        <div className="row align-items-center">
                            <div className="col-auto">
                            </div>
                            <div className="col lh-5">
                                <a className="text-default fw-400" href="#">Filters</a>
                                <span className="topbar-divider"></span>
                                <span className="badge badge-secondary mr-1">Yesterday</span>
                                <span className="badge badge-primary mr-1">Today</span>
                                <span className="badge badge-secondary mr-1">Tomorrow</span>
                            </div>

                            <div className="col-auto">
                                <small className="opacity-65">
                                    <a
                                        href=""
                                        className={`badge ${hidePastEvents ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                        onClick={() => setHidePastEvents(!hidePastEvents)}
                                    >
                                        Show Past
                                    </a>

                                    <a
                                        href=""
                                        className={`badge ${hideFutureEvents ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                        onClick={() => setHideFutureEvents(!hideFutureEvents)}
                                    >
                                        Show Future
                                    </a>
                                </small>
                            </div>
                        </div>
                    </div>

                    <div className="accordion accordion-light" id="event-list">
                        {/*contacts.map(contact => (
                            <div className="card" key={nanoid()}>
                                <h5 className="card-title">
                                    <a data-toggle="collapse" href={`#contact-${contact.id}`} className="collapsed" aria-expanded="false">{contact.name}</a>
                                </h5>

                                <div id={`contact-${contact.id}`} className="collapse" data-parent="#contact-list">
                                    <div className="card-body">
                                        TBD
                                    </div>
                                </div>
                            </div>
                        ))*/}

                        <div className="card">
                            <div className="card-img-top">
                                <div className="badges badges-right">
                                    <span className="badge badge-primary mr-1">Follow-up Call to Close</span>
                                    <span className={`badge ${eventTimeColor('6/2/2020 5:55 PM')}`}>6/2/2020 @ 5:55 PM</span>
                                </div>
                            </div>

                            <h5 className="card-title">
                                <a className="collapsed" data-toggle="collapse" href="#event-four">
                                    Holiday Inn Tampa Airport Westshore
                                </a>
                            </h5>

                            <div id="event-four" className="collapse" data-parent="#event-list">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            Name, Title<br />
                                            Email<br />
                                            Phone
                                        </div>
                                        <div className="col-md-8">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-img-top">
                                <div className="badges badges-right">
                                    <span className="badge badge-primary mr-1">Ad Sale Call Back</span>
                                    <span className={`badge ${eventTimeColor('6/2/2020 4:55 PM')}`}>6/2/2020 @ 4:55 PM</span>
                                </div>
                            </div>

                            <h5 className="card-title">
                                <a className="collapsed" data-toggle="collapse" href="#event-three">
                                    Home Care Assistance of South Tampa
                                </a>
                            </h5>

                            <div id="event-three" className="collapse" data-parent="#event-list">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            Name, Title<br />
                                            Email<br />
                                            Phone
                                        </div>
                                        <div className="col-md-8">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-img-top">
                                <div className="badges badges-right">
                                    <span className="badge badge-primary mr-1">Phone Discovery Meeting</span>
                                    <span className={`badge ${eventTimeColor('6/2/2020 3:03 PM')}`}>6/2/2020 @ 3:03 PM</span>
                                </div>
                            </div>

                            <h5 className="card-title">
                                <a className="collapsed" data-toggle="collapse" href="#event-one">
                                    Safe House Thrift Store
                                </a>
                            </h5>

                            <div id="event-one" className="collapse" data-parent="#event-list">
                                <div className="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-img-top">
                                <div className="badges badges-right">
                                    <span className="badge badge-primary mr-1">Phone Discovery Meeting</span>
                                    <span className={`badge ${eventTimeColor('6/2/2020 2:40 PM')}`}>6/2/2020 @ 2:40 PM</span>
                                </div>
                            </div>

                            <h5 className="card-title">
                                <a className="collapsed" data-toggle="collapse" href="#event-two">
                                    Show Low Motor Sports
                                </a>
                            </h5>

                            <div id="event-two" className="collapse" data-parent="#event-list">
                                <div className="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showCommunications &&
                <div>
                    <div className="border rounded bg-white p-2 mb-2">
                        <div className="row align-items-center">
                            <div className="col-auto">
                            </div>
                            <div className="col lh-5">
                                <a className="text-default fw-400" href="#">Filters</a>
                                <span className="topbar-divider"></span>
                                <span className="badge badge-secondary mr-1">Yesterday</span>
                                <span className="badge badge-primary mr-1">Today</span>
                                <span className="badge badge-secondary mr-1">Tomorrow</span>
                            </div>

                            <div className="col-auto">
                                <span className="badge badge-primary mr-1 opacity-65">Sale</span>
                                <span className="badge badge-success mr-1">Spoke With Them</span>
                                <span className="badge badge-info mr-1 opacity-65">Voicemail</span>
                                <span className="badge badge-warning mr-1 opacity-65">Email</span>
                                <span className="badge badge-danger mr-1 opacity-65">NO</span>
                            </div>
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Sandro Popelka</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">Hampton Inn Yukon</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-success">Spoke With Them</span>
                            </div>
                            6/2/2020 @ 9:55am
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Julie Carman</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-info">Left voicemail</span>
                            </div>
                            6/2/2020 @ 9:59am
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Wendy Wurz</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-success">Spoke with them</span>
                            </div>
                            6/2/2020 @ 9:59am
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Tawanna Simmons</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-info">Left Voicemail</span>
                            </div>
                            6/2/2020 @ 10:11am
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Nicole Gibson</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-success">Spoke With Them</span>
                            </div>
                            6/2/2020 @ 10:18am
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">David Hoffman</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-danger">Not Interested</span>
                            </div>
                            6/2/2020 @ 10:26am
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Beth Kerly</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-info">Left voicemail</span>
                            </div>
                            6/2/2020 @ 10:30am
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Ann Madsen</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-info">Left voicemail</span>
                            </div>
                            6/2/2020 @ 10:33am
                        </div>
                    </div>

                    <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                        <div className="media-body lh-3">
                            <h6 className="mb-0">
                                <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Laura DeLise</Link>
                            </h6>
                            <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                        </div>

                        <div className="col-auto text-right">
                            <div className="mb-0">
                                <span className="badge badge-secondary mr-1">Outbound Call</span>
                                <span className="badge badge-warning">Sent Email</span>
                            </div>
                            6/2/2020 @ 10:36am
                        </div>
                    </div>
                </div>
            }
        </div>

        <div className="col-md-4 mx-auto">
            <h6 className="divider">My Productivity</h6>

            <div className="border rounded bg-white p-2 mb-4">
                <div className="row align-items-center">
                    <div className="col-auto">
                    </div>
                    <div className="col lh-5">
                        <a className="text-default fw-400" href="#">Filters</a>
                        <span className="topbar-divider"></span>
                        <a
                            href=""
                            className={`badge ${filterMyProductivityWeekly ? 'badge-secondary' : 'badge-primary'} mr-1`}
                            onClick={() => setFilterMyProductivityWeekly(!filterMyProductivityWeekly)}
                        >
                            Daily
                        </a>
                        <a
                            href=""
                            className={`badge ${filterMyProductivityWeekly ? 'badge-primary' : 'badge-secondary'} mr-1`}
                            onClick={() => setFilterMyProductivityWeekly(!filterMyProductivityWeekly)}
                        >
                            Weekly
                        </a>
                    </div>
                </div>
            </div>

            <div className="cart-price">
                <div className="flexbox">
                    <div>
                        <p>Dials /hr</p>
                        <p>Completed Calls /hr</p>
                        <p>Sales</p>
                        <p>Sales /hr</p>
                        <p>Conversion Rate</p>
                        <p>Average Sale</p>
                    </div>

                    <div>
                        <p>N/A</p> {/*DPH = (Dispositions/Connections) / hours worked */}
                        <p>N/A</p> {/* Completed calls per hour = (Completed Calls - yes/no/maybe) / hours worked (timeframe - for the day, week, month, etc) */}
                        <p>N/A</p> {/* Sales = total sales */}
                        <p>N/A</p> {/* Sales per hour = total sales / hours worked */}
                        <p>N/A</p> {/* Conversion Rate = (sales) / (dials per hour) */}
                        <p>N/A</p> {/* Average Sale =  */}
                    </div>
                </div>
            </div>

            <Suspense fallback={<div>Loading...</div>}>
                <EmployeeContactList />
            </Suspense>
        </div>
    </div>;
};

















/*
// class LeadGenDashboard extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showCommunications: false,

            filterMyProductivityWeekly: false,
            filterTeamProductivityWeekly: false,

            events: [],
            eventsYesterday: [],
            eventsToday: [],
            eventsTomorrow: [],
            hidePastEvents: false,
            hideFutureEvents: false,

            communications: [],
            communicationsYesterday: [],
            communicationsToday: [],
            communicationsTomorrow: [],
        };
    }

    componentDidMount () {
    }

    // Return the appropriate color for the event time badge
    eventTimeColor = t => {
        const now = moment();
        const thirtyMin = moment().add(30, 'minutes');
        const midnight = moment().endOf('day');
        const eventTime = moment(t);

        if (eventTime.isBetween(now, thirtyMin)) {
            return 'badge-warning';
        } else if (eventTime.isBetween(now, midnight)) {
            return 'badge-success';
        } else {
            return 'badge-secondary';
        }
    };

    render () {
        const {
            showCommunications,
            filterMyProductivityWeekly,
            eventsYesterday, eventsToday, eventsTomorrow, hidePastEvents, hideFutureEvents,
            communicationsYesterday, communicationsToday, communicationsTomorrow,
        } = this.state;

        return (
            <div>
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <h6 className="divider">
                            <a
                                href=""
                                onClick={e => { e.preventDefault(); this.setState({ showCommunications: !showCommunications }) }}
                            >
                                {showCommunications ? 'Communications' : 'Events'}
                            </a>
                        </h6>

                        {!showCommunications &&
                            <div>
                                <div className="border rounded bg-white p-2 mb-4">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                        </div>
                                        <div className="col lh-5">
                                            <a className="text-default fw-400" href="#">Filters</a>
                                            <span className="topbar-divider"></span>
                                            <span className="badge badge-secondary mr-1">Yesterday</span>
                                            <span className="badge badge-primary mr-1">Today</span>
                                            <span className="badge badge-secondary mr-1">Tomorrow</span>
                                        </div>

                                        <div className="col-auto">
                                            <small className="opacity-65">
                                                <a
                                                    href=""
                                                    className={`badge ${hidePastEvents ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                    onClick={e => { e.preventDefault(); this.setState(() => ({ hidePastEvents: !hidePastEvents }))}}
                                                >
                                                    Show Past
                                                </a>

                                                <a
                                                    href=""
                                                    className={`badge ${hideFutureEvents ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                                    onClick={e => { e.preventDefault(); this.setState(() => ({ hideFutureEvents: !hideFutureEvents }))}}
                                                >
                                                    Show Future
                                                </a>
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion accordion-light" id="event-list">
                                    {contacts.map(contact => (
                                        <div className="card" key={nanoid()}>
                                            <h5 className="card-title">
                                                <a data-toggle="collapse" href={`#contact-${contact.id}`} className="collapsed" aria-expanded="false">{contact.name}</a>
                                            </h5>

                                            <div id={`contact-${contact.id}`} className="collapse" data-parent="#contact-list">
                                                <div className="card-body">
                                                    TBD
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="card">
                                        <div className="card-img-top">
                                            <div className="badges badges-right">
                                                <span className="badge badge-primary mr-1">Follow-up Call to Close</span>
                                                <span className={`badge ${this.eventTimeColor('6/2/2020 5:55 PM')}`}>6/2/2020 @ 5:55 PM</span>
                                            </div>
                                        </div>

                                        <h5 className="card-title">
                                            <a className="collapsed" data-toggle="collapse" href="#event-four">
                                                Holiday Inn Tampa Airport Westshore
                                            </a>
                                        </h5>

                                        <div id="event-four" className="collapse" data-parent="#event-list">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        Name, Title<br />
                                                        Email<br />
                                                        Phone<br /><br />
                                                        Address Line1, Line 2<br />
                                                        City, State Zip
                                                    </div>
                                                    <div className="col-md-6">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-img-top">
                                            <div className="badges badges-right">
                                                <span className="badge badge-primary mr-1">Ad Sale Call Back</span>
                                                <span className={`badge ${this.eventTimeColor('6/2/2020 4:55 PM')}`}>6/2/2020 @ 4:55 PM</span>
                                            </div>
                                        </div>

                                        <h5 className="card-title">
                                            <a className="collapsed" data-toggle="collapse" href="#event-three">
                                                Home Care Assistance of South Tampa
                                            </a>
                                        </h5>

                                        <div id="event-three" className="collapse" data-parent="#event-list">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        Name, Title<br />
                                                        Email<br />
                                                        Phone<br /><br />
                                                        Address Line1, Line 2<br />
                                                        City, State Zip
                                                    </div>
                                                    <div className="col-md-6">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-img-top">
                                            <div className="badges badges-right">
                                                <span className="badge badge-primary mr-1">Phone Discovery Meeting</span>
                                                <span className={`badge ${this.eventTimeColor('6/2/2020 3:03 PM')}`}>6/2/2020 @ 3:03 PM</span>
                                            </div>
                                        </div>

                                        <h5 className="card-title">
                                            <a className="collapsed" data-toggle="collapse" href="#event-one">
                                                Safe House Thrift Store
                                            </a>
                                        </h5>

                                        <div id="event-one" className="collapse" data-parent="#event-list">
                                            <div className="card-body">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-img-top">
                                            <div className="badges badges-right">
                                                <span className="badge badge-primary mr-1">Phone Discovery Meeting</span>
                                                <span className={`badge ${this.eventTimeColor('6/2/2020 2:40 PM')}`}>6/2/2020 @ 2:40 PM</span>
                                            </div>
                                        </div>

                                        <h5 className="card-title">
                                            <a className="collapsed" data-toggle="collapse" href="#event-two">
                                                Show Low Motor Sports
                                            </a>
                                        </h5>

                                        <div id="event-two" className="collapse" data-parent="#event-list">
                                            <div className="card-body">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta tempus odio, et finibus mi fermentum et. Donec nunc nulla, aliquam lobortis leo in, posuere consectetur lorem.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {showCommunications &&
                            <div>
                                <div className="border rounded bg-white p-2 mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                        </div>
                                        <div className="col lh-5">
                                            <a className="text-default fw-400" href="#">Filters</a>
                                            <span className="topbar-divider"></span>
                                            <span className="badge badge-secondary mr-1">Yesterday</span>
                                            <span className="badge badge-primary mr-1">Today</span>
                                            <span className="badge badge-secondary mr-1">Tomorrow</span>
                                        </div>

                                        <div className="col-auto">
                                            <span className="badge badge-primary mr-1 opacity-65">Sale</span>
                                            <span className="badge badge-success mr-1">Spoke With Them</span>
                                            <span className="badge badge-info mr-1 opacity-65">Voicemail</span>
                                            <span className="badge badge-warning mr-1 opacity-65">Email</span>
                                            <span className="badge badge-danger mr-1 opacity-65">NO</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Sandro Popelka</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">Hampton Inn Yukon</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-success">Spoke With Them</span>
                                        </div>
                                        6/2/2020 @ 9:55am
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Julie Carman</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-info">Left voicemail</span>
                                        </div>
                                        6/2/2020 @ 9:59am
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Wendy Wurz</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-success">Spoke with them</span>
                                        </div>
                                        6/2/2020 @ 9:59am
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Tawanna Simmons</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-info">Left Voicemail</span>
                                        </div>
                                        6/2/2020 @ 10:11am
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Nicole Gibson</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-success">Spoke With Them</span>
                                        </div>
                                        6/2/2020 @ 10:18am
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">David Hoffman</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-danger">Not Interested</span>
                                        </div>
                                        6/2/2020 @ 10:26am
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Beth Kerly</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-info">Left voicemail</span>
                                        </div>
                                        6/2/2020 @ 10:30am
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Ann Madsen</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-info">Left voicemail</span>
                                        </div>
                                        6/2/2020 @ 10:33am
                                    </div>
                                </div>

                                <div className="media align-items-center border rounded p-2 pl-4 bg-white mb-2">
                                    <div className="media-body lh-3">
                                        <h6 className="mb-0">
                                            <Link to="/companies/iSDAsKU1iLdq1RqzLrRE">Laura DeLise</Link>
                                        </h6>
                                        <p className="text-lighter mb-0 small-2">CHANGE_ADVERTISER</p>
                                    </div>

                                    <div className="col-auto text-right">
                                        <div className="mb-0">
                                            <span className="badge badge-secondary mr-1">Outbound Call</span>
                                            <span className="badge badge-warning">Sent Email</span>
                                        </div>
                                        6/2/2020 @ 10:36am
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-md-4 mx-auto">
                        <h6 className="divider">My Productivity</h6>

                        <div className="border rounded bg-white p-2 mb-4">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                </div>
                                <div className="col lh-5">
                                    <a className="text-default fw-400" href="#">Filters</a>
                                    <span className="topbar-divider"></span>
                                    <a
                                        href=""
                                        className={`badge ${filterMyProductivityWeekly ? 'badge-secondary' : 'badge-primary'} mr-1`}
                                        onClick={e => { e.preventDefault(); this.setState({ filterMyProductivityWeekly: !filterMyProductivityWeekly })}}
                                    >
                                        Daily
                                    </a>
                                    <a
                                        href=""
                                        className={`badge ${filterMyProductivityWeekly ? 'badge-primary' : 'badge-secondary'} mr-1`}
                                        onClick={e => { e.preventDefault(); this.setState({ filterMyProductivityWeekly: !filterMyProductivityWeekly })}}
                                    >
                                        Weekly
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="cart-price">
                            <div className="flexbox">
                                <div>
                                    <p>Dials /hr</p>
                                    <p>Completed Calls /hr</p>
                                    <p>Sales</p>
                                    <p>Sales /hr</p>
                                    <p>Conversion Rate</p>
                                    <p>Average Sale</p>
                                </div>

                                <div>
                                    <p>N/A</p>
                                    <p>N/A</p>
                                    <p>N/A</p>
                                    <p>N/A</p>
                                    <p>N/A</p>
                                    <p>N/A</p>
                                </div>
                            </div>
                        </div>

                        <Suspense fallback={<div>Loading...</div>}>
                            <EmployeeContactList />
                        </Suspense>

                        <h6 className="divider">Test Features</h6>

                        <button
                            onClick={e => {
                                e.preventDefault();

                                // https://developer.mozilla.org/en-US/docs/Web/API/notification

                                if (!("Notification" in window)) {
                                    alert('browser does not support notifications');
                                } else if (Notification.permission === 'granted') {
                                    const notification = new Notification('Hello, world!');
                                } else if (Notification.permission !== 'denied') {
                                    Notification.requestPermission().then(perm => {
                                        if (perm === 'granted') {
                                            const notification = new Notification('Hello, world!');
                                        }
                                    })
                                }
                            }}
                        >
                            Browser Notification
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
*/

export default LeadGenDashboard;
